import React, { FC } from 'react'
import { connect } from 'react-redux'
import CalculateButton from './CalculateButton'
import { RootStore } from 'interfaces/stores'
import { InStockWheatSpec } from 'interfaces/master/sheetInStockWheatSpecs'
import { ExpectedWheatSpec } from 'interfaces/master/sheetExpectedWheatSpecs'
import { FlourSpec } from 'interfaces/master/sheetFlourSpecs'

import { importConstraints } from 'stores/recipe/sheetConstrained/actions'
import { importIdeals } from 'stores/recipe/sheetIdeal/actions'
import { importWup } from 'stores/wup/sheetWup/actions'
import { importFlourSpecs } from 'stores/wup/sheetFlourSpecs/actions'
import { importWheatCosts } from 'stores/wup/sheetWheatCost/actions'
import { importCostStructures } from 'stores/wup/sheetCostStructure/actions'
import { importEffectiveCalcs } from 'stores/wup/sheetEffectiveCalc/actions'
import { importConstrainedRecipes } from 'stores/wup/sheetConstrainedRecipe/actions'
import { importIdealRecipes } from 'stores/wup/sheetIdealRecipe/actions'
import { importForegoneCosts } from 'stores/wup/sheetForegoneCosts/actions'
import { FormikProps } from 'formik'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IncomingWheat } from 'interfaces/swimof/sheetIncomingWheat'
import { ForecastFlour } from 'interfaces/swimof/sheetSalesForecast'
import { OpeningStockWheat } from 'interfaces/swimof/sheetOpeningStocks'
import { WheatSale } from 'interfaces/swimof/sheetWheatSales'
import { Financial } from 'interfaces/swimof/sheetFinancials'
import { WheatReplacementPrice } from 'interfaces/swimof/sheetWheatReplacementPrices'
import { PageProps } from 'modules/recipe/Page.Container'
import { updateWupDownloadLink } from 'stores/wup/downloadLink/actions'
import { MinMaxFlour } from 'interfaces/generic/minMaxConstraint'
import { importMinMaxFlours } from 'stores/wup/sheetMinMax/actions'

interface PropsFromState {
  inStockWheatSpecs: InStockWheatSpec[]
  expectedWheatSpecs: ExpectedWheatSpec[]
  flourSpecs: FlourSpec[]
  date: string
  plantSite: string

  minMaxFlours: MinMaxFlour[]
  incomingWheats: IncomingWheat[]
  referenceWheats: string[]
  forecastFlours: ForecastFlour[]
  openingStockWheats: OpeningStockWheat[]
  wheatSales: WheatSale[]
  financial: Financial
  wheatReplacementPrices: WheatReplacementPrice[]

  month: number

  masterFilename: string
  swimofFilename: string
}

interface PropsFromDispatch {
  importConstraints: typeof importConstraints
  importIdeals: typeof importIdeals
  importWup: typeof importWup
  importFlourSpecs: typeof importFlourSpecs
  importWheatCosts: typeof importWheatCosts
  importCostStructures: typeof importCostStructures
  importEffectiveCalcs: typeof importEffectiveCalcs
  importConstrainedRecipes: typeof importConstrainedRecipes
  importIdealRecipes: typeof importIdealRecipes
  importForegoneCosts: typeof importForegoneCosts
  importMinMaxFlours: typeof importMinMaxFlours
  updateWupDownloadLink: typeof updateWupDownloadLink
}

export type CalculateButtonProps = PropsFromState &
  PropsFromDispatch &
  RouteComponentProps & {
    form: FormikProps<PageProps['initialValues']>
  }

const CalculateButtonContainer: FC<CalculateButtonProps> = props => (
  <CalculateButton {...props} />
)

const mapStateToProps = ({
  master: {
    sheetInStockWheatSpecs,
    sheetExpectedWheatSpecs,
    sheetFlourSpecs,
    sheetDate,
    fileName: masterFilename
  },
  swimof: {
    sheetMinMaxConstraints,
    sheetIncomingWheat,
    sheetSalesForecast,
    sheetOpeningStocks,
    sheetWheatSales,
    sheetFinancials,
    sheetWheatReplacementPrices,
    fileName: swimofFilename
  },
  month
}: RootStore): PropsFromState => ({
  inStockWheatSpecs: sheetInStockWheatSpecs.wheats,
  expectedWheatSpecs: sheetExpectedWheatSpecs.wheats,
  flourSpecs: sheetFlourSpecs.flours,
  date: sheetDate.date,
  plantSite: sheetDate.plantSite,
  minMaxFlours: sheetMinMaxConstraints.minMaxFlours,
  incomingWheats: sheetIncomingWheat.incomingWheats,
  referenceWheats: sheetIncomingWheat.referenceWheats,
  forecastFlours: sheetSalesForecast.forecastFlours,
  openingStockWheats: sheetOpeningStocks.openingStockWheats,
  wheatSales: sheetWheatSales.wheatSales,
  financial: sheetFinancials.financial,
  wheatReplacementPrices: sheetWheatReplacementPrices.wheatReplacementPrices,
  masterFilename,
  swimofFilename,
  month
})

const mapDispatchToProps: PropsFromDispatch = {
  importConstraints,
  importIdeals,
  importWup,
  importFlourSpecs,
  importWheatCosts,
  importCostStructures,
  importEffectiveCalcs,
  importConstrainedRecipes,
  importIdealRecipes,
  importForegoneCosts,
  importMinMaxFlours,
  updateWupDownloadLink
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalculateButtonContainer)
)
