import React, { FC } from 'react'
import { Paper, Box, Typography, Grid } from '@material-ui/core'
import TableWupDates from './TableWupDates'
import TableChangelog from './TableChangelog'
import TableInfo from './TableInfo'
import { Wup } from 'interfaces/wup/sheetWup'

interface TabWupProps {
  wup: Wup
}

const TabWup: FC<TabWupProps> = ({ wup: { changelog, wupDates, ...rest } }) => (
  <Paper>
    <Box padding={3}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h6">WUP</Typography>
        </Grid>
      </Grid>

      <TableInfo {...rest} />
      <TableChangelog changelog={changelog} />
      <TableWupDates wupDates={wupDates} />
    </Box>
  </Paper>
)

export default TabWup
