import { combineReducers } from 'redux'
import Swimof from 'interfaces/swimof'
import { sheetMinMaxConstraintsReducer } from './sheetMinMaxConstraints/reducers'
import { sheetSalesForecastReducer } from './sheetSalesForecast/reducers'
import { sheetWheatSalesReducer } from './sheetWheatSales/reducers'
import { sheetWheatReplacementPricesReducer } from './sheetWheatReplacementPrices/reducers'
import { sheetIncomingWheatReducer } from './sheetIncomingWheat/reducers'
import { sheetOpeningStocksReducer } from './sheetOpeningStocks/reducers'
import { sheetFinancialsReducer } from './sheetFinancials/reducers'
import { fileNameReducer } from './fileName/reducers'

export const swimofReducers = combineReducers<Swimof>({
  fileName: fileNameReducer,
  sheetMinMaxConstraints: sheetMinMaxConstraintsReducer,
  sheetSalesForecast: sheetSalesForecastReducer,
  sheetWheatSales: sheetWheatSalesReducer,
  sheetWheatReplacementPrices: sheetWheatReplacementPricesReducer,
  sheetIncomingWheat: sheetIncomingWheatReducer,
  sheetOpeningStocks: sheetOpeningStocksReducer,
  sheetFinancials: sheetFinancialsReducer
})
