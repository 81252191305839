import React, { Component } from 'react'
import {
  Box,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell
} from '@material-ui/core'

interface TableInfoProps {
  noDokumen: string
  noRevisi: string
  plantSite: string
  tanggalBerlaku: string
}

export default class TableInfo extends Component<TableInfoProps> {
  render() {
    const { noDokumen, noRevisi, plantSite, tanggalBerlaku } = this.props

    return (
      <Box padding={3}>
        <Typography variant="h6">Info</Typography>

        <Box marginTop={2}>
          <Grid container>
            <Grid item>
              <Table>
                <TableRow>
                  <TableCell variant="head">No Dokumen</TableCell>
                  <TableCell>{noDokumen}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">No Revisi</TableCell>
                  <TableCell>{noRevisi}</TableCell>
                </TableRow>
              </Table>
            </Grid>
            <Grid item>
              <Table>
                <TableRow>
                  <TableCell variant="head">Plant Site</TableCell>
                  <TableCell>{plantSite}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Tanggal Berlaku</TableCell>
                  <TableCell>{tanggalBerlaku}</TableCell>
                </TableRow>
              </Table>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }
}
