import { Reducer } from "redux";
import { RecipeFileNameActionTypes } from "./types";

const initialState = "";

export const fileNameReducer: Reducer<string> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case RecipeFileNameActionTypes.UPDATE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
