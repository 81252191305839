import React, { FC, useState, useContext } from 'react'
import { Button, CircularProgress, Box } from '@material-ui/core'
import { CalculateButtonProps } from './CalculateButton.Container'
import axios from 'axios'
import {
  RecipeImporter,
  getWorkbook,
  MasterExporter,
  SwimofExporter
} from 'utils/excel'
import { messageContext } from 'components/message'

const CalculateButton: FC<CalculateButtonProps> = ({
  inStockWheatSpecs,
  expectedWheatSpecs,
  flourSpecs,
  date,
  plantSite,
  referenceWheats,
  importMinMaxConstraints,
  importIncomingWheats,
  importSalesForecasts,
  importOpeningStocks,
  importWheatSales,
  importFinancial,
  importWheatReplacementPrices,
  importConstraints,
  importIdeals,
  importMonth,
  form,
  history
}) => {
  const { handleOpen } = useContext(messageContext)
  const [isLoading, setLoading] = useState<boolean>(false)

  const importRecipeFile = (blob: Blob) => {
    getWorkbook(blob).then(workbook => {
      const recipeImporter = new RecipeImporter(workbook)
      importConstraints(recipeImporter.getConstraits())
      importIdeals(recipeImporter.getIdeals())
    })
  }

  const downloadRecipeFile = (link: string) => {
    return axios.get(link, { responseType: 'blob' })
  }

  const uploadSheet = async () => {
    const {
      minMaxFlours,
      incomingWheats,
      forecastFlours,
      openingStockWheats,
      wheatSales,
      financial,
      wheatReplacementPrices,
      month
    } = form.values

    const blobPartMaster = await new MasterExporter(
      inStockWheatSpecs,
      expectedWheatSpecs,
      flourSpecs,
      date,
      plantSite
    ).getBuffer()
    const blobPartSwimof = await new SwimofExporter(
      minMaxFlours,
      incomingWheats,
      referenceWheats,
      forecastFlours,
      openingStockWheats,
      wheatSales,
      financial,
      wheatReplacementPrices
    ).getBuffer()

    const blobMaster = new Blob([blobPartMaster])
    const blobSwimof = new Blob([blobPartSwimof])

    const formData = new FormData()
    formData.append('master', blobMaster, 'master.xlsx')
    formData.append('swimof', blobSwimof, 'swimof.xlsx')
    formData.append('months', month.toString())

    return await axios.post(
      '//cassava.cerestar.net/api/calculate',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 100000
      }
    )
  }

  const handleClick = async () => {
    if (!isLoading && form.isValid) {
      try {
        setLoading(true)

        importMinMaxConstraints(form.values.minMaxFlours)
        importIncomingWheats(form.values.incomingWheats)
        importSalesForecasts(form.values.forecastFlours)
        importOpeningStocks(form.values.openingStockWheats)
        importWheatSales(form.values.wheatSales)
        importFinancial(form.values.financial)
        importWheatReplacementPrices(form.values.wheatReplacementPrices)
        importMonth(form.values.month)

        const uploadResponse = await uploadSheet()

        if (uploadResponse.data.error) {
          handleOpen(uploadResponse.data.message, uploadResponse.data.details)
        } else {
          const downloadResponse = await downloadRecipeFile(
            '//' + uploadResponse.data.recipeLink
          )

          importRecipeFile(new Blob([downloadResponse.data]))
          history.push('/recipe')
        }
      } catch (err) {
        handleOpen('Something when wrong', err.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const renderLoading = () => {
    return (
      <Box marginLeft="15px" marginTop="5px">
        <CircularProgress color="inherit" size="20px" />
      </Box>
    )
  }

  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      Calculate Recipe
      {isLoading && renderLoading()}
    </Button>
  )
}

export default CalculateButton
