import React, { useContext } from 'react'
import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody as MaterialTableBody,
  colors
} from '@material-ui/core'
import styled from 'styled-components'
import { FieldArray, FormikProps, getIn } from 'formik'
import {
  WheatOrigin,
  WheatHardness,
  WheatColorType,
  InStockWheatSpec
} from 'interfaces/master/sheetInStockWheatSpecs'
import { TextField, DateField, Select } from 'components/formikWrapper'
import * as yup from 'yup'
import _ from 'lodash'
import { confirmContext } from 'components/confirm'
import { PageProps } from '../Page.Container'

const initialValues: InStockWheatSpec = {
  code: '',
  arrivalDate: '',
  typeName: '',
  origin: WheatOrigin.USA,
  rawPrice: 0,
  testWeight: 0,
  screening: 0,
  moisture: 0,
  millingExtractionRate: 0,
  hardness: WheatHardness.SOFT,
  colorType: WheatColorType.RED,
  flourProtein: 0,
  flourWetGluten: 0,
  flourFallingNumber: 0,
  waterAbsorption: 0,
  doughDevelopmentTime: 0,
  stabilityTime: 0,
  water: 0,
  sucrose: 0,
  sodiumCarbonate: 0,
  lacticAcid: 0,
  spreadRatio: 0,
  maxGelatinisation: 0,
  targetMoisture: 0,
  millingInvisibleLoss: 0,
  byProductPrice: 0
}

export const validationSchemaInStockWheatSpec = yup.object().shape({
  code: yup
    .string()
    .required()
    .label('code'),
  arrivalDate: yup
    .string()
    .required()
    .label('arrival date'),
  typeName: yup
    .string()
    .required()
    .label('type name'),
  origin: yup.string().required(),
  rawPrice: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('raw price'),
  testWeight: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('test weight'),
  screening: yup
    .number()
    .typeError('must be a number')
    .min(0)
    .max(100)
    .required(),
  moisture: yup
    .number()
    .typeError('must be a number')
    .min(0)
    .max(100)
    .required(),
  millingExtractionRate: yup
    .number()
    .typeError('must be a number')
    .min(0)
    .max(100)
    .required()
    .label('milling extraction rate'),
  hardness: yup.string().required(),
  colorType: yup
    .string()
    .required()
    .label('color type'),
  flourProtein: yup
    .number()
    .typeError('must be a number')
    .min(0)
    .max(100)
    .required()
    .label('flour protein'),
  flourWetGluten: yup
    .number()
    .typeError('must be a number')
    .min(0)
    .max(100)
    .required()
    .label('flour wet gluten'),
  flourFallingNumber: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('flour falling number'),
  waterAbsorption: yup
    .number()
    .typeError('must be a number')
    .min(0)
    .max(100)
    .required()
    .label('water absorption'),
  doughDevelopmentTime: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('dough development time'),
  stabilityTime: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('stability time'),
  water: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required(),
  sucrose: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required(),
  sodiumCarbonate: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('sodium carbonate'),
  lacticAcid: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('lactic acid'),
  spreadRatio: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('spread ratio'),
  maxGelatinisation: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('max gelatinisation'),
  targetMoisture: yup
    .number()
    .typeError('must be a number')
    .min(0)
    .max(100)
    .required()
    .label('target moisture'),
  millingInvisibleLoss: yup
    .number()
    .typeError('must be a number')
    .min(0)
    .max(100)
    .required()
    .label('milling invisible loss'),
  byProductPrice: yup
    .number()
    .moreThan(-1)
    .typeError('must be a number')
    .required()
    .label('by product price')
})

const TabInStockWheatSpecs = () => {
  const { handleOpen } = useContext(confirmContext)

  const isRecordError = (
    index: number,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    return getIn(form.errors, `inStockWheatSpecs.${index}`)
  }

  const isRecordEven = (index: number) => {
    return (index + 1) % 2 === 0
  }

  const renderInput = (index: number, key: keyof InStockWheatSpec) => {
    switch (key) {
      case 'arrivalDate': {
        return (
          <DateField
            name={`inStockWheatSpecs.${index}.${key}`}
            label={_.startCase(key)}
          />
        )
      }
      case 'origin': {
        return (
          <Select
            name={`inStockWheatSpecs.${index}.${key}`}
            label={_.startCase(key)}
            options={[
              { label: 'USA', value: WheatOrigin.USA },
              { label: 'Canada', value: WheatOrigin.CANADA },
              { label: 'Australia', value: WheatOrigin.AUSTRALIA },
              { label: 'Black Sea', value: WheatOrigin.BLACK_SEA },
              { label: 'Argentina', value: WheatOrigin.ARGENTINA },
              { label: 'Europe', value: WheatOrigin.EUROPE },
              { label: 'Other', value: WheatOrigin.OTHER }
            ]}
          />
        )
      }
      case 'hardness': {
        return (
          <Select
            name={`inStockWheatSpecs.${index}.${key}`}
            label={_.startCase(key)}
            options={[
              { label: 'Soft', value: WheatHardness.SOFT },
              { label: 'Medium', value: WheatHardness.MEDIUM },
              { label: 'Hard', value: WheatHardness.HARD }
            ]}
          />
        )
      }
      case 'colorType': {
        return (
          <Select
            name={`inStockWheatSpecs.${index}.${key}`}
            label={_.startCase(key)}
            options={[
              { label: 'Red', value: WheatColorType.RED },
              { label: 'White', value: WheatColorType.WHITE },
              { label: 'Other', value: WheatColorType.OTHER }
            ]}
          />
        )
      }
      default: {
        return (
          <TextField
            name={`inStockWheatSpecs.${index}.${key}`}
            label={_.startCase(key)}
          />
        )
      }
    }
  }

  const renderCells = (
    key: keyof InStockWheatSpec,
    form: FormikProps<PageProps['initialValues']>,
    isReadOnly?: boolean
  ) => {
    const { inStockWheatSpecs } = form.values
    return inStockWheatSpecs.map((inStockWheatSpec, index) => (
      <TableCell
        key={index}
        isError={isRecordError(index, form)}
        isEven={isRecordEven(index)}
      >
        {isReadOnly ? inStockWheatSpec[key] : renderInput(index, key)}
      </TableCell>
    ))
  }

  const renderEmpty = () => {
    return (
      <Box
        height="200px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="subtitle1">
          There's no data yet, please create new one or import from *.xlsx file
        </Typography>
      </Box>
    )
  }

  const renderData = (
    form: FormikProps<PageProps['initialValues']>,
    remove: (index: number) => void
  ) => {
    const { inStockWheatSpecs } = form.values
    return inStockWheatSpecs.length ? (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Specification</TableCell>
              {inStockWheatSpecs.map((inStockWheatSpec, index) => (
                <TableCell key={index}>
                  <Grid container justify="space-between" alignItems="center">
                    {`Wheat ${index + 1}`}
                    <Button
                      color="secondary"
                      onClick={() =>
                        handleOpen(
                          'Remove Wheat',
                          'Are you sure want to remove wheat ?',
                          () => remove(index)
                        )
                      }
                    >
                      Remove
                    </Button>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Wheat Code</TableCell>
              {renderCells('code', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">Type Name</TableCell>
              {renderCells('typeName', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">Arrival Date</TableCell>
              {renderCells('arrivalDate', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">Origin</TableCell>
              {renderCells('origin', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Wheat Raw Price</Grid>
                  <Grid item>(US $ / MT)</Grid>
                </Grid>
              </TableCell>
              {renderCells('rawPrice', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Wheat Test Weight</Grid>
                  <Grid item>(Kg / hl)</Grid>
                </Grid>
              </TableCell>
              {renderCells('testWeight', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Wheat Screening</Grid>
                  <Grid item>(Unmillable) (%)</Grid>
                </Grid>
              </TableCell>
              {renderCells('screening', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Wheat Moisture</Grid>
                  <Grid item>(%)</Grid>
                </Grid>
              </TableCell>
              {renderCells('moisture', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Milling Extraction Rate</Grid>
                  <Grid item>(%)</Grid>
                </Grid>
              </TableCell>
              {renderCells('millingExtractionRate', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">Wheat Hardness</TableCell>
              {renderCells('hardness', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">Wheat Color Type</TableCell>
              {renderCells('colorType', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Flour Protein</Grid>
                  <Grid item>(%, 14mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('flourProtein', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Flour Wet Gluten</Grid>
                  <Grid item>(%, 14mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('flourWetGluten', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Flour Falling Number</Grid>
                  <Grid item>(sec, 14mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('flourFallingNumber', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Water Absorption</Grid>
                  <Grid item>(Farino) (%, 14mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('waterAbsorption', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Dough Development Time</Grid>
                  <Grid item>(Farino) (sec)</Grid>
                </Grid>
              </TableCell>
              {renderCells('doughDevelopmentTime', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Stability Time</Grid>
                  <Grid item>(Farino) (sec)</Grid>
                </Grid>
              </TableCell>
              {renderCells('stabilityTime', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Water</Grid>
                  <Grid item>(SRC) (%, 14mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('water', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Sucrose</Grid>
                  <Grid item>(SRC) (%, 14mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('sucrose', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Sodium Carbonate</Grid>
                  <Grid item>(SRC) (%, 14mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('sodiumCarbonate', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Lactic Acid</Grid>
                  <Grid item>(SRC) (%, 14mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('lacticAcid', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Spread Ratio</Grid>
                  <Grid item>(Cookie Test)</Grid>
                </Grid>
              </TableCell>
              {renderCells('spreadRatio', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Max Gelatinisation</Grid>
                  <Grid item>(Visco-Amylo) (BU)</Grid>
                </Grid>
              </TableCell>
              {renderCells('maxGelatinisation', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>1B Target Moisture</Grid>
                  <Grid item>(%)</Grid>
                </Grid>
              </TableCell>
              {renderCells('targetMoisture', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Milling Invisible Loss</Grid>
                  <Grid item>(%)</Grid>
                </Grid>
              </TableCell>
              {renderCells('millingInvisibleLoss', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>By Product Price</Grid>
                  <Grid item>(US$ / MT)</Grid>
                </Grid>
              </TableCell>
              {renderCells('byProductPrice', form)}
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      renderEmpty()
    )
  }

  return (
    <FieldArray
      name="inStockWheatSpecs"
      validateOnChange={false}
      render={({ form, push, remove }) => (
        <Paper>
          <Box padding={3}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h6">In Stock Wheat Specs</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    push({ ...initialValues, arrivalDate: form.values.date })
                  }
                >
                  Add Wheat
                </Button>
              </Grid>
            </Grid>

            {renderData(form, remove)}
          </Box>
        </Paper>
      )}
    />
  )
}

export default TabInStockWheatSpecs

const TableContainer = styled.div`
  max-width: 100%;
  max-height: calc(100vh - 245px);
  overflow: auto;
  position: relative;
`

const tableCellHeight = 80

function getCellColor(isError?: boolean, isEven?: boolean) {
  return isError ? colors.red[50] : isEven ? colors.grey[50] : 'white'
}

interface TableCellProps {
  isError?: boolean
  isEven?: boolean
}

const TableCell = styled(MaterialTableCell)<TableCellProps>`
  white-space: nowrap;
  min-width: 200px;
  height: ${tableCellHeight}px;
  background-color: ${({ isError, isEven }) => getCellColor(isError, isEven)};
`

const TableBody = styled(MaterialTableBody)`
  & tr:nth-child(1) td,
  tr:nth-child(2) td {
    position: sticky;
    z-index: 2;
  }

  & tr:nth-child(1) td {
    top: 0px;
  }

  & tr:nth-child(2) td {
    top: ${tableCellHeight}px;
  }
`
