import { Reducer, combineReducers } from 'redux'
import { SheetOpeningStocksActionTypes } from './types'
import { SheetOpeningStocks } from 'interfaces/swimof/sheetOpeningStocks'

const initialState: SheetOpeningStocks = {
  openingStockWheats: []
}

const openingStockWheats: Reducer<SheetOpeningStocks['openingStockWheats']> = (
  state = initialState.openingStockWheats,
  { type, payload }
) => {
  switch (type) {
    case SheetOpeningStocksActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetOpeningStocksActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetOpeningStocksReducer = combineReducers<SheetOpeningStocks>({
  openingStockWheats
})
