import React, { Component, ChangeEvent } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead as MaterialTableHead,
  TableRow as MaterialTableRow,
  TableCell as MaterialTableCell,
  TableBody,
  Grid,
  Select,
  MenuItem,
  Button,
  TextField as MaterialTextField,
  InputLabel,
  FormControl,
  colors,
  FormHelperText,
} from '@material-ui/core'
import { FieldArray, FormikProps, getIn, ErrorMessage } from 'formik'

import * as yup from 'yup'
import { NumberField } from 'components/formikWrapper'
import { FlourUsage, FlourSpec } from 'interfaces/master/sheetFlourSpecs'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import { PageProps } from '../Page.Container'
import { RecipeWheat, RecipeFlour, Recipe } from 'interfaces/generic/recipe'
import isExceedLimit from '../utils/isExceedLimit'
import getTotalValue from '../utils/getTotalValue'

export const validationSchemaConstrained = yup.object().shape({
  date: yup.string().required().label('date'),
  recipeFlours: yup.array(
    yup.object().shape({
      name: yup.string().required().label('name'),
      recipeWheats: yup
        .array(
          yup.object().shape({
            name: yup.string().label('name'),
            value: yup
              .number()
              .typeError('must be a number')
              .required()
              .label('value'),
          })
        )
        .test(
          'sum',
          'every row has to sum to 100',
          (recipeWheats: RecipeWheat[]) => {
            return getTotalValue(recipeWheats) === 100
          }
        ),
    })
  ),
})

interface TabConstrainedProps {
  flourSpecs: FlourSpec[]
}

interface TabConstrainedState {
  flourNameKeyword: string
  selectedFlourUsage?: string
}

export default class TabConstrained extends Component<
  TabConstrainedProps,
  TabConstrainedState
> {
  state: TabConstrainedState = {
    flourNameKeyword: '',
    selectedFlourUsage: undefined,
  }

  handleClearButtonClick = () => {
    this.setState({ flourNameKeyword: '', selectedFlourUsage: undefined })
  }

  handleFlourUsageChange = (event: ChangeEvent<any>) => {
    this.setState({ selectedFlourUsage: event.target.value })
  }

  handleFlourNameChange = (event: ChangeEvent<any>) => {
    this.setState({ flourNameKeyword: event.target.value })
  }

  getFlourUsage = (flourName: string) => {
    const flourSpec = this.props.flourSpecs.find(
      (flourSpec) => flourSpec.name === flourName
    )
    return flourSpec ? flourSpec.usage : ''
  }

  getRecipeFloursByUsage = (recipeFlours: RecipeFlour[]) => {
    const { selectedFlourUsage } = this.state
    return selectedFlourUsage
      ? recipeFlours.filter(
          ({ name }) => this.getFlourUsage(name) === selectedFlourUsage
        )
      : recipeFlours
  }

  getRecipeFloursByName = (recipeFlours: RecipeFlour[]) => {
    return recipeFlours.filter(({ name }) =>
      name.toLowerCase().includes(this.state.flourNameKeyword.toLowerCase())
    )
  }

  getConstraintFlours = (recipeFlours: RecipeFlour[]) => {
    return this.getRecipeFloursByUsage(this.getRecipeFloursByName(recipeFlours))
  }

  renderWheatNames = (recipe: Recipe) => {
    return (
      recipe.recipeFlours.length > 0 &&
      recipe.recipeFlours[0].recipeWheats.map((recipeWheat, index) => (
        <TableCell variant="head" key={index}>
          <div>
            {recipeWheat.name
              .replace('(', '')
              .replace(')', '')
              .split(',')
              .map((e) => (
                <div>{e}</div>
              ))}
          </div>
        </TableCell>
      ))
    )
  }

  renderWheatValues = (
    recipeFlour: RecipeFlour,
    indexRecipe: number,
    indexFlour: number
  ) => {
    return recipeFlour.recipeWheats.map((recipeWheat, indexWheat) => (
      <TableCell key={indexWheat}>
        <NumberField
          name={`constraints.${indexRecipe}.recipeFlours.${indexFlour}.recipeWheats.${indexWheat}.value`}
          label="Value"
          isInteger
        />
      </TableCell>
    ))
  }

  isRecordError = (
    indexRecipe: number,
    indexFlour: number,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    return getIn(
      form.errors,
      `constraints.${indexRecipe}.recipeFlours.${indexFlour}`
    )
  }

  isRecordEven = (index: number) => {
    return (index + 1) % 2 === 0
  }

  renderTables = (form: FormikProps<PageProps['initialValues']>) => {
    const { constraints } = form.values
    return constraints.map((recipe, indexRecipe) => (
      <Box marginBottom={3}>
        <Table>
          <TableHead>
            <TableRow>
              <StickyTableCell
                variant="head"
                position="left"
                style={{ zIndex: 5 }}
              >
                {recipe.date}
              </StickyTableCell>
              {this.renderWheatNames(recipe)}
              <TableCell></TableCell>
              <StickyTableCell
                position="right"
                variant="head"
                stack={2}
                style={{ zIndex: 5 }}
              >
                Protein
              </StickyTableCell>
              <StickyTableCell
                position="right"
                variant="head"
                stack={1}
                style={{ zIndex: 5 }}
              >
                Wet Gluten
              </StickyTableCell>
              <StickyTableCell
                position="right"
                variant="head"
                stack={0}
                style={{ zIndex: 5 }}
              >
                Water Absorption
              </StickyTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.getConstraintFlours(recipe.recipeFlours).map(
              (recipeFlour, indexFlour) => (
                <TableRow
                  key={indexFlour}
                  isError={this.isRecordError(indexRecipe, indexFlour, form)}
                  isEven={this.isRecordEven(indexFlour)}
                >
                  <StickyTableCell position="left">
                    <Grid container direction="column">
                      <Grid item>{recipeFlour.name}</Grid>
                      <Grid item>{this.getFlourUsage(recipeFlour.name)}</Grid>
                    </Grid>
                  </StickyTableCell>
                  {this.renderWheatValues(recipeFlour, indexRecipe, indexFlour)}
                  <TableCell>
                    <ErrorMessage
                      name={`constraints.${indexRecipe}.recipeFlours.${indexFlour}.recipeWheats`}
                      render={(message) => (
                        <FormHelperText error>{message}</FormHelperText>
                      )}
                    />
                  </TableCell>
                  <StickyTableCell position="right" stack={2}>
                    {recipeFlour.protein}
                    {isExceedLimit(recipeFlour.protein) && (
                      <FormHelperText error>
                        value go below or above limit
                      </FormHelperText>
                    )}
                  </StickyTableCell>
                  <StickyTableCell position="right" stack={1}>
                    {recipeFlour.wetGluten}
                    {isExceedLimit(recipeFlour.wetGluten) && (
                      <FormHelperText error>
                        value go below or above limit
                      </FormHelperText>
                    )}
                  </StickyTableCell>
                  <StickyTableCell position="right" stack={0}>
                    {recipeFlour.waterAbsorption}
                    {isExceedLimit(recipeFlour.waterAbsorption) && (
                      <FormHelperText error>
                        value go below or above limit
                      </FormHelperText>
                    )}
                  </StickyTableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Box>
    ))
  }

  renderSearch = () => {
    return (
      <Box marginY={2}>
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid item>
            <MaterialTextField
              label="Flour Name"
              value={this.state.flourNameKeyword}
              onChange={this.handleFlourNameChange}
            />
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 150 }}>
              <InputLabel>Flour Usage</InputLabel>
              <Select
                value={this.state.selectedFlourUsage}
                onChange={this.handleFlourUsageChange}
              >
                {Object.values(FlourUsage).map((value) => (
                  <MenuItem value={value}>{_.startCase(value)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.handleClearButtonClick}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  render() {
    return (
      <FieldArray
        name="constraints"
        validateOnChange={false}
        render={({ form }) => (
          <Paper>
            <Box padding={3}>
              <Typography variant="h6">Constrained</Typography>
              {this.renderSearch()}
              {this.renderTables(form)}
            </Box>
          </Paper>
        )}
      />
    )
  }
}

const TableHead = styled(MaterialTableHead)`
  & th {
    z-index: 3;
    top: 64px;
    position: sticky;
    background-color: ${colors.grey[50]};
  }
`

function getCellColor(isError?: boolean, isEven?: boolean) {
  return isError ? colors.red[50] : isEven ? colors.grey[50] : 'white'
}

interface TableRowProps {
  isError?: boolean
  isEven?: boolean
}

const TableRow = styled(MaterialTableRow)<TableRowProps>`
  background-color: ${({ isError, isEven }) => getCellColor(isError, isEven)};
`

const TableCell = styled(MaterialTableCell)`
  white-space: nowrap;
  min-width: 10px;
  padding: 10px 5px !important;
`

const stickyWidth = 120
const stickyHeight = 50

interface StickyTableCell {
  position: 'top' | 'left' | 'right'
  stack?: number
}

const StickyTableCell = styled(TableCell)<StickyTableCell>`  
  position: sticky;
  background-color: inherit;
  z-index: 3;

  ${({ position, stack }) =>
    position === 'right' &&
    css`
      right: ${stickyWidth * (stack ? stack : 0)}px;
      width: ${stickyWidth}px;
    `}

  ${({ position, stack }) =>
    position === 'left' &&
    css`
      left: ${stickyWidth * (stack ? stack : 0)}px;
      width: ${stickyWidth}px;
    `}

  ${({ position, stack }) =>
    position === 'top' &&
    css`
      top: ${stickyHeight * (stack ? stack : 0)}px;
      height: ${stickyHeight}px;
    `}
`
