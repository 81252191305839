import React, { FC } from 'react'
import { connect } from 'react-redux'
import CalculateButton from './CalculateButton'
import { RootStore } from 'interfaces/stores'
import { InStockWheatSpec } from 'interfaces/master/sheetInStockWheatSpecs'
import { ExpectedWheatSpec } from 'interfaces/master/sheetExpectedWheatSpecs'
import { FlourSpec } from 'interfaces/master/sheetFlourSpecs'

import { importMinMaxConstraints } from 'stores/swimof/sheetMinMaxConstraints/actions'
import { importIncomingWheats } from 'stores/swimof/sheetIncomingWheat/actions'
import { importSalesForecasts } from 'stores/swimof/sheetSalesForecast/actions'
import { importOpeningStocks } from 'stores/swimof/sheetOpeningStocks/actions'
import { importWheatSales } from 'stores/swimof/sheetWheatSales/actions'
import { importFinancial } from 'stores/swimof/sheetFinancials/actions'
import { importWheatReplacementPrices } from 'stores/swimof/sheetWheatReplacementPrices/actions'
import { importConstraints } from 'stores/recipe/sheetConstrained/actions'
import { importIdeals } from 'stores/recipe/sheetIdeal/actions'
import { importMonth } from 'stores/month/actions'

import { FormikProps } from 'formik'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { PageProps } from 'modules/swimof/Page.Container'

interface PropsFromState {
  inStockWheatSpecs: InStockWheatSpec[]
  expectedWheatSpecs: ExpectedWheatSpec[]
  flourSpecs: FlourSpec[]
  date: string
  plantSite: string
  referenceWheats: string[]
}

interface PropsFromDispatch {
  importMinMaxConstraints: typeof importMinMaxConstraints
  importIncomingWheats: typeof importIncomingWheats
  importSalesForecasts: typeof importSalesForecasts
  importOpeningStocks: typeof importOpeningStocks
  importWheatSales: typeof importWheatSales
  importFinancial: typeof importFinancial
  importWheatReplacementPrices: typeof importWheatReplacementPrices
  importConstraints: typeof importConstraints
  importIdeals: typeof importIdeals
  importMonth: typeof importMonth
}

export type CalculateButtonProps = PropsFromState &
  PropsFromDispatch &
  RouteComponentProps & {
    form: FormikProps<PageProps['initialValues']>
  }

const CalculateButtonContainer: FC<CalculateButtonProps> = props => (
  <CalculateButton {...props} />
)

const mapStateToProps = ({
  master: {
    sheetInStockWheatSpecs,
    sheetExpectedWheatSpecs,
    sheetFlourSpecs,
    sheetDate
  },
  swimof: { sheetIncomingWheat }
}: RootStore): PropsFromState => ({
  inStockWheatSpecs: sheetInStockWheatSpecs.wheats,
  expectedWheatSpecs: sheetExpectedWheatSpecs.wheats,
  flourSpecs: sheetFlourSpecs.flours,
  date: sheetDate.date,
  plantSite: sheetDate.plantSite,
  referenceWheats: sheetIncomingWheat.referenceWheats
})

const mapDispatchToProps: PropsFromDispatch = {
  importMinMaxConstraints,
  importIncomingWheats,
  importSalesForecasts,
  importOpeningStocks,
  importWheatSales,
  importFinancial,
  importWheatReplacementPrices,
  importConstraints,
  importIdeals,
  importMonth
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalculateButtonContainer)
)
