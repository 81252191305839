const isExceedLimit = (rangeValue: string) => {
  const value = rangeValue.split(' ')[0]
  const minRange = rangeValue
    .split(' ')[2]
    .split(',')[0]
    .replace('[', '')
    .replace(',', '')
    .trim()
  const maxRange = rangeValue.split(' ')[3].replace(']', '')

  const isValueInMinRange = parseFloat(minRange) <= parseFloat(value)
  const isValueInMaxRange = parseFloat(value) <= parseFloat(maxRange)
  const isMinRangeInfinite = minRange === '-∞'
  const isMaxRangeInfinite = maxRange === '∞'
  return !(
    (isValueInMinRange || isMinRangeInfinite) &&
    (isValueInMaxRange || isMaxRangeInfinite)
  )
}

export default isExceedLimit
