import React, { FC } from 'react'
import { connect } from 'react-redux'
import ExportButton from './ExportButton'
import { importMinMaxConstraints } from 'stores/swimof/sheetMinMaxConstraints/actions'
import { importIncomingWheats } from 'stores/swimof/sheetIncomingWheat/actions'
import { importSalesForecasts } from 'stores/swimof/sheetSalesForecast/actions'
import { importOpeningStocks } from 'stores/swimof/sheetOpeningStocks/actions'
import { importWheatSales } from 'stores/swimof/sheetWheatSales/actions'
import { importFinancial } from 'stores/swimof/sheetFinancials/actions'
import { importWheatReplacementPrices } from 'stores/swimof/sheetWheatReplacementPrices/actions'
import { FormikProps } from 'formik'
import { PageProps } from 'modules/swimof/Page.Container'
import { RootStore } from 'interfaces/stores'

interface PropsFromState {
  referenceWheats: string[]
}

interface PropsFromDispatch {
  importMinMaxConstraints: typeof importMinMaxConstraints
  importIncomingWheats: typeof importIncomingWheats
  importSalesForecasts: typeof importSalesForecasts
  importOpeningStocks: typeof importOpeningStocks
  importWheatSales: typeof importWheatSales
  importFinancial: typeof importFinancial
  importWheatReplacementPrices: typeof importWheatReplacementPrices
}

export type ExportButtonProps = PropsFromState &
  PropsFromDispatch & {
    form: FormikProps<PageProps['initialValues']>
  }

const ExportButtonContainer: FC<ExportButtonProps> = props => (
  <ExportButton {...props} />
)

const mapStateToProps = ({
  swimof: { sheetIncomingWheat }
}: RootStore): PropsFromState => ({
  referenceWheats: sheetIncomingWheat.referenceWheats
})

const mapDispatchToProps: PropsFromDispatch = {
  importMinMaxConstraints,
  importIncomingWheats,
  importSalesForecasts,
  importOpeningStocks,
  importWheatSales,
  importFinancial,
  importWheatReplacementPrices
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportButtonContainer)
