import { createStore, applyMiddleware, Reducer, AnyAction } from 'redux'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { buildRootReducer } from './reducers'
import { RootStore } from 'interfaces/stores'
import { environment } from 'utils/environment'

const persistConfig = {
  key: 'root',
  storage
}

export function configureStore() {
  const history = createBrowserHistory()
  const rootReducer = buildRootReducer(history)
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const reducer = (environment === 'dev'
    ? persistedReducer
    : rootReducer) as Reducer<RootStore, AnyAction>

  const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(routerMiddleware(history)))
  )
  const persistor = persistStore(store)
  return { store, history, persistor }
}
