import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { ExportButtonProps } from './ExportButton.Container'
import { SwimofExporter } from 'utils/excel'

const ExportButton: FC<ExportButtonProps> = ({
  referenceWheats,
  importMinMaxConstraints,
  importIncomingWheats,
  importSalesForecasts,
  importOpeningStocks,
  importWheatSales,
  importFinancial,
  importWheatReplacementPrices,
  form
}) => {
  const handleClick = () => {
    form.validateForm().then(() => {
      if (form.isValid) {
        const {
          minMaxFlours,
          incomingWheats,
          forecastFlours,
          openingStockWheats,
          wheatSales,
          financial,
          wheatReplacementPrices
        } = form.values

        importMinMaxConstraints(minMaxFlours)
        importIncomingWheats(incomingWheats)
        importSalesForecasts(forecastFlours)
        importOpeningStocks(openingStockWheats)
        importWheatSales(wheatSales)
        importFinancial(financial)
        importWheatReplacementPrices(wheatReplacementPrices)

        new SwimofExporter(
          minMaxFlours,
          incomingWheats,
          referenceWheats,
          forecastFlours,
          openingStockWheats,
          wheatSales,
          financial,
          wheatReplacementPrices
        ).download()
      }
    })
  }

  return (
    <Button variant="outlined" color="primary" onClick={handleClick}>
      Export
    </Button>
  )
}

export default ExportButton
