import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody as MaterialTableBody,
  colors
} from '@material-ui/core'
import styled, { css } from 'styled-components'
import { EffectiveCalc } from 'interfaces/wup/sheetEffectiveCalc'
import { getUSValue } from 'utils/number'

interface TabEffectiveCalcProps {
  effectiveCalcs: EffectiveCalc[]
}

export default class TabEffectiveCalc extends Component<TabEffectiveCalcProps> {
  renderEffectiveCalcValues = (effectiveCalc: EffectiveCalc) => {
    return Object.keys(effectiveCalc).map(key => {
      const value = effectiveCalc[key as keyof EffectiveCalc]
      return (
        key !== 'name' && (
          <TableCell isNegative={value < 0}>
            {getUSValue(value)}
          </TableCell>
        )
      )
    })
  }

  renderData = () => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">In-Stock Wheats</TableCell>
              <TableCell variant="head">Effective Price</TableCell>
              <TableCell variant="head">Effective Stock</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.effectiveCalcs.map((effectiveCalc, index) => (
              <TableRow key={index}>
                <TableCell variant="head">{effectiveCalc.name}</TableCell>
                {this.renderEffectiveCalcValues(effectiveCalc)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  render() {
    return (
      <Paper>
        <Box padding={3}>
          <Typography variant="h6">Effective Calc</Typography>
          {this.renderData()}
        </Box>
      </Paper>
    )
  }
}

const TableContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
  margin-bottom: 30;
`

const TableBody = styled(MaterialTableBody)`
  & tr:nth-child(even) {
    background-color: ${colors.grey[50]};
  }
`

interface TableCell {
  isNegative?: boolean
}

const TableCell = styled(MaterialTableCell)<TableCell>`
  white-space: nowrap;
  min-width: 200px;

  ${props =>
    props.isNegative &&
    css`
      background-color: ${colors.red[50]};
    `}
`
