import React from 'react'
import 'typeface-roboto'

import { CssBaseline } from '@material-ui/core'
import Main from './modules/main'
import Login from './modules/login'

import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { configureStore } from './stores'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from 'components/protectedRoute'
import { ConfirmContainer, ConfirmDialog } from 'components/confirm'
import { MessageContainer, MessageDialog } from 'components/message'
import { environment } from 'utils/environment'

const { store, history, persistor } = configureStore()

const PersistProvider: React.FC = ({ children }) =>
  environment === 'dev' ? (
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  ) : (
    <>{children}</>
  )

const App: React.FC = () => {
  React.useEffect(() => {
    if (history.location.pathname !== '/master' && environment !== 'dev')
      history.push('/master')
  }, [])

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <PersistProvider>
          <ConfirmContainer>
            <MessageContainer>
              <ConfirmDialog />
              <MessageDialog />
              <CssBaseline />
              <Switch>
                <Route path="/login" component={Login} />
                <ProtectedRoute path="/" component={Main} />
              </Switch>
            </MessageContainer>
          </ConfirmContainer>
        </PersistProvider>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
