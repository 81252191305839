import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { RootStore } from 'interfaces/stores'
import { masterReducers } from './master/reducers'
import { swimofReducers } from './swimof/reducers'
import { recipeReducers } from './recipe/reducers'
import { wupReducers } from './wup/reducers'
import { monthReducers } from './month/reducers'

export function buildRootReducer(history: History) {
  return combineReducers<RootStore>({
    router: connectRouter(history),
    master: masterReducers,
    swimof: swimofReducers,
    recipe: recipeReducers,
    wup: wupReducers,
    month: monthReducers
  })
}
