import React from 'react'
import Master from 'modules/master'
import Swimof from 'modules/swimof'
import Recipe from 'modules/recipe'
import Wup from 'modules/wup'

export interface Route {
  path: string
  label: string
  component: React.ComponentClass | React.FunctionComponent
}

export const routes: Route[] = [
  {
    path: '/master',
    label: 'Master',
    component: Master
  },
  {
    path: '/swimof',
    label: 'SWIMOF',
    component: Swimof
  },
  {
    path: '/recipe',
    label: 'Recipe',
    component: Recipe
  },
  {
    path: '/wup',
    label: 'WUP',
    component: Wup
  }
]
