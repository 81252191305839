import Excel from 'exceljs'
import { IncomingWheat } from 'interfaces/swimof/sheetIncomingWheat'
import { OpeningStockWheat } from 'interfaces/swimof/sheetOpeningStocks'
import { MinMaxFlour } from 'interfaces/generic/minMaxConstraint'
import { InStockWheatSpec } from 'interfaces/master/sheetInStockWheatSpecs'
import { ExpectedWheatSpec } from 'interfaces/master/sheetExpectedWheatSpecs'
import { FlourSpec } from 'interfaces/master/sheetFlourSpecs'
import {
  getMasterWheatOriginAndNames,
  getWheatOriginAndNames,
  getWheatOriginNameAndCodes,
  setDifference,
  validateSwimofAgainstMasterDataSymmetrically
} from 'utils/excel/swimofValidationUtils'

export class SwimofImporter {
  private workbook = new Excel.Workbook()

  constructor(workbook: Excel.Workbook) {
    this.workbook = workbook
  }

  private getData = (sheetName: string, fieldName: string) => {
    const data: any[] = []
    const worksheet = this.workbook.getWorksheet(sheetName)
    const firstRow = worksheet.getRow(1).values as string[]

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowNumber > 1) {
        data.push({
          name: rowValues[1],
          [fieldName]: rowValues
            .slice(2, rowValues.length)
            .map((value, index) => ({
              name: firstRow[index + 2] as string,
              value: typeof value === 'object' ? value.result : value
            }))
        })
      }
    })
    return data
  }

  getMinMaxConstraints = (): MinMaxFlour[] => {
    return this.getData('min_max_constraints', 'minMaxWheats')
  }

  getSalesForecasts = () => {
    return this.getData('sales_forecast', 'forecastDates')
  }

  getWheatSales = () => {
    return this.getData('wheat_sales', 'dateSales')
  }

  getWheatReplacementPrices = () => {
    return this.getData('wheat_replacement_prices', 'dateReplacementPrices')
  }

  getFinancial = () => {
    const worksheet = this.workbook.getWorksheet('financials')

    const exchangeRate = (worksheet.getRow(1).values as any[])[2]
    const packagingMaterials = (worksheet.getRow(4).values as any[])[2]
    const flourAdditives = (worksheet.getRow(5).values as any[])[2]
    const peopleCost = (worksheet.getRow(6).values as any[])[2]
    const electricity = (worksheet.getRow(7).values as any[])[2]
    const maintenance = (worksheet.getRow(8).values as any[])[2]
    const othersAndFacilities = (worksheet.getRow(9).values as any[])[2]
    const operatingSupplies = (worksheet.getRow(10).values as any[])[2]
    const factoryDepreciation = (worksheet.getRow(11).values as any[])[2]
    const hqDepreciation = (worksheet.getRow(12).values as any[])[2]
    const vat = (worksheet.getRow(13).values as any[])[2]
    const salesAndAdmin = (worksheet.getRow(14).values as any[])[2]
    const logistics = (worksheet.getRow(15).values as any[])[2]
    const amortization = (worksheet.getRow(16).values as any[])[2]
    const financeCost = (worksheet.getRow(17).values as any[])[2]

    return {
      exchangeRate,
      packagingMaterials,
      flourAdditives,
      peopleCost,
      electricity,
      maintenance,
      othersAndFacilities,
      operatingSupplies,
      factoryDepreciation,
      hqDepreciation,
      vat,
      salesAndAdmin,
      logistics,
      amortization,
      financeCost
    }
  }

  getIncomingWheats = () => {
    const incomingWheats: IncomingWheat[] = []
    const worksheet = this.workbook.getWorksheet('incoming_wheat')
    let startRow = 1

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowValues[1] === 'FILL BELOW') startRow = rowNumber
    })

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowNumber > startRow) {
        incomingWheats.push({
          name:
            typeof rowValues[1] === 'object'
              ? rowValues[1].result
              : rowValues[1],
          quantity:
            typeof rowValues[2] === 'object'
              ? rowValues[2].result
              : rowValues[2],
          price:
            typeof rowValues[3] === 'object'
              ? rowValues[3].result
              : rowValues[3],
          eta:
            typeof rowValues[4] === 'object'
              ? rowValues[4].result
              : rowValues[4],
          vessel:
            typeof rowValues[5] === 'object'
              ? rowValues[5].result
              : rowValues[5],
          remark:
            typeof rowValues[6] === 'object'
              ? rowValues[6].result
              : rowValues[6]
        })
      }
    })
    return incomingWheats
  }

  getReferenceWheats = () => {
    const referenceWheats: string[] = []
    const worksheet = this.workbook.getWorksheet('incoming_wheat')
    let endRow = 0

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowValues[1] === 'FILL BELOW') endRow = rowNumber
    })

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowNumber > 1 && rowNumber < endRow) {
        referenceWheats.push(rowValues[1])
      }
    })
    return referenceWheats
  }

  getOpeningStocks = () => {
    const openingStockWheats: OpeningStockWheat[] = []
    const worksheet = this.workbook.getWorksheet('opening_stocks')

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowNumber > 1) {
        openingStockWheats.push({
          name:
            typeof rowValues[1] === 'object'
              ? rowValues[1].result
              : rowValues[1],
          quantity:
            typeof rowValues[2] === 'object'
              ? rowValues[2].result
              : rowValues[2]
        })
      }
    })
    return openingStockWheats
  }

  validateMinMaxWheats = (
    inStockWheatSpecs: InStockWheatSpec[], expectedWheatSpecs: ExpectedWheatSpec[],
  ) => {
    const minMaxWheats = this.getMinMaxConstraints()[0]
      .minMaxWheats.map(({ name }) => name)
    const masterWheats = getMasterWheatOriginAndNames(
      inStockWheatSpecs, expectedWheatSpecs
    )
    const errorMessage = "Wheat, in_stock/expected_wheat_specs & min_max_constraints:"
    return validateSwimofAgainstMasterDataSymmetrically(
      minMaxWheats, masterWheats, errorMessage
    )
  }

  validateMinMaxFlours = (flourSpecs: FlourSpec[]) => {
    const minMaxFlours = this.getMinMaxConstraints().map(({ name }) => name)
    const masterFlours = flourSpecs.map(({ name }) => name)
    const errorMessage = "Flour, in_stock/expected_wheat_specs & min_max_constraints:"
    return validateSwimofAgainstMasterDataSymmetrically(
      minMaxFlours, masterFlours, errorMessage
    )
  }

  validateIncomingWheat = (expectedWheatSpecs: ExpectedWheatSpec[]) => {
    const incomingWheats = this.getIncomingWheats().map(({ name }) => name)
    const expectedWheats = getWheatOriginAndNames(expectedWheatSpecs)
    const wheatDifference = setDifference(incomingWheats, expectedWheats)
    if (wheatDifference.length === 0) {
      return { success: true, message: "Incoming wheat is valid" }
    } else {
      const errorMessage = `
        Wheat, expected & incoming_wheat: ${wheatDifference.join(", ")}.
      `
      return { success: false, message: errorMessage }
    }
  }

  validateSalesForecast = (flourSpecs: FlourSpec[]) => {
    const salesForecastFlourNames = this.getSalesForecasts().map(({ name }) => name)
    const masterFlourNames = flourSpecs.map(({ name }) => name)
    const errorMessage = "Flour, flour_specs & sales_forecast:"
    return validateSwimofAgainstMasterDataSymmetrically(
      salesForecastFlourNames, masterFlourNames, errorMessage
    )
  }

  validateOpeningStocks = (inStockWheatSpecs: InStockWheatSpec[]) => {
    const openingStockWheats = this.getOpeningStocks().map(({ name }) => name)
    const inStockWheats = getWheatOriginNameAndCodes(inStockWheatSpecs)
    const errorMessage = "Flour, in_stock & opening_stocks:"
    return validateSwimofAgainstMasterDataSymmetrically(
      openingStockWheats, inStockWheats, errorMessage
    )
  }

  validateWheatSales = (
    inStockWheatSpecs: InStockWheatSpec[], expectedWheatSpecs: ExpectedWheatSpec[]
  ) => {
    const wheatSalesWheats = this.getWheatSales().map(({ name }) => name)
    const masterWheats = getMasterWheatOriginAndNames(
      inStockWheatSpecs, expectedWheatSpecs
    )
    const errorMessage = "Wheat, in_stock/expected_wheat_specs & wheat_sales:"
    return validateSwimofAgainstMasterDataSymmetrically(
      wheatSalesWheats, masterWheats, errorMessage
    )
  }

  validateWheatReplacementPrices = (
    inStockWheatSpecs: InStockWheatSpec[], expectedWheatSpecs: ExpectedWheatSpec[]
  ) => {
    const wheatReplacementPricesWheats = this.getWheatReplacementPrices()
      .map(({ name }) => name)
    const inStockWheats = inStockWheatSpecs.map(({ typeName }) => typeName)
    const expectedWheats = expectedWheatSpecs.map(({ typeName }) => typeName)
    const masterWheats = Array.from(new Set([...inStockWheats, ...expectedWheats]))
    const errorMessage = "Wheat, in_stock/expected & wheat_replacement_prices:"
    return validateSwimofAgainstMasterDataSymmetrically(
      wheatReplacementPricesWheats, masterWheats, errorMessage
    )
  }
}
