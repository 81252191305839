import React, { Component, ChangeEvent } from "react";
import {
  Container,
  Tabs,
  Tab as MaterialTab,
  Box,
  Typography,
  Grid,
  Button,
  colors,
} from "@material-ui/core";
import TabPanel from "components/TabPanel";
import { Select } from "components/formikWrapper";
import CalculateButton from "./components/calculateButton";
import TabMinMaxConstraints from "./tabMinMaxConstraints";
import TabSalesForecast from "./tabSalesForecast";
import TabWheatSales from "./tabWheatSales";
import TabWheatReplacementPrices from "./tabWheatReplacementPrices";
import TabIncomingWheat from "./tabIncomingWheat";
import TabOpeningStocks from "./tabOpeningStocks";
import TabFinancials from "./tabFinancials";
import ImportButton from "./components/importButton";
import ExportButton from "./components/exportButton";
import { PageProps } from "./Page.Container";
import { Formik, Form, FormikProps } from "formik";
import * as yup from "yup";
import { validationSchemaMinMaxFlour } from "./tabMinMaxConstraints/TabMinMaxConstraints";
import {
  validationSchemaIncomingWheat,
  validationSchemaReferenceWheat,
} from "./tabIncomingWheat/TabIncomingWheat";
import { validationSchemaForecastFlour } from "./tabSalesForecast/TabSalesForecast";
import { validationSchemaOpeningStockWheat } from "./tabOpeningStocks/TabOpeningStocks";
import { validationSchemaWheatSale } from "./tabWheatSales/TabWheatSales";
import { validationSchemaFinancial } from "./tabFinancials/TabFinancials";
import { validationSchemaWheatReplacementPrice } from "./tabWheatReplacementPrices/TabWheatReplacementPrices";
import styled, { css } from "styled-components";
import { getUser } from "utils/auth";

const validationSchema = yup.object().shape({
  minMaxFlours: yup.array(validationSchemaMinMaxFlour),
  incomingWheats: yup.array(validationSchemaIncomingWheat),
  referenceWheats: yup.array(validationSchemaReferenceWheat),
  forecastFlours: yup.array(validationSchemaForecastFlour),
  openingStockWheats: yup.array(validationSchemaOpeningStockWheat),
  wheatSales: yup.array(validationSchemaWheatSale),
  financial: validationSchemaFinancial,
  wheatReplacementPrices: yup.array(validationSchemaWheatReplacementPrice),
  month: yup.number().required(),
});

interface PageState {
  activeTab: number;
}

enum TabIndex {
  TAB_MIN_MAX_CONSTRAINTS = 0,
  TAB_INCOMING_WHEAT = 1,
  TAB_SALES_FORECAST = 2,
  TAB_OPENING_STOCKS = 3,
  TAB_WHEAT_SALES = 4,
  TAB_FINANCIALS = 5,
  TAB_WHEAT_REPLACEMENT_PRICES = 6,
}

export default class Page extends Component<PageProps, PageState> {
  state: PageState = {
    activeTab: TabIndex.TAB_MIN_MAX_CONSTRAINTS,
  };

  handleTabChange = (event: ChangeEvent<{}>, activeTab: number) => {
    this.setState({ activeTab });
  };

  handleSubmit = (values: PageProps["initialValues"]) => {
    this.props.importMinMaxConstraints(values.minMaxFlours);
    this.props.importIncomingWheats(values.incomingWheats);
    this.props.importSalesForecasts(values.forecastFlours);
    this.props.importOpeningStocks(values.openingStockWheats);
    this.props.importWheatSales(values.wheatSales);
    this.props.importFinancial(values.financial);
    this.props.importWheatReplacementPrices(values.wheatReplacementPrices);
    this.props.importMonth(values.month);
    this.props.updateRecipeFileName("");
    this.props.history.push("/recipe");
  };

  handleBackButtonClick = () => {
    this.props.history.push("/master");
  };

  renderMonthInput() {
    return (
      <Box paddingX={3}>
        <Select
          name="month"
          label="Months"
          options={[
            { label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
            { label: "6", value: "6" },
            { label: "7", value: "7" },
            { label: "8", value: "8" },
            { label: "9", value: "9" },
            { label: "10", value: "10" },
          ]}
        />
      </Box>
    );
  }

  renderPageHeader = (form: FormikProps<PageProps["initialValues"]>) => {
    return (
      <Box marginBottom={2}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h4">SWIMOF</Typography>
          </Grid>
          <Grid item>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button onClick={this.handleBackButtonClick}>Back</Button>
                </Grid>
                <Grid item>
                  <ImportButton form={form} />
                </Grid>
                <Grid item>
                  <ExportButton form={form} />
                </Grid>
                <Grid item>{this.renderMonthInput()}</Grid>
                <Grid item>
                  <CalculateButton form={form} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  isTabError = (
    name: keyof PageProps["initialValues"],
    form: FormikProps<PageProps["initialValues"]>
  ) => {
    return form.errors[name] !== undefined;
  };

  isAdmin = () => {
    const role = getUser().role;
    return role === "developer" || role === "admin";
  };

  renderTabs = (form: FormikProps<PageProps["initialValues"]>) => {
    const { activeTab } = this.state;
    return (
      <Tabs
        value={activeTab}
        onChange={this.handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label="min max constraints"
          isError={this.isTabError("minMaxFlours", form)}
        />
        <Tab
          label="incoming wheat"
          isError={this.isTabError("incomingWheats", form)}
        />
        <Tab
          label="sales forecast"
          isError={this.isTabError("forecastFlours", form)}
        />
        <Tab
          label="opening stocks"
          isError={this.isTabError("openingStockWheats", form)}
        />
        <Tab
          label="wheat sales"
          isError={this.isTabError("wheatSales", form)}
        />

        {this.isAdmin() && (
          <Tab
            label="financials"
            isError={this.isTabError("financial", form)}
          />
        )}

        <Tab
          label="wheat replacement prices"
          isError={this.isTabError("wheatReplacementPrices", form)}
        />
      </Tabs>
    );
  };

  renderTabPanels = () => {
    const { activeTab } = this.state;
    const { flourSpecs, referenceWheats } = this.props;
    return (
      <Box marginTop={2}>
        <TabPanel
          index={TabIndex.TAB_MIN_MAX_CONSTRAINTS}
          activeTab={activeTab}
        >
          <TabMinMaxConstraints
            flourSpecUsages={flourSpecs.map((flourSpec) => flourSpec.usage)}
          />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_INCOMING_WHEAT} activeTab={activeTab}>
          <TabIncomingWheat referenceWheats={referenceWheats} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_SALES_FORECAST} activeTab={activeTab}>
          <TabSalesForecast />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_OPENING_STOCKS} activeTab={activeTab}>
          <TabOpeningStocks />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_WHEAT_SALES} activeTab={activeTab}>
          <TabWheatSales />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_FINANCIALS} activeTab={activeTab}>
          <TabFinancials />
        </TabPanel>

        <TabPanel
          index={TabIndex.TAB_WHEAT_REPLACEMENT_PRICES}
          activeTab={activeTab}
        >
          <TabWheatReplacementPrices />
        </TabPanel>
      </Box>
    );
  };

  render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnMount
        onSubmit={this.handleSubmit}
      >
        {(form) => (
          <Form>
            <Container maxWidth="xl">
              {this.renderPageHeader(form)}
              {this.renderTabs(form)}
              {this.renderTabPanels()}
            </Container>
          </Form>
        )}
      </Formik>
    );
  }
}

const Tab = styled(MaterialTab)<{ isError?: boolean }>`
  ${(props) =>
    props.isError &&
    css`
      color: ${colors.red[500]} !important;
    `}
`;
