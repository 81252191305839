import { Reducer, combineReducers } from 'redux'
import { SheetForegoneCostActionTypes } from './types'
import { SheetForegoneCosts } from 'interfaces/wup/sheetForegoneCosts'

const initialState: SheetForegoneCosts = {
  foregoneCosts: []
}

const foregoneCosts: Reducer<SheetForegoneCosts['foregoneCosts']> = (
  state = initialState.foregoneCosts,
  { type, payload }
) => {
  switch (type) {
    case SheetForegoneCostActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetForegoneCostsReducer = combineReducers<SheetForegoneCosts>({
  foregoneCosts
})
