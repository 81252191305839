import React, { Component } from 'react'
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody as MaterialTableBody,
  colors
} from '@material-ui/core'

import styled, { css } from 'styled-components'
import { WupDate, Wheat } from 'interfaces/wup/sheetWup'
import { getUSValue } from 'utils/number'

interface TableWupDatesProps {
  wupDates: WupDate[]
}

export default class TableWupDates extends Component<TableWupDatesProps> {
  isNegative = (value: number) => {
    return value < 0
  }

  getTotal = (wheats: Wheat[], key: Exclude<keyof Wheat, 'name'>) => {
    return getUSValue(
      wheats.map(wheat => wheat[key]).reduce((prev, next) => prev + next)
    )
  }

  renderCell = (value: any) => {
    return <TableCell isNegative={parseInt(value) < 0}>{value}</TableCell>
  }

  renderTables = () => {
    return this.props.wupDates.map((wupDate, index) => (
      <TableContainer key={index}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">{wupDate.date}</TableCell>
              <TableCell>Opening (FH)</TableCell>
              <TableCell>Receiving (FH)</TableCell>
              <TableCell>Usage (FH)</TableCell>
              <TableCell>Sales (FH)</TableCell>
              <TableCell>Opening (LH)</TableCell>
              <TableCell>Receiving (LH)</TableCell>
              <TableCell>Usage (LH)</TableCell>
              <TableCell>Sales (LH)</TableCell>
              <TableCell>Total Usage</TableCell>
              <TableCell>Closing (LH)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wupDate.wheats.map((wheat, index) => (
              <TableRow key={index}>
                <TableCell variant="head">{wheat.name}</TableCell>
                {this.renderCell(getUSValue(wheat.openingFH))}
                {this.renderCell(getUSValue(wheat.receivingFH))}
                {this.renderCell(getUSValue(wheat.usageFH))}
                {this.renderCell(getUSValue(wheat.salesFH))}
                {this.renderCell(getUSValue(wheat.openingLH))}
                {this.renderCell(getUSValue(wheat.receivingLH))}
                {this.renderCell(getUSValue(wheat.usageLH))}
                {this.renderCell(getUSValue(wheat.salesLH))}
                {this.renderCell(getUSValue(wheat.totalUsage))}
                {this.renderCell(getUSValue(wheat.closingLH))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell variant="head">Total</TableCell>
              {this.renderCell(this.getTotal(wupDate.wheats, 'openingFH'))}
              {this.renderCell(this.getTotal(wupDate.wheats, 'receivingFH'))}
              {this.renderCell(this.getTotal(wupDate.wheats, 'usageFH'))}
              {this.renderCell(this.getTotal(wupDate.wheats, 'salesFH'))}
              {this.renderCell(this.getTotal(wupDate.wheats, 'openingLH'))}
              {this.renderCell(this.getTotal(wupDate.wheats, 'receivingLH'))}
              {this.renderCell(this.getTotal(wupDate.wheats, 'usageLH'))}
              {this.renderCell(this.getTotal(wupDate.wheats, 'salesLH'))}
              {this.renderCell(this.getTotal(wupDate.wheats, 'totalUsage'))}
              {this.renderCell(this.getTotal(wupDate.wheats, 'closingLH'))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    ))
  }

  render() {
    return (
      <Box padding={3}>
        <Typography variant="h6">WUP Dates</Typography>
        {this.renderTables()}
      </Box>
    )
  }
}

const TableContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
  margin-bottom: 30;
`

const TableBody = styled(MaterialTableBody)`
  & tr:nth-child(even) {
    background-color: ${colors.grey[50]};
  }
`

interface TableCell {
  isNegative?: boolean
}

const TableCell = styled(MaterialTableCell)<TableCell>`
  white-space: nowrap;
  min-width: 200px;

  ${props =>
    props.isNegative &&
    css`
      background-color: ${colors.red[50]};
    `}
`
