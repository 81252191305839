import { Workbook } from 'exceljs'
import { MinMaxFlour } from 'interfaces/generic/minMaxConstraint'
import { IncomingWheat } from 'interfaces/swimof/sheetIncomingWheat'
import { ForecastFlour } from 'interfaces/swimof/sheetSalesForecast'
import { OpeningStockWheat } from 'interfaces/swimof/sheetOpeningStocks'
import { WheatSale } from 'interfaces/swimof/sheetWheatSales'
import { Financial } from 'interfaces/swimof/sheetFinancials'
import { WheatReplacementPrice } from 'interfaces/swimof/sheetWheatReplacementPrices'
import { saveAs } from 'file-saver'
import model from 'model-input2.json'

export class SwimofExporter {
  private workbook: Workbook
  private minMaxFlours: MinMaxFlour[]
  private incomingWheats: IncomingWheat[]
  private referenceWheats: string[]
  private forecastFlours: ForecastFlour[]
  private openingStockWheats: OpeningStockWheat[]
  private wheatSales: WheatSale[]
  private financial: Financial
  private wheatReplacementPrices: WheatReplacementPrice[]

  constructor(
    minMaxFlours: MinMaxFlour[],
    incomingWheats: IncomingWheat[],
    referenceWheats: string[],
    forecastFlours: ForecastFlour[],
    openingStockWheats: OpeningStockWheat[],
    wheatSales: WheatSale[],
    financial: Financial,
    wheatReplacementPrices: WheatReplacementPrice[]
  ) {
    this.workbook = new Workbook()
    this.minMaxFlours = minMaxFlours
    this.incomingWheats = incomingWheats
    this.referenceWheats = referenceWheats
    this.forecastFlours = forecastFlours
    this.openingStockWheats = openingStockWheats
    this.wheatSales = wheatSales
    this.financial = financial
    this.wheatReplacementPrices = wheatReplacementPrices

    this.applyModel()
    this.createMinMaxFlours()
    this.createIncomingWheats()
    this.createForecastFlours()
    this.createOpeningStockWheats()
    this.createWheatSales()
    this.createFinancial()
    this.createWheatReplacementPrices()
  }

  private applyModel = () => {
    // @ts-ignore
    this.workbook.model = {
      ...this.workbook.model,
      ...model
    }
  }

  private createMinMaxFlours = () => {
    this.create2dSheetRecords(
      'min_max_constraints',
      this.minMaxFlours,
      'minMaxWheats'
    )
  }

  private createIncomingWheats = () => {
    const worksheet = this.workbook.getWorksheet('incoming_wheat')
    this.referenceWheats.forEach(referenceWheat => {
      const values: any[] = []
      values.push(referenceWheat)
      values.push('?')
      values.push('?')
      values.push('?')
      values.push('?')
      values.push('?')
      worksheet.addRow(values)
    })

    worksheet.addRow(['FILL BELOW']).getCell(1).style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00000000' }
      },
      font: {
        bold: true,
        color: {
          argb: 'FFFFFFFF'
        }
      },
      alignment: {
        horizontal: 'center'
      }
    }

    this.incomingWheats.forEach(incomingWheats => {
      const values: any[] = [...Object.values(incomingWheats)]
      worksheet.addRow(values)
    })
  }

  private createForecastFlours = () => {
    this.create2dSheetRecords(
      'sales_forecast',
      this.forecastFlours,
      'forecastDates'
    )
  }

  private createOpeningStockWheats = () => {
    this.createSheetRecords('opening_stocks', this.openingStockWheats)
  }

  private createWheatSales = () => {
    this.create2dSheetRecords('wheat_sales', this.wheatSales, 'dateSales')
  }

  private createFinancial = () => {
    const worksheet = this.workbook.getWorksheet('financials')
    worksheet.getCell('B1').value = parseFloat(
      this.financial.exchangeRate.toString()
    )
    worksheet.getCell('B4').value = this.financial.packagingMaterials
    worksheet.getCell('B5').value = this.financial.flourAdditives
    worksheet.getCell('B6').value = this.financial.peopleCost
    worksheet.getCell('B7').value = this.financial.electricity
    worksheet.getCell('B8').value = this.financial.maintenance
    worksheet.getCell('B9').value = this.financial.othersAndFacilities
    worksheet.getCell('B10').value = this.financial.operatingSupplies
    worksheet.getCell('B11').value = this.financial.factoryDepreciation
    worksheet.getCell('B12').value = this.financial.hqDepreciation
    worksheet.getCell('B13').value = this.financial.vat
    worksheet.getCell('B14').value = this.financial.salesAndAdmin
    worksheet.getCell('B15').value = this.financial.logistics
    worksheet.getCell('B16').value = this.financial.amortization
    worksheet.getCell('B17').value = this.financial.financeCost
  }

  private createWheatReplacementPrices = () => {
    this.create2dSheetRecords(
      'wheat_replacement_prices',
      this.wheatReplacementPrices,
      'dateReplacementPrices'
    )
  }

  private create2dSheetRecords = (
    worksheetName: string,
    items: any[],
    field: string
  ) => {
    const worksheet = this.workbook.getWorksheet(worksheetName)
    worksheet.columns = [
      ...worksheet.columns,
      ...items[0][field].map((item: any) => ({
        header: item.name
      }))
    ]

    items.forEach(item => {
      const values: string[] = []
      values.push(item.name)
      item[field].forEach((item: any) => {
        values.push(item.value)
      })
      worksheet.addRow(values)
    })
  }

  private createSheetRecords = (worksheetName: string, items: any[]) => {
    const worksheet = this.workbook.getWorksheet(worksheetName)
    items.forEach(item => {
      const values: any[] = [...Object.values(item)]
      worksheet.addRow(values)
    })
  }

  download = async () => {
    const blobPart = await this.workbook.xlsx.writeBuffer()
    const blob = new Blob([blobPart])
    saveAs(blob, 'swimof.xlsx')
  }

  getBuffer = () => {
    return this.workbook.xlsx.writeBuffer()
  }
}
