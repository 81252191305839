import React, { FC, useState, useContext } from 'react'
import { Button, CircularProgress, Box } from '@material-ui/core'
import { CalculateButtonProps } from './CalculateButton.Container'
import axios from 'axios'
import {
  getWorkbook,
  WupImporter,
  RecipeExporter,
  MasterExporter,
  SwimofExporter
} from 'utils/excel'
import { messageContext } from 'components/message'

const CalculateButton: FC<CalculateButtonProps> = ({
  inStockWheatSpecs,
  expectedWheatSpecs,
  flourSpecs,
  date,
  plantSite,
  minMaxFlours,
  incomingWheats,
  referenceWheats,
  forecastFlours,
  openingStockWheats,
  wheatSales,
  financial,
  wheatReplacementPrices,
  masterFilename,
  swimofFilename,
  importConstraints,
  importIdeals,
  importWup,
  importFlourSpecs,
  importWheatCosts,
  importCostStructures,
  importEffectiveCalcs,
  importConstrainedRecipes,
  importIdealRecipes,
  importForegoneCosts,
  importMinMaxFlours,
  updateWupDownloadLink,
  form,
  history,
  month
}) => {
  const { handleOpen } = useContext(messageContext)
  const [isLoading, setLoading] = useState<boolean>(false)

  const importWupFile = (blob: Blob) => {
    getWorkbook(blob).then(workbook => {
      const wupImporter = new WupImporter(workbook)
      importWup(wupImporter.getWup())
      importFlourSpecs(wupImporter.getFlourSpecs())
      importWheatCosts(wupImporter.getWheatCosts())
      importCostStructures(wupImporter.getCostStructures())
      importEffectiveCalcs(wupImporter.getEffectiveCalcs())
      importConstrainedRecipes(wupImporter.getConstrainedRecipes())
      importIdealRecipes(wupImporter.getIdealRecipes())
      importForegoneCosts(wupImporter.getForegoneCosts())
      importMinMaxFlours(wupImporter.getMinMaxFlours())
    })
  }

  const downloadWupFile = (link: string) => {
    return axios.get(link, { responseType: 'blob' })
  }

  const uploadSheet = async () => {
    const { constraints, ideals } = form.values

    const blobPartMaster = await new MasterExporter(
      inStockWheatSpecs,
      expectedWheatSpecs,
      flourSpecs,
      date,
      plantSite
    ).getBuffer()
    const blobPartSwimof = await new SwimofExporter(
      minMaxFlours,
      incomingWheats,
      referenceWheats,
      forecastFlours,
      openingStockWheats,
      wheatSales,
      financial,
      wheatReplacementPrices
    ).getBuffer()
    const blobPartRecipe = await new RecipeExporter(
      constraints,
      ideals
    ).getBuffer()

    const blobMaster = new Blob([blobPartMaster])
    const blobSwimof = new Blob([blobPartSwimof])
    const blobRecipe = new Blob([blobPartRecipe])

    const formData = new FormData()
    formData.append('master', blobMaster, 'master.xlsx')
    formData.append('swimof', blobSwimof, 'swimof.xlsx')
    formData.append('recipe', blobRecipe, 'recipe.xlsx')
    formData.append('masterFilename', masterFilename)
    formData.append('swimofFilename', swimofFilename)
    formData.append('months', month.toString())

    return axios.post(
      '//cassava.cerestar.net/api/calculate-wup',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 100000
      }
    )
  }

  const handleClick = async () => {
    if (!isLoading && form.isValid) {
      try {
        importConstraints(form.values.constraints)
        importIdeals(form.values.ideals)

        setLoading(true)
        importConstraints(form.values.constraints)
        importIdeals(form.values.ideals)
        const uploadResponse = await uploadSheet()

        if (uploadResponse.data.error) {
          handleOpen(uploadResponse.data.message, uploadResponse.data.details)
        } else {
          const downloadLink = '//' + uploadResponse.data.wupLink
          const downloadResponse = await downloadWupFile(downloadLink)
          importWupFile(new Blob([downloadResponse.data]))
          updateWupDownloadLink(downloadLink)
          history.push('/wup')
        }
      } catch (err) {
        handleOpen('Something when wrong', err.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const renderLoading = () => {
    return (
      <Box marginLeft="15px" marginTop="5px">
        <CircularProgress color="inherit" size="20px" />
      </Box>
    )
  }

  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      Calculate WUP
      {isLoading && renderLoading()}
    </Button>
  )
}

export default CalculateButton
