import { Reducer, combineReducers } from 'redux'
import { SheetSalesForecastActionTypes } from './types'
import { SheetSalesForecast } from 'interfaces/swimof/sheetSalesForecast'

const initialState: SheetSalesForecast = {
  forecastFlours: []
}

const forecastFlours: Reducer<SheetSalesForecast['forecastFlours']> = (
  state = initialState.forecastFlours,
  { type, payload }
) => {
  switch (type) {
    case SheetSalesForecastActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetSalesForecastActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetSalesForecastReducer = combineReducers<SheetSalesForecast>({
  forecastFlours
})
