export interface SheetFlourSpecs {
  flours: FlourSpec[]
}

export interface FlourSpec {
  name: string
  productId: string
  usage: FlourUsage
  minProtein: number
  maxProtein: number
  minWetGluten: number
  maxWetGluten: number
  minWaterAbsorption: number
  maxWaterAbsorption: number
  minFallingNumber: number
  maxFallingNumber: number
  minSourceWater: number
  maxSourceWater: number
  minSourceSucrose: number
  maxSourceSucrose: number
  minSourceSodiumCarbonate: number
  maxSourceSodiumCarbonate: number
  minSourceLacticAcid: number
  maxSourceLacticAcid: number
}

export enum FlourUsage {
  BISCUIT = 'Biscuit',
  WAFER = 'Wafer',
  NOODLE = 'Noodle',
  UDON = 'Udon',
  BREAD = 'Bread',
  BUN = 'Bun',
  PAO = 'Pao',
  GENERAL_PURPOSE = 'General_Purpose',
  FIGHTING_BRAND = 'Fighting_Brand',
  FEED = 'Feed',
  OTHER = 'Other'
}
