export interface SheetExpectedWheatSpecs {
  wheats: ExpectedWheatSpec[]
}

export interface ExpectedWheatSpec {
  typeName: string
  origin: WheatOrigin
  testWeight: number
  screening: number
  moisture: number
  millingExtractionRate: number
  hardness: WheatHardness
  colorType: WheatColorType
  flourProtein: number
  flourWetGluten: number
  flourFallingNumber: number
  waterAbsorption: number
  doughDevelopmentTime: number
  stabilityTime: number
  water: number
  sucrose: number
  sodiumCarbonate: number
  lacticAcid: number
  spreadRatio: number
  maxGelatinisation: number
  targetMoisture: number
  millingInvisibleLoss: number
  byProductPrice: number
}

export enum WheatOrigin {
  USA = 'USA',
  CANADA = 'Canada',
  AUSTRALIA = 'Australia',
  BLACK_SEA = 'Black_Sea',
  ARGENTINA = 'Argentina',
  EUROPE = 'Europe',
  OTHER = 'Other'
}

export enum WheatHardness {
  SOFT = 'Soft',
  MEDIUM = 'Medium',
  HARD = 'Hard'
}

export enum WheatColorType {
  RED = 'Red',
  WHITE = 'White',
  OTHER = 'Other'
}
