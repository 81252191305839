import React, { Component, ChangeEvent } from 'react'
import {
  Container,
  Tabs,
  Tab,
  Box,
  Typography,
  Grid,
  Button
} from '@material-ui/core'
import TabPanel from 'components/TabPanel'
import TabWup from './tabWup'
import TabFlourSpecs from './tabFlourSpecs'
import TabWheatCost from './tabWheatCost'
import TabCostStructure from './tabCostStructure'
import TabEffectiveCalc from './tabEffectiveCalc'
import TabConstrainedRecipe from './tabConstrainedRecipe'
import TabIdealRecipe from './tabIdealRecipe'
import ExportButton from './components/exportButton'
import { PageProps } from './Page.Container'
import TabForegoneCosts from './tabForegoneCosts'
import TabMinMax from './tabMinMax'

interface PageState {
  activeTab: number
}

enum TabIndex {
  TAB_WUP = 0,
  TAB_FLOUR_SPECS = 1,
  TAB_WHEAT_COST = 2,
  TAB_COST_STRUCTURE = 3,
  TAB_EFFECTIVE_CALC = 4,
  TAB_CONSTRAINED = 5,
  TAB_IDEAL = 6,
  TAB_FOREGONE_COSTS = 7,
  TAB_MIN_MAX = 8
}

export default class Page extends Component<PageProps, PageState> {
  state: PageState = {
    activeTab: TabIndex.TAB_WUP
  }

  handleTabChange = (event: ChangeEvent<{}>, activeTab: number) => {
    this.setState({ activeTab })
  }

  handleBackButtonClick = () => {
    this.props.history.push('/recipe')
  }

  renderPageHeader = () => {
    return (
      <Box marginBottom={2}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h4">WUP</Typography>
          </Grid>
          <Grid item>
            <Button onClick={this.handleBackButtonClick}>Back</Button>
            &nbsp;
            <ExportButton />
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderTabs = () => {
    const { activeTab } = this.state
    return (
      <Tabs
        value={activeTab}
        onChange={this.handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="wup" />
        <Tab label="flour specs" />
        <Tab label="wheat cost" />
        <Tab label="cost structure" />
        <Tab label="effective calc" />
        <Tab label="constrained recipe" />
        <Tab label="ideal recipe" />
        <Tab label="foregone costs" />
        <Tab label="min max" />
      </Tabs>
    )
  }

  renderTabPanels = () => {
    const { activeTab } = this.state
    const {
      wup,
      flourSpecs,
      wheatCosts,
      costStructures,
      effectiveCalcs,
      constraints,
      ideals,
      foregoneCosts,
      minMaxFlours
    } = this.props.initialValues
    return (
      <Box marginTop={2}>
        <TabPanel index={TabIndex.TAB_WUP} activeTab={activeTab}>
          <TabWup wup={wup} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_FLOUR_SPECS} activeTab={activeTab}>
          <TabFlourSpecs flourSpecs={flourSpecs} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_WHEAT_COST} activeTab={activeTab}>
          <TabWheatCost wheatCosts={wheatCosts} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_COST_STRUCTURE} activeTab={activeTab}>
          <TabCostStructure costStructures={costStructures} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_EFFECTIVE_CALC} activeTab={activeTab}>
          <TabEffectiveCalc effectiveCalcs={effectiveCalcs} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_CONSTRAINED} activeTab={activeTab}>
          <TabConstrainedRecipe constraints={constraints} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_IDEAL} activeTab={activeTab}>
          <TabIdealRecipe ideals={ideals} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_FOREGONE_COSTS} activeTab={activeTab}>
          <TabForegoneCosts foregoneCosts={foregoneCosts} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_MIN_MAX} activeTab={activeTab}>
          <TabMinMax minMaxFlours={minMaxFlours} />
        </TabPanel>
      </Box>
    )
  }

  render() {
    return (
      <Container maxWidth="xl">
        {this.renderPageHeader()}
        {this.renderTabs()}
        {this.renderTabPanels()}
      </Container>
    )
  }
}
