export const getDateHalf = (date: string) => {
  return date.split('-')[0]
}

export const getMonth = (date: string) => {
  return date.split('-')[1]
}

export const getYear = (date: string) => {
  return date.split('-')[2]
}

export const monthFormat = 'MMM'
export const yearFormat = 'YYYY'
