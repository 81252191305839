import { Reducer } from 'redux'
import { SwimofFileNameActionTypes } from './types'

const initialState = ''

export const fileNameReducer: Reducer<string> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SwimofFileNameActionTypes.UPDATE: {
      return payload
    }
    default: {
      return state
    }
  }
}
