import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { ExportButtonProps } from './ExportButton.Container'
import { MasterExporter } from 'utils/excel'

const ExportButton: FC<ExportButtonProps> = ({
  importInStockWheatSpecs,
  importExpectedWheatSpecs,
  importFlourSpecs,
  importDate,
  importPlantSite,
  form
}) => {
  const handleClick = () => {
    form.validateForm().then(() => {
      if (form.isValid) {
        const {
          inStockWheatSpecs,
          expectedWheatSpecs,
          flourSpecs,
          date,
          plantSite
        } = form.values

        importInStockWheatSpecs(inStockWheatSpecs)
        importExpectedWheatSpecs(expectedWheatSpecs)
        importFlourSpecs(flourSpecs)
        importDate(date)
        importPlantSite(plantSite)

        new MasterExporter(
          inStockWheatSpecs,
          expectedWheatSpecs,
          flourSpecs,
          date,
          plantSite
        ).download()
      }
    })
  }

  return (
    <Button variant="outlined" color="primary" onClick={handleClick}>
      Export
    </Button>
  )
}

export default ExportButton
