import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody as MaterialTableBody,
  colors
} from '@material-ui/core'
import styled, { css } from 'styled-components'
import { WheatCost } from 'interfaces/wup/sheetWheatCost'
import { getUSValue } from 'utils/number'

interface TabWheatCostProps {
  wheatCosts: WheatCost[]
}

export default class TabWheatCost extends Component<TabWheatCostProps> {
  renderData = () => {
    const { wheatCosts } = this.props
    return (
      wheatCosts.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Wheat Cost</TableCell>
                {wheatCosts[0].wheatDates.map((wheatDate, index) => (
                  <TableCell key={index}>{wheatDate.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {wheatCosts.map((wheatCost, indexWheat) => (
                <TableRow key={indexWheat}>
                  <TableCell variant="head">{wheatCost.name}</TableCell>
                  {wheatCosts[0].wheatDates.map((wheatDate, indexDate) => (
                    <TableCell
                      key={indexDate}
                      isNegative={parseInt(wheatDate.value) < 0}
                    >
                      {getUSValue(wheatDate.value)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    )
  }

  render() {
    return (
      <Paper>
        <Box padding={3}>
          <Typography variant="h6">Wheat Cost</Typography>
          {this.renderData()}
        </Box>
      </Paper>
    )
  }
}

const TableContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
  margin-bottom: 30;
`

const TableBody = styled(MaterialTableBody)`
  & tr:nth-child(even) {
    background-color: ${colors.grey[50]};
  }
`

interface TableCell {
  isNegative?: boolean
}

const TableCell = styled(MaterialTableCell)<TableCell>`
  white-space: nowrap;
  min-width: 200px;

  ${props =>
    props.isNegative &&
    css`
      background-color: ${colors.red[50]};
    `}
`
