import React, { FC } from 'react'
import { connect } from 'react-redux'
import Page from './Page'
import { RootStore } from 'interfaces/stores'
import { Wup } from 'interfaces/wup/sheetWup'
import { FlourSpec } from 'interfaces/wup/sheetFlourSpecs'
import { WheatCost } from 'interfaces/wup/sheetWheatCost'
import { CostStructure } from 'interfaces/wup/sheetCostStructure'
import { EffectiveCalc } from 'interfaces/wup/sheetEffectiveCalc'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Recipe } from 'interfaces/generic/recipe'
import { ForegoneCost } from 'interfaces/wup/sheetForegoneCosts'
import { MinMaxFlour } from 'interfaces/generic/minMaxConstraint'

interface PropsFromState {
  initialValues: {
    wup: Wup
    flourSpecs: FlourSpec[]
    wheatCosts: WheatCost[]
    costStructures: CostStructure[]
    effectiveCalcs: EffectiveCalc[]
    constraints: Recipe[]
    ideals: Recipe[]
    foregoneCosts: ForegoneCost[]
    minMaxFlours: MinMaxFlour[]
  }
}

export type PageProps = PropsFromState & RouteComponentProps

const PageContainer: FC<PageProps> = props => <Page {...props} />

const mapStateToProps = ({
  wup: {
    sheetWup,
    sheetFlourSpecs,
    sheetWheatCost,
    sheetCostStructure,
    sheetEffectiveCalc,
    sheetConstrainedRecipe,
    sheetIdealRecipe,
    sheetForegoneCosts,
    sheetMinMax
  }
}: RootStore): PropsFromState => ({
  initialValues: {
    wup: sheetWup.wup,
    flourSpecs: sheetFlourSpecs.flourSpecs,
    wheatCosts: sheetWheatCost.wheatCosts,
    costStructures: sheetCostStructure.costStructures,
    effectiveCalcs: sheetEffectiveCalc.effectiveCalcs,
    constraints: sheetConstrainedRecipe.constaints,
    ideals: sheetIdealRecipe.ideals,
    foregoneCosts: sheetForegoneCosts.foregoneCosts,
    minMaxFlours: sheetMinMax.minMaxFlours
  }
})

export default withRouter(connect(mapStateToProps)(PageContainer))
