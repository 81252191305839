import Excel from 'exceljs'
import { WupDate, Wheat, Wup } from 'interfaces/wup/sheetWup'
import { FlourSpec, Flour } from 'interfaces/wup/sheetFlourSpecs'
import { WheatCost } from 'interfaces/wup/sheetWheatCost'
import { CostStructure, FlourCost } from 'interfaces/wup/sheetCostStructure'
import { EffectiveCalc } from 'interfaces/wup/sheetEffectiveCalc'
import { Recipe, RecipeFlour } from 'interfaces/generic/recipe'
import { ForegoneCost } from 'interfaces/wup/sheetForegoneCosts'
import { MinMaxFlour } from 'interfaces/generic/minMaxConstraint'

export class WupImporter {
  private workbook = new Excel.Workbook()

  constructor(workbook: Excel.Workbook) {
    this.workbook = workbook
  }

  getWup = (): Wup => {
    const worksheet = this.workbook.getWorksheet('wup')
    const noDokumen = worksheet.getCell('B1').value as string
    const plantSite = worksheet.getCell('E1').value as string
    const noRevisi = worksheet.getCell('B2').value as string
    const tanggalBerlaku = worksheet.getCell('E2').value as string

    const master = worksheet.getRow(5).values as string[]
    const swimof = worksheet.getRow(6).values as string[]

    const wupDates: WupDate[] = []
    const tableLocations: number[] = []

    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 7) {
        const rowValues = row.values as any[]
        if (rowValues.length && isNaN(rowValues[2]))
          tableLocations.push(rowNumber)
      }
    })

    tableLocations.forEach((tableLocation, index) => {
      const firstRow = worksheet.getRow(tableLocation).values as string[]
      const wheats: Wheat[] = []

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = row.values as any[]
        const nextTableLocation = tableLocations[index + 1]
          ? tableLocations[index + 1]
          : worksheet.rowCount

        const name =
          typeof rowValues[1] === 'object' ? rowValues[1].result : rowValues[1]

        if (
          rowNumber > tableLocation &&
          rowNumber < nextTableLocation &&
          name !== 'Total'
        ) {
          wheats.push({
            name,
            openingFH:
              typeof rowValues[2] === 'object'
                ? rowValues[2].result
                : rowValues[2],
            receivingFH:
              typeof rowValues[3] === 'object'
                ? rowValues[3].result
                : rowValues[3],
            usageFH:
              typeof rowValues[4] === 'object'
                ? rowValues[4].result
                : rowValues[4],
            salesFH:
              typeof rowValues[5] === 'object'
                ? rowValues[5].result
                : rowValues[5],
            openingLH:
              typeof rowValues[6] === 'object'
                ? rowValues[6].result
                : rowValues[6],
            receivingLH:
              typeof rowValues[7] === 'object'
                ? rowValues[7].result
                : rowValues[7],
            usageLH:
              typeof rowValues[8] === 'object'
                ? rowValues[8].result
                : rowValues[8],
            salesLH:
              typeof rowValues[9] === 'object'
                ? rowValues[9].result
                : rowValues[9],
            totalUsage:
              typeof rowValues[10] === 'object'
                ? rowValues[10].result
                : rowValues[10],
            closingLH:
              typeof rowValues[11] === 'object'
                ? rowValues[11].result
                : rowValues[11],
          })
        }
      })

      wupDates.push({
        date: firstRow[1],
        wheats,
      })
    })

    return {
      noDokumen,
      plantSite,
      noRevisi,
      tanggalBerlaku,
      changelog: {
        master: {
          fileName: master[2],
          uploadTime: master[3],
        },
        swimof: {
          fileName: swimof[2],
          uploadTime: swimof[3],
        },
      },
      wupDates,
    }
  }

  getFlourSpecs = () => {
    const worksheet = this.workbook.getWorksheet('flour_specs')
    const data: FlourSpec[] = []
    const tableLocations: number[] = []

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowValues.length && rowValues[2] === 'dough_development_time')
        tableLocations.push(rowNumber)
    })

    tableLocations.forEach((tableLocation, index) => {
      const firstRow = worksheet.getRow(tableLocation).values as string[]
      const flours: Flour[] = []

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = row.values as any[]
        const nextTableLocation = tableLocations[index + 1]
          ? tableLocations[index + 1]
          : worksheet.rowCount

        if (rowNumber > tableLocation && rowNumber < nextTableLocation) {
          flours.push({
            name:
              typeof rowValues[1] === 'object'
                ? rowValues[1].result
                : rowValues[1],
            doughDevelopmentTime:
              typeof rowValues[2] === 'object'
                ? rowValues[2].result
                : rowValues[2],
            fallingNumber:
              typeof rowValues[3] === 'object'
                ? rowValues[3].result
                : rowValues[3],
            maxGelatinisation:
              typeof rowValues[4] === 'object'
                ? rowValues[4].result
                : rowValues[4],
            moisture:
              typeof rowValues[5] === 'object'
                ? rowValues[5].result
                : rowValues[5],
            protein:
              typeof rowValues[6] === 'object'
                ? rowValues[6].result
                : rowValues[6],
            sourceLacticAcid:
              typeof rowValues[7] === 'object'
                ? rowValues[7].result
                : rowValues[7],
            sourceSodiumCarbonate:
              typeof rowValues[8] === 'object'
                ? rowValues[8].result
                : rowValues[8],
            sourceSucrose:
              typeof rowValues[9] === 'object'
                ? rowValues[9].result
                : rowValues[9],
            sourceWater:
              typeof rowValues[10] === 'object'
                ? rowValues[10].result
                : rowValues[10],
            stabilityTime:
              typeof rowValues[11] === 'object'
                ? rowValues[11].result
                : rowValues[11],
            waterAbsorption:
              typeof rowValues[12] === 'object'
                ? rowValues[12].result
                : rowValues[12],
            wetGluten:
              typeof rowValues[13] === 'object'
                ? rowValues[13].result
                : rowValues[13],
          })
        }
      })

      data.push({
        date: firstRow[1],
        flours,
      })
    })

    return data
  }

  getWheatCosts = () => {
    const worksheet = this.workbook.getWorksheet('wheat_cost')
    const data: WheatCost[] = []
    const firstRow = worksheet.getRow(1).values as string[]

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowNumber > 1) {
        data.push({
          name: rowValues[1],
          wheatDates: rowValues
            .slice(2, rowValues.length)
            .map((value, index) => ({
              name: firstRow[index + 2] as string,
              value: typeof value === 'object' ? value.result : value,
            })),
        })
      }
    })
    return data
  }

  getCostStructures = () => {
    const worksheet = this.workbook.getWorksheet('cost_structure')
    const data: CostStructure[] = []
    const tableLocations: number[] = []

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowValues.length && isNaN(rowValues[2]))
        tableLocations.push(rowNumber)
    })

    tableLocations.forEach((tableLocation, index) => {
      const firstRow = worksheet.getRow(tableLocation).values as string[]
      const flourCosts: FlourCost[] = []

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = row.values as any[]
        const nextTableLocation = tableLocations[index + 1]
          ? tableLocations[index + 1]
          : worksheet.rowCount

        if (rowNumber > tableLocation && rowNumber < nextTableLocation) {
          flourCosts.push({
            name:
              typeof rowValues[1] === 'object'
                ? rowValues[1].result
                : rowValues[1],
            fullCost:
              typeof rowValues[2] === 'object'
                ? rowValues[2].result
                : rowValues[2],
            fullCostVat:
              typeof rowValues[3] === 'object'
                ? rowValues[3].result
                : rowValues[3],
            gpCost:
              typeof rowValues[4] === 'object'
                ? rowValues[4].result
                : rowValues[4],
          })
        }
      })

      data.push({
        date: firstRow[1],
        flourCosts,
      })
    })

    return data
  }

  getEffectiveCalcs = () => {
    const worksheet = this.workbook.getWorksheet('effective_calc')
    const effectiveCalcs: EffectiveCalc[] = []

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowNumber > 1) {
        effectiveCalcs.push({
          name:
            typeof rowValues[1] === 'object'
              ? rowValues[1].result
              : rowValues[1],
          effectivePrice:
            typeof rowValues[2] === 'object'
              ? rowValues[2].result
              : rowValues[2],
          effectiveStock:
            typeof rowValues[3] === 'object'
              ? rowValues[3].result
              : rowValues[3],
        })
      }
    })
    return effectiveCalcs
  }

  getConstrainedRecipes = () => {
    return this.getRecipes('constrained_recipe')
  }

  getIdealRecipes = () => {
    return this.getRecipes('ideal_recipe')
  }

  private isTableHeader = (rowValues: any[]) => {
    return typeof rowValues[2] === 'string'
  }

  private getTableLocations = (worksheet: Excel.Worksheet) => {
    const tableLocations: number[] = []
    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (this.isTableHeader(rowValues)) tableLocations.push(rowNumber)
    })
    return tableLocations
  }

  private getNextTableLocation = (
    index: number,
    worksheet: Excel.Worksheet
  ) => {
    const tableLocations = this.getTableLocations(worksheet)
    return tableLocations[index + 1]
      ? tableLocations[index + 1]
      : worksheet.rowCount + 1
  }

  private getFilledValues = (values: string[]) => {
    const filledValues: string[] = []

    for (let i = 0; i < values.length; i++) {
      const value = values[i]
      filledValues.push(value ? value : '0')
    }

    return filledValues
  }

  private getProtein = (rowValues: string[]) => {
    return rowValues[rowValues.length - 3]
  }

  private getWetGluten = (rowValues: string[]) => {
    return rowValues[rowValues.length - 2]
  }

  private getWaterAbsorption = (rowValues: string[]) => {
    return rowValues[rowValues.length - 1]
  }

  private getRecipes = (worksheetName: string) => {
    const worksheet = this.workbook.getWorksheet(worksheetName)
    const recipes: Recipe[] = []
    const tableLocations = this.getTableLocations(worksheet)

    tableLocations.forEach((tableLocation, index) => {
      const firstRow = worksheet.getRow(tableLocation).values as string[]
      const recipeFlours: RecipeFlour[] = []

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = this.getFilledValues(row.values as string[])
        const protein = this.getProtein(rowValues)
        const wetGluten = this.getWetGluten(rowValues)
        const waterAbsorption = this.getWaterAbsorption(rowValues)

        if (
          rowNumber > tableLocation &&
          rowNumber < this.getNextTableLocation(index, worksheet)
        ) {
          recipeFlours.push({
            name: rowValues[1],
            protein,
            wetGluten,
            waterAbsorption,
            recipeWheats: rowValues
              .slice(2, rowValues.length - 3)
              .map((value, index) => ({
                name: firstRow[index + 2] as string,
                value: parseInt(value),
              })),
          })
        }
      })

      recipes.push({
        date: firstRow[1],
        recipeFlours,
      })
    })

    return recipes
  }

  getForegoneCosts = () => {
    const worksheet = this.workbook.getWorksheet('foregone_costs')
    const foregoneCosts: ForegoneCost[] = []

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowNumber > 1) {
        foregoneCosts.push({
          name:
            typeof rowValues[1] === 'object'
              ? rowValues[1].result
              : rowValues[1],
          withoutMinMaxCost:
            typeof rowValues[2] === 'object'
              ? rowValues[2].result
              : rowValues[2],
          finalCost:
            typeof rowValues[3] === 'object'
              ? rowValues[3].result
              : rowValues[3],
          foregoneCost:
            typeof rowValues[4] === 'object'
              ? rowValues[4].result
              : rowValues[4],
          totalQuantity:
            typeof rowValues[5] === 'object'
              ? rowValues[5].result
              : rowValues[5],
          foregoneCostPerMT:
            typeof rowValues[6] === 'object'
              ? rowValues[6].result
              : rowValues[6],
        })
      }
    })
    return foregoneCosts
  }

  getMinMaxFlours = () => {
    const worksheet = this.workbook.getWorksheet('min_max')
    const data: MinMaxFlour[] = []
    const firstRow = worksheet.getRow(1).values as string[]

    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (rowNumber > 1) {
        data.push({
          name: rowValues[1],
          minMaxWheats: rowValues
            .slice(2, rowValues.length)
            .map((value, index) => ({
              name: firstRow[index + 2] as string,
              value: typeof value === 'object' ? value.result : value,
            })),
        })
      }
    })
    return data
  }
}
