import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody as MaterialTableBody,
  colors
} from '@material-ui/core'
import styled from 'styled-components'
import { FlourSpec } from 'interfaces/wup/sheetFlourSpecs'

interface TabFlourSpecsProps {
  flourSpecs: FlourSpec[]
}

export default class TabFlourSpecs extends Component<TabFlourSpecsProps> {
  renderTables = () => {
    return this.props.flourSpecs.map((flourSpec, index) => (
      <TableContainer key={index}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">{flourSpec.date}</TableCell>
              <TableCell>Dough Development Time</TableCell>
              <TableCell>Falling Number</TableCell>
              <TableCell>Max Gelatinisation</TableCell>
              <TableCell>Moisture</TableCell>
              <TableCell>Protein</TableCell>
              <TableCell>Source Lactic Acid</TableCell>
              <TableCell>Source Sodium Carbonate</TableCell>
              <TableCell>Source Sucrose</TableCell>
              <TableCell>Source Water</TableCell>
              <TableCell>Stability Time</TableCell>
              <TableCell>Water Absorption</TableCell>
              <TableCell>Wet Gluten</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {flourSpec.flours.map((flour, index) => (
              <TableRow key={index}>
                <TableCell variant="head">{flour.name}</TableCell>
                <TableCell>{flour.doughDevelopmentTime}</TableCell>
                <TableCell>{flour.fallingNumber}</TableCell>
                <TableCell>{flour.maxGelatinisation}</TableCell>
                <TableCell>{flour.moisture}</TableCell>
                <TableCell>{flour.protein}</TableCell>
                <TableCell>{flour.sourceLacticAcid}</TableCell>
                <TableCell>{flour.sourceSodiumCarbonate}</TableCell>
                <TableCell>{flour.sourceSucrose}</TableCell>
                <TableCell>{flour.sourceWater}</TableCell>
                <TableCell>{flour.stabilityTime}</TableCell>
                <TableCell>{flour.waterAbsorption}</TableCell>
                <TableCell>{flour.wetGluten}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ))
  }

  render() {
    return (
      <Paper>
        <Box padding={3}>
          <Typography variant="h6">Flour Specs</Typography>
          {this.renderTables()}
        </Box>
      </Paper>
    )
  }
}

const TableContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
  margin-bottom: 30;
`

const TableBody = styled(MaterialTableBody)`
  & tr:nth-child(even) {
    background-color: ${colors.grey[50]};
  }
`

const TableCell = styled(MaterialTableCell)`
  white-space: nowrap;
  min-width: 200px;
`
