import { Reducer, combineReducers } from 'redux'
import { SheetFinancialsActionTypes } from './types'
import { SheetFinancials } from 'interfaces/swimof/sheetFinancials'

const initialState: SheetFinancials = {
  financial: {
    exchangeRate: 0,
    packagingMaterials: 0,
    flourAdditives: 0,
    peopleCost: 0,
    electricity: 0,
    maintenance: 0,
    othersAndFacilities: 0,
    operatingSupplies: 0,
    factoryDepreciation: 0,
    hqDepreciation: 0,
    vat: 0,
    salesAndAdmin: 0,
    logistics: 0,
    amortization: 0,
    financeCost: 0
  }
}

const financial: Reducer<SheetFinancials['financial']> = (
  state = initialState.financial,
  { type, payload }
) => {
  switch (type) {
    case SheetFinancialsActionTypes.UPDATE: {
      return payload
    }
    default: {
      return state
    }
  }
}

export const sheetFinancialsReducer = combineReducers<SheetFinancials>({
  financial
})
