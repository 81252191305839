import { combineReducers } from 'redux'
import Wup from 'interfaces/wup'
import { downloadLinkReducer } from './downloadLink/reducers'
import { sheetWupReducer } from './sheetWup/reducers'
import { sheetFlourSpecsReducer } from './sheetFlourSpecs/reducers'
import { sheetWheatCostReducer } from './sheetWheatCost/reducers'
import { sheetCostStructureReducer } from './sheetCostStructure/reducers'
import { sheetEffectiveCalcReducer } from './sheetEffectiveCalc/reducers'
import { sheetConstrainedRecipeReducer } from './sheetConstrainedRecipe/reducers'
import { sheetIdealRecipeReducer } from './sheetIdealRecipe/reducers'
import { sheetForegoneCostsReducer } from './sheetForegoneCosts/reducers'
import { sheetMinMaxReducer } from './sheetMinMax/reducers'

export const wupReducers = combineReducers<Wup>({
  downloadLink: downloadLinkReducer,
  sheetWup: sheetWupReducer,
  sheetFlourSpecs: sheetFlourSpecsReducer,
  sheetWheatCost: sheetWheatCostReducer,
  sheetCostStructure: sheetCostStructureReducer,
  sheetEffectiveCalc: sheetEffectiveCalcReducer,
  sheetConstrainedRecipe: sheetConstrainedRecipeReducer,
  sheetIdealRecipe: sheetIdealRecipeReducer,
  sheetForegoneCosts: sheetForegoneCostsReducer,
  sheetMinMax: sheetMinMaxReducer
})
