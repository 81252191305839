import React, { Component } from 'react'
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Changelog } from 'interfaces/wup/sheetWup'

interface TableChangelogProps {
  changelog: Changelog
}

export default class TableChangelog extends Component<TableChangelogProps> {
  renderTableCells = (changelogType: keyof Changelog) => {
    const { changelog } = this.props
    return (
      <>
        <TableCell>{changelog[changelogType].fileName}</TableCell>
        <TableCell>
          {changelog[changelogType].uploadTime.replace(/"/g, '')}
        </TableCell>
      </>
    )
  }

  renderTable = () => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Changelog</TableCell>
              <TableCell variant="head">File Name</TableCell>
              <TableCell variant="head">Upload Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Master</TableCell>
              {this.renderTableCells('master')}
            </TableRow>
            <TableRow>
              <TableCell variant="head">Swimof</TableCell>
              {this.renderTableCells('swimof')}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  render() {
    return (
      <Box padding={3}>
        <Typography variant="h6">Changelog</Typography>
        {this.renderTable()}
      </Box>
    )
  }
}

const TableContainer = styled('div')({
  maxWidth: '100%',
  overflow: 'auto'
})

const TableCell = styled(MaterialTableCell)({
  whiteSpace: 'nowrap',
  minWidth: '200px'
})
