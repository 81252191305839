import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody as MaterialTableBody,
  colors
} from '@material-ui/core'

import styled, { css } from 'styled-components'
import { getUSValue } from 'utils/number'
import { Recipe, RecipeFlour } from 'interfaces/generic/recipe'

interface TabConstrainedRecipeProps {
  constraints: Recipe[]
}

export default class TabConstrainedRecipe extends Component<
  TabConstrainedRecipeProps
> {
  renderWheatNames = (recipe: Recipe) => {
    return (
      recipe.recipeFlours.length > 0 &&
      recipe.recipeFlours[0].recipeWheats.map((recipeWheat, index) => (
        <TableCell variant="head" key={index}>
          {recipeWheat.name}
        </TableCell>
      ))
    )
  }

  renderWheatValues = (recipeFlour: RecipeFlour) => {
    return recipeFlour.recipeWheats.map((recipeWheat, index) => (
      <TableCell key={index} isNegative={recipeWheat.value < 0}>
        {getUSValue(recipeWheat.value)}
      </TableCell>
    ))
  }

  renderTables = () => {
    return this.props.constraints.map((recipe, index) => (
      <TableContainer key={index}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">{recipe.date}</TableCell>
              {this.renderWheatNames(recipe)}
              <TableCell variant="head">Protein</TableCell>
              <TableCell variant="head">Wet Gluten</TableCell>
              <TableCell variant="head">Water Absorption</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recipe.recipeFlours.map((recipeFlour, index) => (
              <TableRow key={index}>
                <TableCell variant="head">{recipeFlour.name}</TableCell>
                {this.renderWheatValues(recipeFlour)}
                <TableCell>{recipeFlour.protein}</TableCell>
                <TableCell>{recipeFlour.wetGluten}</TableCell>
                <TableCell>{recipeFlour.waterAbsorption}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ))
  }

  render() {
    return (
      <Paper>
        <Box padding={3}>
          <Typography variant="h6">Constrained Recipe</Typography>
          {this.renderTables()}
        </Box>
      </Paper>
    )
  }
}

const TableContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
  margin-bottom: 30;
`

const TableBody = styled(MaterialTableBody)`
  & tr:nth-child(even) {
    background-color: ${colors.grey[50]};
  }
`

interface TableCell {
  isNegative?: boolean
}

const TableCell = styled(MaterialTableCell)<TableCell>`
  white-space: nowrap;
  min-width: 200px;

  ${props =>
    props.isNegative &&
    css`
      background-color: ${colors.red[50]};
    `}
`
