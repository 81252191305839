import React, { FC } from 'react'
import { connect } from 'react-redux'

import ImportButton from './ImportButton'
import { importMinMaxConstraints } from 'stores/swimof/sheetMinMaxConstraints/actions'
import { importIncomingWheats } from 'stores/swimof/sheetIncomingWheat/actions'
import { importSalesForecasts } from 'stores/swimof/sheetSalesForecast/actions'
import { importOpeningStocks } from 'stores/swimof/sheetOpeningStocks/actions'
import { importWheatSales } from 'stores/swimof/sheetWheatSales/actions'
import { importFinancial } from 'stores/swimof/sheetFinancials/actions'
import { importWheatReplacementPrices } from 'stores/swimof/sheetWheatReplacementPrices/actions'
import { OpeningStockWheat } from 'interfaces/swimof/sheetOpeningStocks'
import { WheatSale } from 'interfaces/swimof/sheetWheatSales'
import { WheatReplacementPrice } from 'interfaces/swimof/sheetWheatReplacementPrices'
import { ForecastFlour } from 'interfaces/swimof/sheetSalesForecast'
import { RootStore } from 'interfaces/stores'
import { FormikProps } from 'formik'
import { PageProps } from 'modules/swimof/Page.Container'
import { updateSwimofFileName } from 'stores/swimof/fileName/actions'
import { MinMaxFlour } from 'interfaces/generic/minMaxConstraint'
import { InStockWheatSpec } from 'interfaces/master/sheetInStockWheatSpecs'
import { ExpectedWheatSpec } from 'interfaces/master/sheetExpectedWheatSpecs'
import { FlourSpec } from 'interfaces/master/sheetFlourSpecs'

interface PropsFromState {
  minMaxFlours: MinMaxFlour[]
  referenceWheats: string[]
  forecastFlours: ForecastFlour[]
  openingStockWheats: OpeningStockWheat[]
  wheatSales: WheatSale[]
  wheatReplacementPrices: WheatReplacementPrice[]
  fileName: string,
  inStockWheatSpecs: InStockWheatSpec[],
  expectedWheatSpecs: ExpectedWheatSpec[],
  flourSpecs: FlourSpec[]
}

interface PropsFromDispatch {
  importMinMaxConstraints: typeof importMinMaxConstraints
  importIncomingWheats: typeof importIncomingWheats
  importSalesForecasts: typeof importSalesForecasts
  importOpeningStocks: typeof importOpeningStocks
  importWheatSales: typeof importWheatSales
  importFinancial: typeof importFinancial
  importWheatReplacementPrices: typeof importWheatReplacementPrices
  updateSwimofFileName: typeof updateSwimofFileName
}

export type ImportButtonProps = PropsFromState &
  PropsFromDispatch & {
    form: FormikProps<PageProps['initialValues']>
  }

const ImportButtonContainer: FC<ImportButtonProps> = props => (
  <ImportButton {...props} />
)

const mapStateToProps = ({
  master: {
    sheetInStockWheatSpecs,
    sheetExpectedWheatSpecs,
    sheetFlourSpecs
  },
  swimof: {
    fileName,
    sheetMinMaxConstraints,
    sheetIncomingWheat,
    sheetSalesForecast,
    sheetOpeningStocks,
    sheetWheatSales,
    sheetWheatReplacementPrices
  }
}: RootStore): PropsFromState => ({
  minMaxFlours: sheetMinMaxConstraints.minMaxFlours,
  referenceWheats: sheetIncomingWheat.referenceWheats,
  forecastFlours: sheetSalesForecast.forecastFlours,
  openingStockWheats: sheetOpeningStocks.openingStockWheats,
  wheatSales: sheetWheatSales.wheatSales,
  wheatReplacementPrices: sheetWheatReplacementPrices.wheatReplacementPrices,
  fileName,
  inStockWheatSpecs: sheetInStockWheatSpecs.wheats,
  expectedWheatSpecs: sheetExpectedWheatSpecs.wheats,
  flourSpecs: sheetFlourSpecs.flours
})

const mapDispatchToProps: PropsFromDispatch = {
  importMinMaxConstraints,
  importIncomingWheats,
  importSalesForecasts,
  importOpeningStocks,
  importWheatSales,
  importFinancial,
  importWheatReplacementPrices,
  updateSwimofFileName
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportButtonContainer)
