import { Reducer, combineReducers } from 'redux'
import { SheetWupActionTypes } from './types'
import { SheetWup, ChangelogValue } from 'interfaces/wup/sheetWup'

const initialChangelogValue: ChangelogValue = {
  fileName: '',
  uploadTime: ''
}

const initialState: SheetWup = {
  wup: {
    noDokumen: '',
    noRevisi: '',
    plantSite: '',
    tanggalBerlaku: '',
    changelog: {
      master: initialChangelogValue,
      swimof: initialChangelogValue
    },
    wupDates: []
  }
}

const wup: Reducer<SheetWup['wup']> = (
  state = initialState.wup,
  { type, payload }
) => {
  switch (type) {
    case SheetWupActionTypes.IMPORT_DATA: {
      return payload
    }
    default: {
      return state
    }
  }
}

export const sheetWupReducer = combineReducers<SheetWup>({
  wup
})
