import React, { FC, ChangeEvent, useContext } from 'react'
import { Button, Box } from '@material-ui/core'
import { ImportButtonProps } from './ImportButton.Container'
import { getWorkbook, SwimofImporter } from 'utils/excel'
import { messageContext } from 'components/message'

const ImportButton: FC<ImportButtonProps> = ({
  importMinMaxConstraints,
  importIncomingWheats,
  importSalesForecasts,
  importOpeningStocks,
  importWheatSales,
  importFinancial,
  importWheatReplacementPrices,
  updateSwimofFileName,
  minMaxFlours,
  referenceWheats,
  forecastFlours,
  wheatSales,
  wheatReplacementPrices,
  openingStockWheats,
  form,
  fileName,
  inStockWheatSpecs,
  expectedWheatSpecs,
  flourSpecs
}) => {
  const { handleOpen } = useContext(messageContext)
  let fileInput: HTMLInputElement | null

  const validateSwimof = (swimofImporter: SwimofImporter) => {
    const validatorResults = [
      swimofImporter.validateMinMaxWheats(inStockWheatSpecs, expectedWheatSpecs),
      swimofImporter.validateMinMaxFlours(flourSpecs),
      swimofImporter.validateIncomingWheat(expectedWheatSpecs),
      swimofImporter.validateSalesForecast(flourSpecs),
      swimofImporter.validateOpeningStocks(inStockWheatSpecs),
      swimofImporter.validateWheatSales(inStockWheatSpecs, expectedWheatSpecs),
      swimofImporter.validateWheatReplacementPrices(
        inStockWheatSpecs, expectedWheatSpecs
      ),
    ]
    const validationSucceeded = validatorResults
      .map(({ success }) => success)
      .every(Boolean)

    if (validationSucceeded) {
      return { success: true, message: "Swimof is valid" }
    } else {
      const failingValidators = validatorResults.filter((result) => !result.success)
      const concatenatedErrorMessages = failingValidators
        .map(({ message }) => message)
        .join(" || ")
      return { success: false, message: concatenatedErrorMessages }
    }
  }

  const resetForm = (swimofImporter: SwimofImporter) => {
    form.resetForm({
      values: {
        minMaxFlours: swimofImporter.getMinMaxConstraints(),
        incomingWheats: swimofImporter.getIncomingWheats(),
        forecastFlours: swimofImporter.getSalesForecasts(),
        openingStockWheats: swimofImporter.getOpeningStocks(),
        wheatSales: swimofImporter.getWheatSales(),
        financial: swimofImporter.getFinancial(),
        wheatReplacementPrices: swimofImporter.getWheatReplacementPrices(),
        month: form.values.month
      }
    })
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0]
    event.target.value = ''

    getWorkbook(selectedFile!)
      .then(workbook => {
        const swimofImporter = new SwimofImporter(workbook)
        const swimofValidationResult = validateSwimof(swimofImporter)

        if (swimofValidationResult.success) {
          importMinMaxConstraints(swimofImporter.getMinMaxConstraints())
          importSalesForecasts(swimofImporter.getSalesForecasts())
          importWheatSales(swimofImporter.getWheatSales())
          importOpeningStocks(swimofImporter.getOpeningStocks())
          importIncomingWheats(swimofImporter.getIncomingWheats())
          importFinancial(swimofImporter.getFinancial())
          importWheatReplacementPrices(
            swimofImporter.getWheatReplacementPrices()
          )
          updateSwimofFileName(selectedFile!.name)
          resetForm(swimofImporter)
        } else {
          handleOpen('SWIMOF data does not match Master', swimofValidationResult.message)
        }
      })
      .catch(() =>
        handleOpen(
          'Wrong Format',
          'Please use our excel template to import data'
        )
      )
  }

  const handleButtonClick = () => {
    fileInput && fileInput.click()
  }

  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        onChange={handleFileChange}
        ref={ref => (fileInput = ref)}
      />
      <Button variant="outlined" color="primary" onClick={handleButtonClick}>
        Import
      </Button>
      <Box
        maxWidth={90}
        marginTop={1}
        textAlign="center"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        title={fileName}
      >
        {fileName}
      </Box>
    </>
  )
}

export default ImportButton
