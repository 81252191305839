import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow as MaterialTableRow,
  TableCell as MaterialTableCell,
  TableBody,
  colors
} from '@material-ui/core'
import { FieldArray, FormikProps, getIn } from 'formik'

import { TextField } from 'components/formikWrapper'
import * as yup from 'yup'
import styled from 'styled-components'
import { PageProps } from '../Page.Container'

export const validationSchemaMinMaxFlour = yup.object().shape({
  name: yup
    .string()
    .required()
    .label('name'),
  minMaxWheats: yup.array(
    yup.object().shape({
      name: yup.string().label('name'),
      value: yup
        .string()
        .matches(
          /^(100|[1-9]?[0-9])-(100|[1-9]?[0-9])$/,
          'should be range between integer from 0-100'
        )
        .required()
        .label('value')
    })
  )
})

interface TabMinMaxConstraintsProps {
  flourSpecUsages: string[]
}

export default class TabMinMaxConstraints extends Component<
  TabMinMaxConstraintsProps
> {
  isRecordError = (
    index: number,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    return getIn(form.errors, `minMaxFlours.${index}`)
  }

  isRecordEven = (index: number) => {
    return (index + 1) % 2 === 0
  }

  renderData = (form: FormikProps<PageProps['initialValues']>) => {
    const { flourSpecUsages } = this.props
    const { minMaxFlours } = form.values

    return (
      minMaxFlours.length > 0 && (
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Flours / Wheats</TableCell>
                {minMaxFlours[0].minMaxWheats.map((minMaxWheat, index) => (
                  <TableCell key={index}>
                    <div>{minMaxWheat.name.replace('(', '').replace(')', '').split(',').map((e) => <div>{e}</div>)}</div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {minMaxFlours.map((minMaxFlour, indexFlour) => (
                <TableRow
                  key={indexFlour}
                  isError={this.isRecordError(indexFlour, form)}
                  isEven={this.isRecordEven(indexFlour)}
                >
                  <TableCell variant="head">
                    <Grid container direction="column">
                      <Grid item>
                        <Box fontWeight="bolder" fontSize={14}>
                          {minMaxFlour.name}
                        </Box>
                      </Grid>
                      <Grid item>{flourSpecUsages[indexFlour]}</Grid>
                    </Grid>
                  </TableCell>
                  {minMaxFlours[0].minMaxWheats.map(
                    (minMaxWheat, indexWheat) => (
                      <TableCell key={indexWheat}>
                        <TextField
                          name={`minMaxFlours.${indexFlour}.minMaxWheats.${indexWheat}.value`}
                          label="Value"
                        />
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    )
  }

  render() {
    return (
      <FieldArray
        name="minMaxFlours"
        validateOnChange={false}
        render={({ form }) => (
          <Paper>
            <Box padding={3}>
              <Typography variant="h6">Min Max Constraints</Typography>
              {this.renderData(form)}
            </Box>
          </Paper>
        )}
      />
    )
  }
}

const TableContainer = styled('div')`
  max-width: 100%;
  max-height: 500px;
  overflow: auto;
`

function getCellColor(isError?: boolean, isEven?: boolean) {
  return isError ? colors.red[50] : isEven ? colors.grey[50] : 'white'
}

interface TableRowProps {
  isError?: boolean
  isEven?: boolean
}

const TableRow = styled(MaterialTableRow)<TableRowProps>`
  background-color: ${({ isError, isEven }) => getCellColor(isError, isEven)};
`

const TableCell = styled(MaterialTableCell)`
  white-space: nowrap;
  min-width: 10px;
  padding: 10px 5px !important;
`
