import React from 'react'

export interface MessageContext {
  title: string
  description: string
  isOpen: boolean
  handleOpen: (title: string, description: string) => void
  handleClose: () => void
}

export const messageContext = React.createContext<MessageContext>({
  title: '',
  description: '',
  isOpen: false,
  handleOpen: () => null,
  handleClose: () => null
})

export const { Consumer, Provider } = messageContext
