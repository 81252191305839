import { Reducer, combineReducers } from 'redux'
import { SheetConstrainedRecipeActionTypes } from './types'
import { SheetConstrainedRecipe } from 'interfaces/wup/sheetConstrainedRecipe'

const initialState: SheetConstrainedRecipe = {
  constaints: []
}

const constaints: Reducer<SheetConstrainedRecipe['constaints']> = (
  state = initialState.constaints,
  { type, payload }
) => {
  switch (type) {
    case SheetConstrainedRecipeActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetConstrainedRecipeActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetConstrainedRecipeReducer = combineReducers<
  SheetConstrainedRecipe
>({
  constaints
})
