import React, { FC } from "react";
import { connect } from "react-redux";
import { importConstraints } from "stores/recipe/sheetConstrained/actions";
import { importIdeals } from "stores/recipe/sheetIdeal/actions";
import { updateRecipeFileName } from "stores/recipe/fileName/actions";
import ImportButton from "./ImportButton";
import { PageProps } from "modules/recipe/Page.Container";
import { FormikProps } from "formik";
import { RootStore } from "interfaces/stores";

interface PropsFromState {
  fileName: string;
}

interface PropsFromDispatch {
  importConstraints: typeof importConstraints;
  importIdeals: typeof importIdeals;
  updateRecipeFileName: typeof updateRecipeFileName;
}

export type ImportButtonProps = PropsFromState &
  PropsFromDispatch & {
    form: FormikProps<PageProps["initialValues"]>;
  };

const ImportButtonContainer: FC<ImportButtonProps> = (props) => (
  <ImportButton {...props} />
);

const mapStateToProps = ({
  recipe: { fileName },
}: RootStore): PropsFromState => ({
  fileName,
});

const mapDispatchToProps: PropsFromDispatch = {
  importConstraints,
  importIdeals,
  updateRecipeFileName,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportButtonContainer);
