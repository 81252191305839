import React, { Component } from 'react'
import { AppBar, Toolbar, Button, Grid, Box } from '@material-ui/core'
import { routes } from 'config'
import {
  Route,
  Redirect,
  Switch,
  RouteComponentProps,
  withRouter
} from 'react-router'
import { logout, getUser } from 'utils/auth'
import ModuleStepper from 'components/moduleStepper'

class Page extends Component<RouteComponentProps> {
  handleLogoutButtonClick = () => {
    logout()
    this.props.history.push('/login')
  }

  renderRoutes = () => {
    return (
      <Switch>
        {routes.map(route => (
          <Route
            key={route.label}
            path={route.path}
            component={route.component}
          />
        ))}
        <Route component={() => <Redirect to={routes[0].path} />} />
      </Switch>
    )
  }

  render() {
    return (
      <>
        <AppBar position="fixed">
          <Toolbar>
            <Grid container justify="space-between" alignItems="center">
              {getUser().username}
              <Button color="inherit" onClick={this.handleLogoutButtonClick}>
                Logout
              </Button>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box marginTop="80px" paddingLeft="15px">
          <ModuleStepper />
          {this.renderRoutes()}
        </Box>
      </>
    )
  }
}

export default withRouter(Page)
