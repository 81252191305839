import React, { FC } from 'react'
import { connect } from 'react-redux'
import { importInStockWheatSpecs } from 'stores/master/sheetInStockWheatSpecs/actions'
import { importExpectedWheatSpecs } from 'stores/master/sheetExpectedWheatSpecs/actions'
import { importFlourSpecs } from 'stores/master/sheetFlourSpecs/actions'
import { importDate, importPlantSite } from 'stores/master/sheetDate/actions'
import { updateMasterFileName } from 'stores/master/fileName/actions'
import ImportButton from './ImportButton'
import { PageProps } from 'modules/master/Page.Container'
import { FormikProps } from 'formik'
import { RootStore } from 'interfaces/stores'

interface PropsFromState {
  fileName: string
}

interface PropsFromDispatch {
  importInStockWheatSpecs: typeof importInStockWheatSpecs
  importExpectedWheatSpecs: typeof importExpectedWheatSpecs
  importFlourSpecs: typeof importFlourSpecs
  importDate: typeof importDate
  importPlantSite: typeof importPlantSite
  updateMasterFileName: typeof updateMasterFileName
}

export type ImportButtonProps = PropsFromState &
  PropsFromDispatch & {
    form: FormikProps<PageProps['initialValues']>
  }

const ImportButtonContainer: FC<ImportButtonProps> = props => (
  <ImportButton {...props} />
)

const mapStateToProps = ({
  master: { fileName }
}: RootStore): PropsFromState => ({
  fileName
})

const mapDispatchToProps: PropsFromDispatch = {
  importInStockWheatSpecs,
  importExpectedWheatSpecs,
  importFlourSpecs,
  importDate,
  importPlantSite,
  updateMasterFileName
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportButtonContainer)
