import React, { FC } from 'react'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid
} from '@material-ui/core'
import { FastField, ErrorMessage, FormikProps, FieldProps } from 'formik'
import { getDateHalf, getMonth, getYear, yearFormat } from 'utils/dateHalf'
import styled from 'styled-components'

// @ts-ignore
const moment = extendMoment(Moment)

interface DateFieldProps {
  name: string
  label: string
}

enum INDEX {
  DATE_PART = 0,
  MONTH = 1,
  YEAR = 2
}

export const DateField: FC<DateFieldProps> = ({ label, name }) => {
  const isError = (form: FormikProps<any>) => {
    return form.errors[name] !== undefined
  }

  const handleChange = (
    value: string,
    fieldValue: string,
    index: number,
    form: FieldProps['form']
  ) => {
    const splittedFieldValue = fieldValue.split('-')
    splittedFieldValue[index] = value
    form.setFieldValue(name, splittedFieldValue.join('-'))
  }

  const getYears = () => {
    const start = moment().subtract(5, 'year')
    const end = moment().add(5, 'years')
    const range = moment.range(start, end)
    return Array.from(range.by('years'))
  }

  const renderYearItems = () => {
    return getYears().map((date, index) => (
      <MenuItem key={index} value={date.format(yearFormat)}>
        {date.format(yearFormat)}
      </MenuItem>
    ))
  }

  const renderMonthItems = () => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    return months.map(month => (
      <MenuItem key={month} value={month}>
        {month}
      </MenuItem>
    ))
  }

  return (
    <FastField name={name}>
      {({ form, field }: FieldProps) => (
        <div>
          <StyledInputLabel error={isError(form)}>{label}</StyledInputLabel>

          <Grid container>
            <Grid item>
              <FormControl>
                <Select
                  value={getDateHalf(field.value)}
                  onChange={event =>
                    handleChange(
                      event.target.value as string,
                      field.value,
                      INDEX.DATE_PART,
                      form
                    )
                  }
                >
                  <MenuItem value="FH">FH</MenuItem>
                  <MenuItem value="LH">LH</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <Select
                  value={getMonth(field.value)}
                  onChange={event =>
                    handleChange(
                      event.target.value as string,
                      field.value,
                      INDEX.MONTH,
                      form
                    )
                  }
                >
                  {renderMonthItems()}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <Select
                  placeholder="Year"
                  value={getYear(field.value)}
                  onChange={event =>
                    handleChange(
                      event.target.value as string,
                      field.value,
                      INDEX.YEAR,
                      form
                    )
                  }
                >
                  {renderYearItems()}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <ErrorMessage
            name={name}
            render={message => <FormHelperText error>{message}</FormHelperText>}
          />
        </div>
      )}
    </FastField>
  )
}

const StyledInputLabel = styled(InputLabel)`
  font-size: smaller !important;
`