import React, { Component } from 'react'
import { Paper, Box, Typography, Grid } from '@material-ui/core'
import * as yup from 'yup'
import { DateField, TextField } from 'components/formikWrapper'

export const validationSchemaDate = yup.string().required()
export const validationSchemaPlantSite = yup
  .string()
  .required()
  .label('plant site')

export default class TabDate extends Component {
  render() {
    return (
      <Paper>
        <Box padding={3}>
          <Typography variant="h6">Date & Plant Site</Typography>

          <Box marginTop={2}>
            <Grid container spacing={5}>
              <Grid item sm={3}>
                <DateField name="date" label="Date" />                
              </Grid>
              <Grid item sm={3}>
                <TextField name="plantSite" label="Plant Site" />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    )
  }
}
