import { Workbook, Buffer } from 'exceljs'

export const getWorkbook = (blob: Blob) => {
  return new Promise<Workbook>(resolve => {
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const data = event.target && event.target.result
      new Workbook().xlsx
        .load(data as Buffer)
        .then(workbook => resolve(workbook))
    }
    reader.readAsArrayBuffer(blob)
  })
}
