import { Reducer, combineReducers } from 'redux'
import { SheetMinMaxActionTypes } from './types'
import { SheetMinMax } from 'interfaces/wup/sheetMinMax'

const initialState: SheetMinMax = {
  minMaxFlours: []
}

const minMaxFlours: Reducer<SheetMinMax['minMaxFlours']> = (
  state = initialState.minMaxFlours,
  { type, payload }
) => {
  switch (type) {
    case SheetMinMaxActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetMinMaxReducer = combineReducers<SheetMinMax>({
  minMaxFlours
})
