import React, { Component, ChangeEvent } from 'react'
import {
  Container,
  Tabs,
  Tab as MaterialTab,
  Box,
  Typography,
  Grid,
  Button,
  colors
} from '@material-ui/core'
import TabPanel from 'components/TabPanel'
import TabInStockWheatSpecs from './tabInStockWheatSpecs'
import TabExpectedWheatSpecs from './tabExpectedWheatSpecs'
import TabFlourSpecs from './tabFlourSpecs'
import TabDate from './tabDate'
import ImportButton from './components/importButton'
import ExportButton from './components/exportButton'
import { Formik, Form, FormikContextType, FormikProps } from 'formik'
import { PageProps } from './Page.Container'
import * as yup from 'yup'
import { validationSchemaInStockWheatSpec } from './tabInStockWheatSpecs/TabInStockWheatSpecs'
import { validationSchemaExpectedWheatSpec } from './tabExpectedWheatSpecs/TabExpectedWheatSpecs'
import { validationSchemaFlourSpec } from './tabFlourSpecs/TabFlourSpecs'
import {
  validationSchemaDate,
  validationSchemaPlantSite
} from './tabDate/TabDate'

import Moment from 'moment'
import { extendMoment } from 'moment-range'
import _ from 'lodash'
import {
  getMonth,
  getYear,
  getDateHalf,
  monthFormat,
  yearFormat
} from 'utils/dateHalf'
import styled, { css } from 'styled-components'

// @ts-ignore
const moment = extendMoment(Moment)

const validationSchema = yup.object().shape({
  inStockWheatSpecs: yup.array(validationSchemaInStockWheatSpec),
  expectedWheatSpecs: yup.array(validationSchemaExpectedWheatSpec),
  flourSpecs: yup.array(validationSchemaFlourSpec),
  date: validationSchemaDate,
  plantSite: validationSchemaPlantSite
})

interface PageState {
  activeTab: number
}

enum TabIndex {
  TAB_DATE = 0,
  TAB_IN_STOCK_WHEAT_SPECS = 1,
  TAB_EXPECTED_WHEAT_SPECS = 2,
  TAB_FLOUR_SPECS = 3
}

class Page extends Component<PageProps, PageState> {
  state: PageState = {
    activeTab: TabIndex.TAB_DATE
  }

  isLastIndex = (currentIndex: number, array: any[]) => {
    return currentIndex === array.length - 1
  }

  getDateRanges = (startDate: string, monthAmount: number) => {
    const dateTime = `${getMonth(startDate)}-${getYear(startDate)}`
    const dateFormat = `${monthFormat}-${yearFormat}`
    const start = moment(dateTime, dateFormat)
    const end = moment(dateTime, dateFormat).add(monthAmount - 1, 'months')
    return Array.from(moment.range(start, end).by('month'))
  }

  getNextDateFrom = (startDate: string, monthAmount: number) => {
    let nextMonths: string[] = []
    const dateRanges = this.getDateRanges(startDate, monthAmount)

    dateRanges.forEach(date => {
      const month = date.format(monthFormat).toString()
      const year = date.format(yearFormat).toString()
      nextMonths.push(`FH-${month}-${year}`)
      nextMonths.push(`LH-${month}-${year}`)
    })

    if (getDateHalf(startDate) === 'LH') {
      const date = _.last(dateRanges)
      if (date) {
        const nextDate = date.add(1, 'month')
        const month = nextDate.format(monthFormat).toString()
        const year = nextDate.format(yearFormat).toString()
        nextMonths.shift()
        nextMonths.push(`FH-${month}-${year}`)
      }
    }

    return nextMonths
  }

  getSalesForecasts = (values: PageProps['initialValues']) => {
    return values.flourSpecs.map(({ name }) => ({
      name,
      forecastDates: this.getNextDateFrom(values.date, 10).map(date => ({
        name: date,
        value: 0
      }))
    }))
  }

  getMinMaxConstraints = (values: PageProps['initialValues']) => {
    return values.flourSpecs.map(({ name }) => ({
      name,
      minMaxWheats: values.expectedWheatSpecs.map(({ origin, typeName }) => ({
        name: `(${origin}, ${typeName})`,
        value: '0-0'
      }))
    }))
  }

  getReferenceWheats = (values: PageProps['initialValues']) => {
    return values.expectedWheatSpecs.map(
      ({ origin, typeName }) => `(${origin}, ${typeName})`
    )
  }

  getOpeningStocks = (values: PageProps['initialValues']) => {
    return values.inStockWheatSpecs.map(({ origin, typeName, code }) => ({
      name: `(${origin}, ${typeName}, ${code})`,
      quantity: 0
    }))
  }

  getWheatSales = (values: PageProps['initialValues']) => {
    return values.expectedWheatSpecs.map(({ origin, typeName }) => ({
      name: `(${origin}, ${typeName})`,
      dateSales: this.getNextDateFrom(values.date, 10).map(date => ({
        name: date,
        value: 0
      }))
    }))
  }

  getFinancial = () => {
    return {
      amortization: 0,
      electricity: 0,
      exchangeRate: 0,
      factoryDepreciation: 0,
      financeCost: 0,
      flourAdditives: 0,
      hqDepreciation: 0,
      logistics: 0,
      maintenance: 0,
      operatingSupplies: 0,
      othersAndFacilities: 0,
      packagingMaterials: 0,
      peopleCost: 0,
      salesAndAdmin: 0,
      vat: 0
    }
  }

  getWheatReplacementPrices = (values: PageProps['initialValues']) => {
    return values.expectedWheatSpecs.map(({ typeName }) => ({
      name: typeName,
      dateReplacementPrices: this.getNextDateFrom(values.date, 10).map(
        date => ({
          name: date,
          value: 0
        })
      )
    }))
  }

  changeInStockWheatSpecsArrivalDate = (
    current: FormikContextType<PageProps['initialValues']>,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    form.setFieldValue(
      'inStockWheatSpecs',
      form.values.inStockWheatSpecs.map(inStockWheatSpec => ({
        ...inStockWheatSpec,
        arrivalDate: current.values.date
      }))
    )
  }

  handleTabChange = (event: ChangeEvent<{}>, activeTab: number) => {
    this.setState({ activeTab })
  }

  handleSubmit = (values: PageProps['initialValues']) => {
    this.props.importInStockWheatSpecs(values.inStockWheatSpecs)
    this.props.importExpectedWheatSpecs(values.expectedWheatSpecs)
    this.props.importFlourSpecs(values.flourSpecs)
    this.props.importDate(values.date)
    this.props.importPlantSite(values.plantSite)

    this.props.importMinMaxConstraints(this.getMinMaxConstraints(values))
    this.props.importIncomingWheats([])
    this.props.importReferenceWheats(this.getReferenceWheats(values))
    this.props.importSalesForecasts(this.getSalesForecasts(values))
    this.props.importOpeningStocks(this.getOpeningStocks(values))
    this.props.importWheatSales(this.getWheatSales(values))
    this.props.importFinancial(this.getFinancial())
    this.props.importWheatReplacementPrices(
      this.getWheatReplacementPrices(values)
    )
    this.props.updateSwimofFileName('')
    this.props.history.push('/swimof')
  }

  renderPageHeader = (form: FormikProps<PageProps['initialValues']>) => {
    return (
      <Box marginBottom={2}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h4">Master</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <ImportButton form={form} />
              </Grid>
              <Grid item>
                <ExportButton form={form} />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }

  isTabError = (
    name: keyof PageProps['initialValues'],
    form: FormikProps<PageProps['initialValues']>
  ) => {
    return form.errors[name] !== undefined
  }

  renderTabs = (form: FormikProps<PageProps['initialValues']>) => {
    const { activeTab } = this.state
    return (
      <Tabs value={activeTab} onChange={this.handleTabChange}>
        <Tab label="date & site" isError={this.isTabError('date', form)} />
        <Tab
          label="in stock wheat specs"
          isError={this.isTabError('inStockWheatSpecs', form)}
        />
        <Tab
          label="expected wheat specs"
          isError={this.isTabError('expectedWheatSpecs', form)}
        />
        <Tab
          label="flour specs"
          isError={this.isTabError('flourSpecs', form)}
        />
      </Tabs>
    )
  }

  renderTabPanels = () => {
    const { activeTab } = this.state
    return (
      <Box marginTop={2}>
        <TabPanel index={TabIndex.TAB_DATE} activeTab={activeTab}>
          <TabDate />
        </TabPanel>

        <TabPanel
          index={TabIndex.TAB_IN_STOCK_WHEAT_SPECS}
          activeTab={activeTab}
        >
          <TabInStockWheatSpecs />
        </TabPanel>

        <TabPanel
          index={TabIndex.TAB_EXPECTED_WHEAT_SPECS}
          activeTab={activeTab}
        >
          <TabExpectedWheatSpecs />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_FLOUR_SPECS} activeTab={activeTab}>
          <TabFlourSpecs />
        </TabPanel>
      </Box>
    )
  }

  render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnMount
        onSubmit={this.handleSubmit}
        render={form => (
          <Form>
            <Container maxWidth="xl">
              {this.renderPageHeader(form)}
              {this.renderTabs(form)}
              {this.renderTabPanels()}
            </Container>
          </Form>
        )}
      />
    )
  }
}

const Tab = styled(MaterialTab)<{ isError?: boolean }>`
  ${props =>
    props.isError &&
    css`
      color: ${colors.red[500]} !important;
    `}
`

export default Page
