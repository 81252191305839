import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody as MaterialTableBody,
  colors
} from '@material-ui/core'
import styled, { css } from 'styled-components'
import { getUSValue } from 'utils/number'
import { MinMaxFlour } from 'interfaces/generic/minMaxConstraint'

interface TabMinMaxProps {
  minMaxFlours: MinMaxFlour[]
}

export default class TabWheatCost extends Component<TabMinMaxProps> {
  renderData = () => {
    const { minMaxFlours } = this.props
    return (
      minMaxFlours.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Flours / Wheats</TableCell>
                {minMaxFlours[0].minMaxWheats.map((minMaxWheat, index) => (
                  <TableCell key={index}>{minMaxWheat.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {minMaxFlours.map((minMaxFlour, indexFlour) => (
                <TableRow key={indexFlour}>
                  <TableCell variant="head">{minMaxFlour.name}</TableCell>
                  {minMaxFlours[0].minMaxWheats.map(
                    (minMaxWheat, indexDate) => (
                      <TableCell
                        key={indexDate}
                        isNegative={parseInt(minMaxWheat.value) < 0}
                      >
                        {getUSValue(minMaxWheat.value)}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    )
  }

  render() {
    return (
      <Paper>
        <Box padding={3}>
          <Typography variant="h6">Wheat Cost</Typography>
          {this.renderData()}
        </Box>
      </Paper>
    )
  }
}

const TableContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
  margin-bottom: 30;
`

const TableBody = styled(MaterialTableBody)`
  & tr:nth-child(even) {
    background-color: ${colors.grey[50]};
  }
`

interface TableCell {
  isNegative?: boolean
}

const TableCell = styled(MaterialTableCell)<TableCell>`
  white-space: nowrap;
  min-width: 200px;

  ${props =>
    props.isNegative &&
    css`
      background-color: ${colors.red[50]};
    `}
`
