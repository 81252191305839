import { Reducer } from 'redux'
import { WupDownloadLinkActionTypes } from './types'

const initialState = ''

export const downloadLinkReducer: Reducer<string> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case WupDownloadLinkActionTypes.UPDATE: {
      return payload
    }
    default: {
      return state
    }
  }
}
