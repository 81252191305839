import { InStockWheatSpec } from 'interfaces/master/sheetInStockWheatSpecs'
import { ExpectedWheatSpec } from 'interfaces/master/sheetExpectedWheatSpecs'

const zip = (firstArray: string[], secondArray: string[]) => {
  return firstArray.map((e, i) => [e, secondArray[i]])
}

const symmetricDifference = (firstArray: string[], secondArray: string[]) => {
  return firstArray
         .filter(x => !secondArray.includes(x))
         .concat(secondArray.filter(x => !firstArray.includes(x)))
}

export const getWheatOriginAndNames = (wheatSpecs: any[]) => {
  const wheatOrigins = wheatSpecs.map(({ origin }) => origin)
  const wheatNames = wheatSpecs.map(({ typeName }) => typeName)
  const zipped = zip(wheatOrigins, wheatNames)
  return zipped.map((e) => "(" + e.join(", ") + ")")
}

export const getWheatOriginNameAndCodes = (wheatSpecs: any[]) => {
  const wheatOrigins = wheatSpecs.map(({ origin }) => origin)
  const wheatNames = wheatSpecs.map(({ typeName }) => typeName)
  const wheatCodes = wheatSpecs.map(({ code }) => code)
  const firstZip = zip(wheatOrigins, wheatNames).map((e) => "(" + e.join(", "))
  const secondZip = zip(firstZip, wheatCodes)
  return secondZip.map((e) => e.join(", ") + ")")
}

export const setDifference = (firstArray: string[], secondArray: string[]) => {
  return firstArray.filter(x => !secondArray.includes(x))
}

export const validateSwimofAgainstMasterDataSymmetrically = (
  masterData: string[], swimofData: string[], errorMessage: string
) => {
  const difference = symmetricDifference(swimofData, masterData)
  if (difference.length === 0) {
    return { success: true, message: "Validation successful" }
  } else {
    return { success: false, message: `${errorMessage} ${difference.join(", ")}.` }
  }
}

export const getMasterWheatOriginAndNames = (
  inStockWheatSpecs: InStockWheatSpec[], expectedWheatSpecs: ExpectedWheatSpec[],
) => {
  const inStockWheats = getWheatOriginAndNames(inStockWheatSpecs)
  const expectedWheats = getWheatOriginAndNames(expectedWheatSpecs)
  return Array.from(new Set([...inStockWheats, ...expectedWheats]))
}
