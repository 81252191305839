import { Reducer, combineReducers } from 'redux'
import { SheetEffectiveCalcActionTypes } from './types'
import { SheetEffectiveCalc } from 'interfaces/wup/sheetEffectiveCalc'

const initialState: SheetEffectiveCalc = {
  effectiveCalcs: []
}

const effectiveCalcs: Reducer<SheetEffectiveCalc['effectiveCalcs']> = (
  state = initialState.effectiveCalcs,
  { type, payload }
) => {
  switch (type) {
    case SheetEffectiveCalcActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetEffectiveCalcActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetEffectiveCalcReducer = combineReducers<SheetEffectiveCalc>({
  effectiveCalcs
})
