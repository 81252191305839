import { action } from 'typesafe-actions'
import { SheetWheatReplacementPricesActionTypes } from './types'
import { WheatReplacementPrice } from 'interfaces/swimof/sheetWheatReplacementPrices'

export const createWheatReplacementPrice = (
  wheatReplacementPrice: WheatReplacementPrice
) =>
  action(SheetWheatReplacementPricesActionTypes.CREATE, wheatReplacementPrice)

export const importWheatReplacementPrices = (
  wheatReplacementPrices: WheatReplacementPrice[]
) =>
  action(
    SheetWheatReplacementPricesActionTypes.IMPORT_DATA,
    wheatReplacementPrices
  )
