import * as React from 'react'
import { connect, FormikContextType } from 'formik'

interface OuterProps<T> {
  onChange: (current: FormikContextType<T>, prev: FormikContextType<T>) => void
}

interface ConnectProps<T> {
  formik: FormikContextType<T>
}

type EffectProps<T> = OuterProps<T> & ConnectProps<T>

class Effect<T> extends React.Component<EffectProps<T>> {
  componentDidUpdate(prevProps: EffectProps<T>) {
    if (prevProps.formik !== this.props.formik) {
      this.props.onChange(this.props.formik, prevProps.formik)
    }
  }

  render() {
    return null
  }
}

export default connect<OuterProps<any>, any>(Effect)
