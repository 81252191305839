import React, { useContext } from 'react'
import { confirmContext } from './ConfirmContext'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'

export const ConfirmDialog = () => {
  const {
    title,
    description,
    isOpen,
    handleClose,
    acceptCallback
  } = useContext(confirmContext)

  const handleAcceptButton = () => {
    handleClose()
    acceptCallback()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          No
        </Button>
        <Button onClick={handleAcceptButton} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
