import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isLoggedIn } from 'utils/auth'

interface ProtectedRouteProps {
  path: string
  component: React.FunctionComponent | React.ComponentClass
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  path,
  component: Component
}) => (
  <Route
    path={path}
    component={() => (isLoggedIn() ? <Component /> : <Redirect to="/login" />)}
  />
)

export default ProtectedRoute
