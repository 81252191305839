import React from 'react'
import { FieldProps, ErrorMessage, FormikProps, FastField } from 'formik'
import {
  Select as MaterialSelect,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText
} from '@material-ui/core'

export interface Option {
  label: string
  value: string
}

interface TextFieldProps {
  name: string
  label: string
  options: Option[]
}

export const Select: React.FC<TextFieldProps> = ({ name, label, options }) => {
  function isError(form: FormikProps<any>) {
    return form.errors[name] !== undefined
  }

  function renderOptions() {
    return options.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  }

  return (
    <FastField name={name}>
      {({ form, field }: FieldProps) => (
        <FormControl style={{ minWidth: 100 }} error={isError(form)}>
          <InputLabel error={isError(form)}>{label}</InputLabel>
          <MaterialSelect {...field} error={isError(form)}>
            {renderOptions()}
          </MaterialSelect>
          <ErrorMessage
            name={name}
            render={message => <FormHelperText error>{message}</FormHelperText>}
          />
        </FormControl>
      )}
    </FastField>
  )
}
