import React, { FC } from "react";
import { connect } from "react-redux";
import { IncomingWheat } from "interfaces/swimof/sheetIncomingWheat";
import { ForecastFlour } from "interfaces/swimof/sheetSalesForecast";
import { OpeningStockWheat } from "interfaces/swimof/sheetOpeningStocks";
import { WheatSale } from "interfaces/swimof/sheetWheatSales";
import { Financial } from "interfaces/swimof/sheetFinancials";
import { WheatReplacementPrice } from "interfaces/swimof/sheetWheatReplacementPrices";
import Page from "./Page";
import { RootStore } from "interfaces/stores";
import { importMinMaxConstraints } from "stores/swimof/sheetMinMaxConstraints/actions";
import { importIncomingWheats } from "stores/swimof/sheetIncomingWheat/actions";
import { importSalesForecasts } from "stores/swimof/sheetSalesForecast/actions";
import { importOpeningStocks } from "stores/swimof/sheetOpeningStocks/actions";
import { importWheatSales } from "stores/swimof/sheetWheatSales/actions";
import { importFinancial } from "stores/swimof/sheetFinancials/actions";
import { importWheatReplacementPrices } from "stores/swimof/sheetWheatReplacementPrices/actions";
import { importMonth } from "stores/month/actions";
import { updateRecipeFileName } from "stores/recipe/fileName/actions";
import { FlourSpec } from "interfaces/master/sheetFlourSpecs";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MinMaxFlour } from "interfaces/generic/minMaxConstraint";

interface PropsFromState {
  initialValues: {
    minMaxFlours: MinMaxFlour[];
    incomingWheats: IncomingWheat[];
    forecastFlours: ForecastFlour[];
    openingStockWheats: OpeningStockWheat[];
    wheatSales: WheatSale[];
    financial: Financial;
    wheatReplacementPrices: WheatReplacementPrice[];
    month: number;
  };
  flourSpecs: FlourSpec[];
  referenceWheats: string[];
}

interface PropsFromDispatch {
  importMinMaxConstraints: typeof importMinMaxConstraints;
  importIncomingWheats: typeof importIncomingWheats;
  importSalesForecasts: typeof importSalesForecasts;
  importOpeningStocks: typeof importOpeningStocks;
  importWheatSales: typeof importWheatSales;
  importFinancial: typeof importFinancial;
  importWheatReplacementPrices: typeof importWheatReplacementPrices;
  importMonth: typeof importMonth;
  updateRecipeFileName: typeof updateRecipeFileName;
}

export type PageProps = PropsFromState &
  PropsFromDispatch &
  RouteComponentProps;

const PageContainer: FC<PageProps> = (props) => <Page {...props} />;

const mapStateToProps = ({
  master: { sheetExpectedWheatSpecs, sheetFlourSpecs },
  swimof: {
    sheetMinMaxConstraints,
    sheetIncomingWheat,
    sheetSalesForecast,
    sheetOpeningStocks,
    sheetWheatSales,
    sheetFinancials,
    sheetWheatReplacementPrices,
  },
  month,
}: RootStore): PropsFromState => ({
  initialValues: {
    minMaxFlours: sheetMinMaxConstraints.minMaxFlours,
    incomingWheats: sheetIncomingWheat.incomingWheats,
    forecastFlours: sheetSalesForecast.forecastFlours,
    openingStockWheats: sheetOpeningStocks.openingStockWheats,
    wheatSales: sheetWheatSales.wheatSales,
    financial: sheetFinancials.financial,
    wheatReplacementPrices: sheetWheatReplacementPrices.wheatReplacementPrices,
    month,
  },
  flourSpecs: sheetFlourSpecs.flours,
  referenceWheats: sheetIncomingWheat.referenceWheats,
});

const mapDispatchToProps: PropsFromDispatch = {
  importMinMaxConstraints,
  importIncomingWheats,
  importSalesForecasts,
  importOpeningStocks,
  importWheatSales,
  importFinancial,
  importWheatReplacementPrices,
  importMonth,
  updateRecipeFileName,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageContainer)
);
