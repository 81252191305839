import React, { FC } from 'react'
import { Button, Link } from '@material-ui/core'
import { ExportButtonProps } from './ExportButton.Container'

const ExportButton: FC<ExportButtonProps> = ({ downloadLink }) => {
  return (
    <Link href={downloadLink} style={{ textDecoration: 'none' }}>
      <Button variant="outlined" color="primary">
        Export
      </Button>
    </Link>
  )
}

export default ExportButton
