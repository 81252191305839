import React, { FC } from 'react'
import { connect } from 'react-redux'
import ExportButton from './ExportButton'

import { FormikProps } from 'formik'
import { PageProps } from 'modules/recipe/Page.Container'
import { importConstraints } from 'stores/recipe/sheetConstrained/actions'
import { importIdeals } from 'stores/recipe/sheetIdeal/actions'

interface PropsFromDispatch {
  importConstraints: typeof importConstraints
  importIdeals: typeof importIdeals
}

export type ExportButtonProps = PropsFromDispatch & {
  form: FormikProps<PageProps['initialValues']>
}

const ExportButtonContainer: FC<ExportButtonProps> = props => (
  <ExportButton {...props} />
)

const mapDispatchToProps: PropsFromDispatch = {
  importConstraints,
  importIdeals
}

export default connect(null, mapDispatchToProps)(ExportButtonContainer)
