import React from 'react'

export interface ConfirmContext {
  title: string
  description: string
  acceptCallback: () => void
  isOpen: boolean
  handleOpen: (
    title: string,
    description: string,
    acceptCallback: () => void
  ) => void
  handleClose: () => void
}

export const confirmContext = React.createContext<ConfirmContext>({
  title: '',
  description: '',
  acceptCallback: () => null,
  isOpen: false,
  handleOpen: () => null,
  handleClose: () => null
})

export const { Consumer, Provider } = confirmContext
