import Excel from 'exceljs'
import { Recipe, RecipeFlour } from 'interfaces/generic/recipe'

export class RecipeImporter {
  private workbook = new Excel.Workbook()

  constructor(workbook: Excel.Workbook) {
    this.workbook = workbook
  }

  private isTableHeader = (rowValues: any[]) => {
    return typeof rowValues[2] === 'string'
  }

  private getTableLocations = (worksheet: Excel.Worksheet) => {
    const tableLocations: number[] = []
    worksheet.eachRow((row, rowNumber) => {
      const rowValues = row.values as any[]
      if (this.isTableHeader(rowValues)) tableLocations.push(rowNumber)
    })
    return tableLocations
  }

  private getNextTableLocation = (
    index: number,
    worksheet: Excel.Worksheet
  ) => {
    const tableLocations = this.getTableLocations(worksheet)
    return tableLocations[index + 1]
      ? tableLocations[index + 1]
      : worksheet.rowCount + 1
  }

  private getFilledValues = (values: string[]) => {
    const filledValues: string[] = []

    for (let i = 0; i < values.length; i++) {
      const value = values[i]
      filledValues.push(value ? value : '0')
    }

    return filledValues
  }

  private getProtein = (rowValues: string[]) => {
    return rowValues[rowValues.length - 3]
  }

  private getWetGluten = (rowValues: string[]) => {
    return rowValues[rowValues.length - 2]
  }

  private getWaterAbsorption = (rowValues: string[]) => {
    return rowValues[rowValues.length - 1]
  }

  private getData = (worksheetName: string) => {
    const worksheet = this.workbook.getWorksheet(worksheetName)
    const data: Recipe[] = []
    const tableLocations = this.getTableLocations(worksheet)

    tableLocations.forEach((tableLocation, index) => {
      const firstRow = worksheet.getRow(tableLocation).values as string[]
      const recipeFlours: RecipeFlour[] = []

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = this.getFilledValues(row.values as string[])
        const protein = this.getProtein(rowValues)
        const wetGluten = this.getWetGluten(rowValues)
        const waterAbsorption = this.getWaterAbsorption(rowValues)

        if (
          rowNumber > tableLocation &&
          rowNumber < this.getNextTableLocation(index, worksheet)
        ) {
          recipeFlours.push({
            name: rowValues[1],
            protein,
            wetGluten,
            waterAbsorption,
            recipeWheats: rowValues
              .slice(2, rowValues.length - 3)
              .map((value, index) => ({
                name: firstRow[index + 2] as string,
                value: parseInt(value)
              }))
          })
        }
      })

      data.push({
        date: firstRow[1],
        recipeFlours
      })
    })

    return data
  }

  getConstraits = () => {
    return this.getData('constrained')
  }

  getIdeals = () => {
    return this.getData('ideal')
  }
}
