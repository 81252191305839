import React, { FC, ChangeEvent, useContext } from 'react'
import { Button, Box } from '@material-ui/core'
import { ImportButtonProps } from './ImportButton.Container'
import { getWorkbook, MasterImporter } from 'utils/excel'
import { messageContext } from 'components/message'

const ImportButton: FC<ImportButtonProps> = ({
  importInStockWheatSpecs,
  importExpectedWheatSpecs,
  importFlourSpecs,
  importDate,
  importPlantSite,
  updateMasterFileName,
  form,
  fileName
}) => {
  const { handleOpen } = useContext(messageContext)
  let fileInput: HTMLInputElement | null = null

  const resetForm = (masterImporter: MasterImporter) => {
    form.setFieldValue('date', masterImporter.getDate())
    form.setFieldValue('plantSite', masterImporter.getPlantSite())
    form.setFieldValue(
      'inStockWheatSpecs',
      masterImporter.getInStockWheatSpecs()
    )
    form.setFieldValue(
      'expectedWheatSpecs',
      masterImporter.getExpectedWheatSpecs()
    )
    form.setFieldValue('flourSpecs', masterImporter.getFlourSpecs())
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0]
    event.target.value = ''

    getWorkbook(selectedFile!)
      .then(workbook => {
        const masterImporter = new MasterImporter(workbook)
        importInStockWheatSpecs(masterImporter.getInStockWheatSpecs())
        importExpectedWheatSpecs(masterImporter.getExpectedWheatSpecs())
        importFlourSpecs(masterImporter.getFlourSpecs())
        importDate(masterImporter.getDate())
        importPlantSite(masterImporter.getPlantSite())
        updateMasterFileName(selectedFile!.name)
        resetForm(masterImporter)
      })
      .catch(() =>
        handleOpen(
          'Wrong Format',
          'Please use our excel template to import data'
        )
      )
  }

  const handleButtonClick = () => {
    fileInput && fileInput.click()
  }

  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        onChange={handleFileChange}
        ref={ref => (fileInput = ref)}
      />
      <Button variant="outlined" color="primary" onClick={handleButtonClick}>
        Import
      </Button>
      <Box
        maxWidth={90}
        marginTop={1}
        textAlign="center"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        title={fileName}
      >
        {fileName}
      </Box>
    </>
  )
}

export default ImportButton
