import { Reducer, combineReducers } from 'redux'
import { SheetWheatSalesActionTypes } from './types'
import { SheetWheatSales } from 'interfaces/swimof/sheetWheatSales'

const initialState: SheetWheatSales = {
  wheatSales: []
}

const wheatSales: Reducer<SheetWheatSales['wheatSales']> = (
  state = initialState.wheatSales,
  { type, payload }
) => {
  switch (type) {
    case SheetWheatSalesActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetWheatSalesActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetWheatSalesReducer = combineReducers<SheetWheatSales>({
  wheatSales
})
