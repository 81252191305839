import { action } from 'typesafe-actions'
import { SheetIncomingWheatActionTypes } from './types'
import { IncomingWheat } from 'interfaces/swimof/sheetIncomingWheat'

export const createIncomingWheat = (incomingWheat: IncomingWheat) =>
  action(SheetIncomingWheatActionTypes.CREATE, incomingWheat)

export const importIncomingWheats = (incomingWheats: IncomingWheat[]) =>
  action(SheetIncomingWheatActionTypes.IMPORT_DATA, incomingWheats)

export const importReferenceWheats = (referenceWheats: string[]) =>
  action(SheetIncomingWheatActionTypes.IMPORT_REFERENCE, referenceWheats)
