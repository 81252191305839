import { Reducer, combineReducers } from 'redux'
import { SheetMinMaxConstraintsActionTypes } from './types'
import { SheetMinMaxConstraints } from 'interfaces/swimof/sheetMinMaxConstraints'

const initialState: SheetMinMaxConstraints = {
  minMaxFlours: []
}

const minMaxFlours: Reducer<SheetMinMaxConstraints['minMaxFlours']> = (
  state = initialState.minMaxFlours,
  { type, payload }
) => {
  switch (type) {
    case SheetMinMaxConstraintsActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetMinMaxConstraintsActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetMinMaxConstraintsReducer = combineReducers<
  SheetMinMaxConstraints
>({
  minMaxFlours
})
