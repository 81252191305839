import { Reducer, combineReducers } from 'redux'
import { SheetWheatCostActionTypes } from './types'
import { SheetWheatCost } from 'interfaces/wup/sheetWheatCost'

const initialState: SheetWheatCost = {
  wheatCosts: []
}

const wheatCosts: Reducer<SheetWheatCost['wheatCosts']> = (
  state = initialState.wheatCosts,
  { type, payload }
) => {
  switch (type) {
    case SheetWheatCostActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetWheatCostActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetWheatCostReducer = combineReducers<SheetWheatCost>({
  wheatCosts
})
