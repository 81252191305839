import React, { FC } from 'react'
import { Stepper as MaterialStepper, Step, StepLabel } from '@material-ui/core'
import _ from 'lodash'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'

const ModuleStepper: FC<RouteComponentProps> = ({ location }) => {
  const steps = ['master', 'swimof', 'recipe', 'wup']

  const isActive = (step: string) => {
    return step === location.pathname.replace('/', '')
  }

  const renderSteps = () => {
    return steps.map(step => (
      <Step key={step} active={isActive(step)}>
        <StepLabel active={isActive(step)}>{_.startCase(step)}</StepLabel>
      </Step>
    ))
  }

  return <Stepper>{renderSteps()}</Stepper>
}

const Stepper = styled(MaterialStepper)`
  background-color: transparent !important;
  margin-bottom: 15px;
`

export default withRouter(ModuleStepper)
