import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow as MaterialTableRow,
  TableCell,
  TableBody,
  colors
} from '@material-ui/core'
import { FieldArray, FormikProps, getIn } from 'formik'

import { NumberField } from 'components/formikWrapper'
import * as yup from 'yup'
import styled from 'styled-components'
import { PageProps } from '../Page.Container'

export const validationSchemaOpeningStockWheat = yup.object().shape({
  name: yup
    .string()
    .required()
    .label('name'),
  quantity: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('quantity')
})

export default class TabOpeningStocks extends Component {
  isRecordError = (
    index: number,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    return getIn(form.errors, `openingStockWheats.${index}`)
  }

  isRecordEven = (index: number) => {
    return (index + 1) % 2 === 0
  }

  renderData = (form: FormikProps<PageProps['initialValues']>) => {
    const { openingStockWheats } = form.values
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Wheats</TableCell>
              <TableCell variant="head">Quantity (MT)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {openingStockWheats.map((openingStockWheat, index) => (
              <TableRow
                key={index}
                isError={this.isRecordError(index, form)}
                isEven={this.isRecordEven(index)}
              >
                <TableCell variant="head">{openingStockWheat.name}</TableCell>
                <TableCell>
                  <NumberField
                    name={`openingStockWheats.${index}.quantity`}
                    label="quantity"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  render() {
    return (
      <FieldArray
        name="openingStockWheats"
        validateOnChange={false}
        render={({ form }) => (
          <Paper>
            <Box padding={3}>
              <Typography variant="h6">Opening Stocks</Typography>
              {this.renderData(form)}
            </Box>
          </Paper>
        )}
      />
    )
  }
}

const TableContainer = styled('div')({
  maxWidth: '100%',
  overflow: 'auto'
})

function getCellColor(isError?: boolean, isEven?: boolean) {
  return isError ? colors.red[50] : isEven ? colors.grey[50] : 'white'
}

interface TableRowProps {
  isError?: boolean
  isEven?: boolean
}

const TableRow = styled(MaterialTableRow)<TableRowProps>`
  background-color: ${({ isError, isEven }) => getCellColor(isError, isEven)};
`
