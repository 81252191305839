import { SheetInStockWheatSpecs } from 'interfaces/master/sheetInStockWheatSpecs'
import { Reducer, combineReducers } from 'redux'
import { SheetInStockWheatSpecsActionTypes } from './types'

const initialState: SheetInStockWheatSpecs = {
  wheats: []
}

const wheats: Reducer<SheetInStockWheatSpecs['wheats']> = (
  state = initialState.wheats,
  { type, payload }
) => {
  switch (type) {
    case SheetInStockWheatSpecsActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetInStockWheatSpecsActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetInStockWheatSpecsReducer = combineReducers<
  SheetInStockWheatSpecs
>({
  wheats
})
