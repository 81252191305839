import { Reducer, combineReducers } from 'redux'
import { SheetCostStructureActionTypes } from './types'
import { SheetCostStructure } from 'interfaces/wup/sheetCostStructure'

const initialState: SheetCostStructure = {
  costStructures: []
}

const costStructures: Reducer<SheetCostStructure['costStructures']> = (
  state = initialState.costStructures,
  { type, payload }
) => {
  switch (type) {
    case SheetCostStructureActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetCostStructureActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetCostStructureReducer = combineReducers<SheetCostStructure>({
  costStructures
})
