import React, { FC } from 'react'
import { connect } from 'react-redux'
import ExportButton from './ExportButton'
import { RootStore } from 'interfaces/stores'

interface propsFromState {
  downloadLink: string
}

export type ExportButtonProps = propsFromState

const ExportButtonContainer: FC<ExportButtonProps> = props => (
  <ExportButton {...props} />
)

const mapStateToProps = ({
  wup: { downloadLink }
}: RootStore): propsFromState => ({
  downloadLink
})

export default connect(mapStateToProps)(ExportButtonContainer)
