import { Reducer, combineReducers } from 'redux'
import { SheetWheatReplacementPricesActionTypes } from './types'
import { SheetWheatReplacementPrices } from 'interfaces/swimof/sheetWheatReplacementPrices'

const initialState: SheetWheatReplacementPrices = {
  wheatReplacementPrices: []
}

const wheatReplacementPrices: Reducer<SheetWheatReplacementPrices['wheatReplacementPrices']> = (
  state = initialState.wheatReplacementPrices,
  { type, payload }
) => {
  switch (type) {
    case SheetWheatReplacementPricesActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetWheatReplacementPricesActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetWheatReplacementPricesReducer = combineReducers<
  SheetWheatReplacementPrices
>({
  wheatReplacementPrices
})
