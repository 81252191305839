import React, { FC } from 'react'
import { connect } from 'react-redux'
import ExportButton from './ExportButton'
import { FormikProps } from 'formik'
import { importInStockWheatSpecs } from 'stores/master/sheetInStockWheatSpecs/actions'
import { importExpectedWheatSpecs } from 'stores/master/sheetExpectedWheatSpecs/actions'
import { importFlourSpecs } from 'stores/master/sheetFlourSpecs/actions'
import { importDate, importPlantSite } from 'stores/master/sheetDate/actions'
import { PageProps } from 'modules/master/Page.Container'

interface PropsFromDispatch {
  importInStockWheatSpecs: typeof importInStockWheatSpecs
  importExpectedWheatSpecs: typeof importExpectedWheatSpecs
  importFlourSpecs: typeof importFlourSpecs
  importDate: typeof importDate
  importPlantSite: typeof importPlantSite
}

export type ExportButtonProps = PropsFromDispatch & {
  form: FormikProps<PageProps['initialValues']>
}

const ExportButtonContainer: FC<ExportButtonProps> = props => (
  <ExportButton {...props} />
)

const mapDispatchToProps: PropsFromDispatch = {
  importInStockWheatSpecs,
  importExpectedWheatSpecs,
  importFlourSpecs,
  importDate,
  importPlantSite
}

export default connect(null, mapDispatchToProps)(ExportButtonContainer)
