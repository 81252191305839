import { Reducer, combineReducers } from 'redux'
import { SheetIdealActionTypes } from './types'
import { SheetIdeal } from 'interfaces/recipe/sheetIdeal'

const initialState: SheetIdeal = {
  ideals: []
}

const ideals: Reducer<SheetIdeal['ideals']> = (
  state = initialState.ideals,
  { type, payload }
) => {
  switch (type) {
    case SheetIdealActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetIdealActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetIdealReducer = combineReducers<SheetIdeal>({
  ideals
})
