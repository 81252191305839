import React, { FC } from 'react'
import { connect } from 'react-redux'
import { importInStockWheatSpecs } from 'stores/master/sheetInStockWheatSpecs/actions'
import { importExpectedWheatSpecs } from 'stores/master/sheetExpectedWheatSpecs/actions'
import { importFlourSpecs } from 'stores/master/sheetFlourSpecs/actions'
import { importDate, importPlantSite } from 'stores/master/sheetDate/actions'
import { importMinMaxConstraints } from 'stores/swimof/sheetMinMaxConstraints/actions'
import {
  importReferenceWheats,
  importIncomingWheats
} from 'stores/swimof/sheetIncomingWheat/actions'
import { importSalesForecasts } from 'stores/swimof/sheetSalesForecast/actions'
import { importOpeningStocks } from 'stores/swimof/sheetOpeningStocks/actions'
import { importWheatSales } from 'stores/swimof/sheetWheatSales/actions'
import { importWheatReplacementPrices } from 'stores/swimof/sheetWheatReplacementPrices/actions'
import { importFinancial } from 'stores/swimof/sheetFinancials/actions'
import { updateSwimofFileName } from 'stores/swimof/fileName/actions'

import { InStockWheatSpec } from 'interfaces/master/sheetInStockWheatSpecs'
import { ExpectedWheatSpec } from 'interfaces/master/sheetExpectedWheatSpecs'
import { FlourSpec } from 'interfaces/master/sheetFlourSpecs'
import Page from './Page'
import { RootStore } from 'interfaces/stores'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface PropsFromState {
  initialValues: {
    inStockWheatSpecs: InStockWheatSpec[]
    expectedWheatSpecs: ExpectedWheatSpec[]
    flourSpecs: FlourSpec[]
    date: string
    plantSite: string
  }
}

interface PropsFromDispatch {
  importInStockWheatSpecs: typeof importInStockWheatSpecs
  importExpectedWheatSpecs: typeof importExpectedWheatSpecs
  importFlourSpecs: typeof importFlourSpecs
  importDate: typeof importDate
  importPlantSite: typeof importPlantSite
  importMinMaxConstraints: typeof importMinMaxConstraints
  importReferenceWheats: typeof importReferenceWheats
  importIncomingWheats: typeof importIncomingWheats
  importSalesForecasts: typeof importSalesForecasts
  importOpeningStocks: typeof importOpeningStocks
  importWheatSales: typeof importWheatSales
  importFinancial: typeof importFinancial
  importWheatReplacementPrices: typeof importWheatReplacementPrices
  updateSwimofFileName: typeof updateSwimofFileName
}

export type PageProps = PropsFromState & PropsFromDispatch & RouteComponentProps

const PageContainer: FC<PageProps> = props => <Page {...props} />

const mapStateToProps = ({
  master: {
    sheetInStockWheatSpecs,
    sheetExpectedWheatSpecs,
    sheetFlourSpecs,
    sheetDate
  }
}: RootStore): PropsFromState => ({
  initialValues: {
    inStockWheatSpecs: sheetInStockWheatSpecs.wheats,
    expectedWheatSpecs: sheetExpectedWheatSpecs.wheats,
    flourSpecs: sheetFlourSpecs.flours,
    date: sheetDate.date,
    plantSite: sheetDate.plantSite
  }
})

const mapDispatchToProps: PropsFromDispatch = {
  importInStockWheatSpecs,
  importExpectedWheatSpecs,
  importFlourSpecs,
  importDate,
  importPlantSite,
  importMinMaxConstraints,
  importIncomingWheats,
  importReferenceWheats,
  importSalesForecasts,
  importOpeningStocks,
  importWheatSales,
  importFinancial,
  importWheatReplacementPrices,
  updateSwimofFileName
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageContainer)
)
