import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow as MaterialTableRow,
  TableCell as MaterialTableCell,
  TableBody,
  colors
} from '@material-ui/core'
import { FieldArray, FormikProps, getIn } from 'formik'

import { TextField } from 'components/formikWrapper'
import * as yup from 'yup'
import styled from 'styled-components'
import { PageProps } from '../Page.Container'

export const validationSchemaForecastFlour = yup.object().shape({
  name: yup
    .string()
    .required()
    .label('name'),
  forecastDates: yup.array(
    yup.object().shape({
      name: yup.string().label('name'),
      value: yup
        .number()
        .typeError('must be a number')
        .required()
        .label('value')
    })
  )
})

export default class TabSalesForecast extends Component {
  isRecordError = (
    index: number,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    return getIn(form.errors, `forecastFlours.${index}`)
  }

  isRecordEven = (index: number) => {
    return (index + 1) % 2 === 0
  }

  renderData = (form: FormikProps<PageProps['initialValues']>) => {
    const { forecastFlours } = form.values
    return (
      forecastFlours.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Flours / Dates</TableCell>
                {forecastFlours[0].forecastDates.map((forecastDate, index) => (
                  <TableCell key={index}>{forecastDate.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {forecastFlours.map((forecastFlour, indexFlour) => (
                <TableRow
                  key={indexFlour}
                  isError={this.isRecordError(indexFlour, form)}
                  isEven={this.isRecordEven(indexFlour)}
                >
                  <TableCell variant="head">{forecastFlour.name}</TableCell>
                  {forecastFlours[0].forecastDates.map(
                    (forecastDate, indexDate) => (
                      <TableCell key={indexDate}>
                        <TextField
                          name={`forecastFlours.${indexFlour}.forecastDates.${indexDate}.value`}
                          label="Value"
                        />
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    )
  }

  render() {
    return (
      <FieldArray
        name="forecastFlours"
        validateOnChange={false}
        render={({ form }) => (
          <Paper>
            <Box padding={3}>
              <Typography variant="h6">Sales Forecast</Typography>
              {this.renderData(form)}
            </Box>
          </Paper>
        )}
      />
    )
  }
}

const TableContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
`

function getCellColor(isError?: boolean, isEven?: boolean) {
  return isError ? colors.red[50] : isEven ? colors.grey[50] : 'white'
}

interface TableRowProps {
  isError?: boolean
  isEven?: boolean
}

const TableRow = styled(MaterialTableRow)<TableRowProps>`
  background-color: ${({ isError, isEven }) => getCellColor(isError, isEven)};
`

const TableCell = styled(MaterialTableCell)`
  white-space: nowrap;
  min-width: 200px;
`
