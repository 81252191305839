import React, { useContext } from 'react'
import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody as MaterialTableBody,
  colors
} from '@material-ui/core'
import { FieldArray, FormikProps, getIn } from 'formik'
import { FlourSpec, FlourUsage } from 'interfaces/master/sheetFlourSpecs'
import { TextField, Select } from 'components/formikWrapper'
import { confirmContext } from 'components/confirm'
import * as yup from 'yup'
import styled from 'styled-components'
import _ from 'lodash'
import { PageProps } from '../Page.Container'

const initialValues: FlourSpec = {
  name: '',
  productId: '',
  usage: FlourUsage.BISCUIT,
  minProtein: 0,
  maxProtein: 0,
  minWetGluten: 0,
  maxWetGluten: 0,
  minWaterAbsorption: 0,
  maxWaterAbsorption: 0,
  minFallingNumber: 0,
  maxFallingNumber: 0,
  minSourceWater: 0,
  maxSourceWater: 0,
  minSourceSucrose: 0,
  maxSourceSucrose: 0,
  minSourceSodiumCarbonate: 0,
  maxSourceSodiumCarbonate: 0,
  minSourceLacticAcid: 0,
  maxSourceLacticAcid: 0
}

const getOptionalValidation = (schema: yup.Schema<number>) => {
  return yup.lazy(value => (value === '-' ? yup.mixed().notRequired() : schema))
}

export const validationSchemaFlourSpec = yup.object().shape({
  name: yup
    .string()
    .required()
    .label('name'),
  productId: yup.string().label('product id'),
  usage: yup.string().required(),
  minProtein: getOptionalValidation(
    yup
      .number()
      .typeError('must be a number')
      .min(0)
      .max(100)
      .required()
      .label('min protein')
  ),
  maxProtein: getOptionalValidation(
    yup
      .number()
      .typeError('must be a number')
      .min(0)
      .max(100)
      .required()
      .label('max protein')
  ),
  minWetGluten: getOptionalValidation(
    yup
      .number()
      .typeError('must be a number')
      .min(0)
      .max(100)
      .required()
      .label('min wet gluten')
  ),
  maxWetGluten: getOptionalValidation(
    yup
      .number()
      .typeError('must be a number')
      .min(0)
      .max(100)
      .required()
      .label('max wet gluten')
  ),
  minWaterAbsorption: getOptionalValidation(
    yup
      .number()
      .typeError('must be a number')
      .min(0)
      .max(100)
      .required()
      .label('min water absorption')
  ),
  maxWaterAbsorption: getOptionalValidation(
    yup
      .number()
      .typeError('must be a number')
      .min(0)
      .max(100)
      .required()
      .label('max water absorption')
  ),
  minFallingNumber: getOptionalValidation(
    yup
      .number()
      .moreThan(-1)
      .typeError('must be a number')
      .required()
      .label('min falling number')
  ),
  minSourceWater: getOptionalValidation(
    yup
      .number()
      .moreThan(-1)
      .typeError('must be a number')
      .required()
      .label('min source water')
  ),
  maxSourceWater: getOptionalValidation(
    yup
      .number()
      .moreThan(-1)
      .typeError('must be a number')
      .required()
      .label('max source water')
  ),
  minSourceSucrose: getOptionalValidation(
    yup
      .number()
      .moreThan(-1)
      .typeError('must be a number')
      .required()
      .label('min source sucrose')
  ),
  maxSourceSucrose: getOptionalValidation(
    yup
      .number()
      .moreThan(-1)
      .typeError('must be a number')
      .required()
      .label('max source sucrose')
  ),
  minSourceSodiumCarbonate: getOptionalValidation(
    yup
      .number()
      .moreThan(-1)
      .typeError('must be a number')
      .required()
      .label('min source sodium carbonate')
  ),
  maxSourceSodiumCarbonate: getOptionalValidation(
    yup
      .number()
      .moreThan(-1)
      .typeError('must be a number')
      .required()
      .label('max source sodium carbonate')
  ),
  minSourceLacticAcid: getOptionalValidation(
    yup
      .number()
      .moreThan(-1)
      .typeError('must be a number')
      .required()
      .label('min source lactic acid')
  ),
  maxSourceLacticAcid: getOptionalValidation(
    yup
      .number()
      .moreThan(-1)
      .typeError('must be a number')
      .required()
      .label('max source lactic acid')
  )
})

const TabInStockWheatSpecs = () => {
  const { handleOpen } = useContext(confirmContext)

  const isRecordError = (
    index: number,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    return getIn(form.errors, `flourSpecs.${index}`)
  }

  const isRecordEven = (index: number) => {
    return (index + 1) % 2 === 0
  }

  const renderInput = (index: number, key: keyof FlourSpec) => {
    switch (key) {
      case 'usage': {
        return (
          <Select
            name={`flourSpecs.${index}.${key}`}
            label={_.startCase(key)}
            options={[
              { label: 'Biscuit', value: FlourUsage.BISCUIT },
              { label: 'Wafer', value: FlourUsage.WAFER },
              { label: 'Noodle', value: FlourUsage.NOODLE },
              { label: 'Udon', value: FlourUsage.UDON },
              { label: 'Bread', value: FlourUsage.BREAD },
              { label: 'Bun', value: FlourUsage.BUN },
              { label: 'Pao', value: FlourUsage.PAO },
              {
                label: 'General Purpose',
                value: FlourUsage.GENERAL_PURPOSE
              },
              {
                label: 'Fighting Brand',
                value: FlourUsage.FIGHTING_BRAND
              },
              { label: 'Feed', value: FlourUsage.FEED },
              { label: 'Other', value: FlourUsage.OTHER }
            ]}
          />
        )
      }
      default: {
        return (
          <TextField
            name={`flourSpecs.${index}.${key}`}
            label={_.startCase(key)}
          />
        )
      }
    }
  }

  const renderCells = (
    key: keyof FlourSpec,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    const { flourSpecs } = form.values
    return flourSpecs.map((flourSpec, index) => (
      <TableCell
        key={index}
        isError={isRecordError(index, form)}
        isEven={isRecordEven(index)}
      >
        {renderInput(index, key)}
      </TableCell>
    ))
  }

  const renderEmpty = () => {
    return (
      <Box
        height="200px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="subtitle1">
          There's no data yet, please create new one or import from *.xlsx file
        </Typography>
      </Box>
    )
  }

  const renderData = (
    form: FormikProps<PageProps['initialValues']>,
    remove: (index: number) => void
  ) => {
    const { flourSpecs } = form.values
    return flourSpecs.length ? (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Specification</TableCell>
              {flourSpecs.map((flourSpec, index) => (
                <TableCell key={index}>
                  <Grid container justify="space-between" alignItems="center">
                    {`Flour ${index + 1}`}
                    <Button
                      color="secondary"
                      onClick={() =>
                        handleOpen(
                          'Remove Flour',
                          'Are you sure want to remove flour ?',
                          () => remove(index)
                        )
                      }
                    >
                      Remove
                    </Button>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Name</TableCell>
              {renderCells('name', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">Product ID</TableCell>
              {renderCells('productId', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">Usage</TableCell>
              {renderCells('usage', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Min Protein</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('minProtein', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Max Protein</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('maxProtein', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Min Wet Gluten</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('minWetGluten', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Max Wet Gluten</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('maxWetGluten', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Min Water Absorption</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('minWaterAbsorption', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Max Water Absorption</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('maxWaterAbsorption', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Min Falling Number</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('minFallingNumber', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Max Falling Number</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('maxFallingNumber', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Min Src Water</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('minSourceWater', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Max Src Water</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('maxSourceWater', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Min Src Sucrose</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('minSourceSucrose', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Max Src Sucrose</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('maxSourceSucrose', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Min Src Sodium Carbonate</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('minSourceSodiumCarbonate', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Max Src Sodium Carbonate</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('maxSourceSodiumCarbonate', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Min Src Lactic Acid</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('minSourceLacticAcid', form)}
            </TableRow>

            <TableRow>
              <TableCell variant="head">
                <Grid container direction="column">
                  <Grid item>Max Src Lactic Acid</Grid>
                  <Grid item>(%, 14 mb)</Grid>
                </Grid>
              </TableCell>
              {renderCells('maxSourceLacticAcid', form)}
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      renderEmpty()
    )
  }

  return (
    <FieldArray
      name="flourSpecs"
      validateOnChange={false}
      render={({ form, push, remove }) => (
        <Paper>
          <Box padding={3}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h6">Flour Specs</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => push(initialValues)}
                >
                  Add Flour
                </Button>
              </Grid>
            </Grid>

            {renderData(form, remove)}
          </Box>
        </Paper>
      )}
    />
  )
}

export default TabInStockWheatSpecs

const TableContainer = styled('div')`
  max-width: 100%;
  max-height: calc(100vh - 245px);
  overflow: auto;
  position: relative;
`

const tableCellHeight = 80

function getCellColor(isError?: boolean, isEven?: boolean) {
  return isError ? colors.red[50] : isEven ? colors.grey[50] : 'white'
}

interface TableCellProps {
  isError?: boolean
  isEven?: boolean
}

const TableCell = styled(MaterialTableCell)<TableCellProps>`
  white-space: nowrap;
  min-width: 200px;
  height: ${tableCellHeight}px;
  background-color: ${({ isError, isEven }) => getCellColor(isError, isEven)};
`

const TableBody = styled(MaterialTableBody)`
  & tr:nth-child(1) td,
  tr:nth-child(2) td {
    position: sticky;
    z-index: 2;
  }

  & tr:nth-child(1) td {
    top: 0px;
  }

  & tr:nth-child(2) td {
    top: ${tableCellHeight}px;
  }
`
