import { combineReducers } from 'redux'
import Master from 'interfaces/master'
import { sheetInStockWheatSpecsReducer } from './sheetInStockWheatSpecs/reducers'
import { sheetExpectedWheatSpecsReducer } from './sheetExpectedWheatSpecs/reducers'
import { sheetFlourSpecsReducer } from './sheetFlourSpecs/reducers'
import { sheetDateReducer } from './sheetDate/reducers'
import { fileNameReducer } from './fileName/reducers'

export const masterReducers = combineReducers<Master>({
  fileName: fileNameReducer,
  sheetInStockWheatSpecs: sheetInStockWheatSpecsReducer,
  sheetExpectedWheatSpecs: sheetExpectedWheatSpecsReducer,
  sheetFlourSpecs: sheetFlourSpecsReducer,
  sheetDate: sheetDateReducer
})
