import { SheetFlourSpecs } from 'interfaces/master/sheetFlourSpecs'
import { Reducer, combineReducers } from 'redux'
import { SheetFlourSpecsActionTypes } from './types'

const initialState: SheetFlourSpecs = {
  flours: []
}

const flours: Reducer<SheetFlourSpecs['flours']> = (
  state = initialState.flours,
  { type, payload }
) => {
  switch (type) {
    case SheetFlourSpecsActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetFlourSpecsActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetFlourSpecsReducer = combineReducers<SheetFlourSpecs>({
  flours
})
