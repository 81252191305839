import { combineReducers } from "redux";
import Recipe from "interfaces/recipe";
import { sheetConstrainedReducer } from "./sheetConstrained/reducers";
import { sheetIdealReducer } from "./sheetIdeal/reducers";
import { fileNameReducer } from "./fileName/reducers";

export const recipeReducers = combineReducers<Recipe>({
  fileName: fileNameReducer,
  sheetConstrained: sheetConstrainedReducer,
  sheetIdeal: sheetIdealReducer,
});
