import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { ExportButtonProps } from './ExportButton.Container'
import { RecipeExporter } from 'utils/excel'

const ExportButton: FC<ExportButtonProps> = ({
  importConstraints,
  importIdeals,
  form
}) => {
  const handleClick = () => {
    form.validateForm().then(() => {
      if (form.isValid) {
        const { constraints, ideals } = form.values
        importConstraints(constraints)
        importIdeals(ideals)
        new RecipeExporter(constraints, ideals).download()
      }
    })
  }

  return (
    <Button variant="outlined" color="primary" onClick={handleClick}>
      Export
    </Button>
  )
}

export default ExportButton
