import React, { FC } from 'react'
import { connect } from 'react-redux'
import Page from './Page'
import { RootStore } from 'interfaces/stores'
import { importConstraints } from 'stores/recipe/sheetConstrained/actions'
import { importIdeals } from 'stores/recipe/sheetIdeal/actions'
import { FlourSpec } from 'interfaces/master/sheetFlourSpecs'
import { InStockWheatSpec } from 'interfaces/master/sheetInStockWheatSpecs'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Recipe } from 'interfaces/generic/recipe'
import { ExpectedWheatSpec } from 'interfaces/master/sheetExpectedWheatSpecs'

interface PropsFromState {
  initialValues: {
    constraints: Recipe[]
    ideals: Recipe[]
  }
  inStockWheatSpecs: InStockWheatSpec[]
  expectedWheatSpecs: ExpectedWheatSpec[]
  flourSpecs: FlourSpec[]
}

interface PropsFromDispatch {
  importConstraints: typeof importConstraints
  importIdeals: typeof importIdeals
}

export type PageProps = PropsFromState & PropsFromDispatch & RouteComponentProps

const PageContainer: FC<PageProps> = props => <Page {...props} />

const mapStateToProps = ({
  master: { sheetInStockWheatSpecs, sheetExpectedWheatSpecs, sheetFlourSpecs },
  recipe: { sheetConstrained, sheetIdeal }
}: RootStore): PropsFromState => ({
  initialValues: {
    constraints: sheetConstrained.constaints,
    ideals: sheetIdeal.ideals
  },
  inStockWheatSpecs: sheetInStockWheatSpecs.wheats,
  expectedWheatSpecs: sheetExpectedWheatSpecs.wheats,
  flourSpecs: sheetFlourSpecs.flours
})

const mapDispatchToProps: PropsFromDispatch = {
  importConstraints,
  importIdeals
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageContainer)
)
