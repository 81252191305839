import cookie from 'js-cookie'

interface User {
  username: string
  role: string
}

const key = 'cassava_user'

export function isLoggedIn() {
  return cookie.get(key) !== undefined
}

export function login(user: User) {
  cookie.set(key, JSON.stringify(user))
}

export function logout() {
  cookie.remove(key)
}

export function getUser(): User {
  return JSON.parse(cookie.get(key) || '')
}
