import { Reducer, combineReducers } from 'redux'
import { SheetIdealRecipeActionTypes } from './types'
import { SheetIdealRecipe } from 'interfaces/wup/sheetIdealRecipe'

const initialState: SheetIdealRecipe = {
  ideals: []
}

const ideals: Reducer<SheetIdealRecipe['ideals']> = (
  state = initialState.ideals,
  { type, payload }
) => {
  switch (type) {
    case SheetIdealRecipeActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetIdealRecipeActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetIdealRecipeReducer = combineReducers<SheetIdealRecipe>({
  ideals
})
