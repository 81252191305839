import React from 'react'

interface TabPanelProps {
  index: number
  activeTab: number
  children?: React.ReactNode
}

const TabPanel: React.FC<TabPanelProps> = ({ index, activeTab, children }) =>
  index === activeTab ? <>{children}</> : null

export default TabPanel
