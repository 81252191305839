import React, { FC, useContext } from 'react'
import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableHead,
  TableRow as MaterialTableRow,
  TableCell as MaterialTableCell,
  TableBody,
  colors
} from '@material-ui/core'
import { FieldArray, FormikProps, getIn } from 'formik'

import { TextField, Select, NumberField } from 'components/formikWrapper'
import * as yup from 'yup'
import styled from 'styled-components'
import { IncomingWheat } from 'interfaces/swimof/sheetIncomingWheat'
import { confirmContext } from 'components/confirm'
import { DateField } from 'components/formikWrapper'
import { PageProps } from '../Page.Container'

const initialValues: IncomingWheat = {
  name: '',
  quantity: 0,
  price: 0,
  eta: '',
  vessel: '',
  remark: ''
}

export const validationSchemaIncomingWheat = yup.object().shape({
  name: yup
    .string()
    .required()
    .label('name'),
  quantity: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('quantity'),
  price: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('price'),
  eta: yup
    .string()
    .required()
    .label('eta'),
  vessel: yup
    .string()
    .required()
    .label('vessel'),
  remark: yup
    .string()
    .required()
    .label('remark')
})

export const validationSchemaReferenceWheat = yup
  .string()
  .required()
  .label('reference wheat')

interface TabIncomingWheatProps {
  referenceWheats: string[]
}

const TabIncomingWheat: FC<TabIncomingWheatProps> = ({ referenceWheats }) => {
  const { handleOpen } = useContext(confirmContext)

  const renderEmpty = () => {
    return (
      <Box
        height="200px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="subtitle1">
          There's no data yet, please create new one or import from *.xlsx file
        </Typography>
      </Box>
    )
  }

  const isRecordError = (
    index: number,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    return getIn(form.errors, `incomingWheats.${index}`)
  }

  const isRecordEven = (index: number) => {
    return (index + 1) % 2 === 0
  }

  const renderData = (
    form: FormikProps<PageProps['initialValues']>,
    remove: (index: number) => void
  ) => {
    const { incomingWheats } = form.values
    return incomingWheats.length ? (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell variant="head">Wheats IDs</TableCell>
              <TableCell variant="head">Quantity</TableCell>
              <TableCell variant="head">Price</TableCell>
              <TableCell variant="head">ETA</TableCell>
              <TableCell variant="head">Vessel</TableCell>
              <TableCell variant="head">Remark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incomingWheats.map((incomingWheat, index) => (
              <TableRow
                key={index}
                isError={isRecordError(index, form)}
                isEven={isRecordEven(index)}
              >
                <TableCell>
                  <Button
                    color="secondary"
                    onClick={() =>
                      handleOpen(
                        'Remove Wheat',
                        'Are you sure want to remove wheat ?',
                        () => remove(index)
                      )
                    }
                  >
                    remove
                  </Button>
                </TableCell>
                <TableCell variant="head">
                  <Select
                    name={`incomingWheats.${index}.name`}
                    label="Name"
                    options={referenceWheats.map(referenceWheat => ({
                      label: referenceWheat,
                      value: referenceWheat
                    }))}
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    name={`incomingWheats.${index}.quantity`}
                    label="Quantity"
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    name={`incomingWheats.${index}.price`}
                    label="Price"
                  />
                </TableCell>
                <TableCell>
                  <DateField name={`incomingWheats.${index}.eta`} label="ETA" />
                </TableCell>
                <TableCell>
                  <TextField
                    name={`incomingWheats.${index}.vessel`}
                    label="Vessel"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={`incomingWheats.${index}.remark`}
                    label="Remark"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      renderEmpty()
    )
  }

  return (
    <Paper>
      <Box padding={3}>
        <FieldArray
          name="incomingWheats"
          validateOnChange={false}
          render={({ form, push, remove }) => (
            <>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h6">Incoming Wheat</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => push(initialValues)}
                  >
                    Add Wheat
                  </Button>
                </Grid>
              </Grid>
              {renderData(form, remove)}
            </>
          )}
        />
      </Box>
    </Paper>
  )
}

export default TabIncomingWheat

const TableContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
`

function getCellColor(isError?: boolean, isEven?: boolean) {
  return isError ? colors.red[50] : isEven ? colors.grey[50] : 'white'
}

interface TableRowProps {
  isError?: boolean
  isEven?: boolean
}

const TableRow = styled(MaterialTableRow)<TableRowProps>`
  background-color: ${({ isError, isEven }) => getCellColor(isError, isEven)};
`

const TableCell = styled(MaterialTableCell)`
  white-space: nowrap;
  min-width: 250px;
`
