import React from 'react'
import { FieldProps, FormikProps, ErrorMessage, FastField } from 'formik'
import {
  TextField as MaterialTextField,
  FormControl,
  FormHelperText
} from '@material-ui/core'

interface TextFieldProps {
  name: string
  label: string
  type?: string
}

export const TextField: React.FC<TextFieldProps> = ({ name, label, type }) => {
  function isError(form: FormikProps<any>) {
    return form.errors[name] !== undefined
  }

  return (
    <FastField name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl error={isError(form)}>
          <MaterialTextField
            {...field}
            label={label}
            type={type}
            error={isError(form)}
            size="small"
          />
          <ErrorMessage
            name={name}
            render={message => <FormHelperText error>{message}</FormHelperText>}
          />
        </FormControl>
      )}
    </FastField>
  )
}
