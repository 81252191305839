import { Reducer } from 'redux'
import { MonthActionTypes } from './types'

const initialState: number = 1

export const monthReducers: Reducer<number> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case MonthActionTypes.IMPORT_DATA: {
      return payload
    }
    default: {
      return state
    }
  }
}
