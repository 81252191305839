import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow as MaterialTableRow,
  TableCell as MaterialTableCell,
  TableBody,
  colors
} from '@material-ui/core'
import { FieldArray, FormikProps, getIn } from 'formik'

import { TextField } from 'components/formikWrapper'
import * as yup from 'yup'
import styled from 'styled-components'
import { PageProps } from '../Page.Container'

export const validationSchemaWheatReplacementPrice = yup.object().shape({
  name: yup
    .string()
    .required()
    .label('name'),
  dateReplacementPrices: yup.array(
    yup.object().shape({
      name: yup.string().label('name'),
      value: yup
        .number()
        .typeError('must be a number')
        .required()
        .label('value')
    })
  )
})

export default class TabWheatReplacementPrices extends Component {
  isRecordError = (
    index: number,
    form: FormikProps<PageProps['initialValues']>
  ) => {
    return getIn(form.errors, `wheatReplacementPrices.${index}`)
  }

  isRecordEven = (index: number) => {
    return (index + 1) % 2 === 0
  }

  renderData = (form: FormikProps<PageProps['initialValues']>) => {
    const { wheatReplacementPrices } = form.values
    return (
      wheatReplacementPrices.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Wheat Types / Dates</TableCell>
                {wheatReplacementPrices[0].dateReplacementPrices.map(
                  (dateReplacementPrice, index) => (
                    <TableCell key={index}>
                      {dateReplacementPrice.name}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {wheatReplacementPrices.map(
                (wheatReplacementPrice, indexWheat) => (
                  <TableRow
                    key={indexWheat}
                    isError={this.isRecordError(indexWheat, form)}
                    isEven={this.isRecordEven(indexWheat)}
                  >
                    <TableCell variant="head">
                      {wheatReplacementPrice.name}
                    </TableCell>
                    {wheatReplacementPrices[0].dateReplacementPrices.map(
                      (dateReplacementPrice, indexDate) => (
                        <TableCell key={indexDate}>
                          <TextField
                            name={`wheatReplacementPrices.${indexWheat}.dateReplacementPrices.${indexDate}.value`}
                            label="Value"
                          />
                        </TableCell>
                      )
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )
    )
  }

  render() {
    return (
      <FieldArray
        name="wheatReplacementPrices"
        validateOnChange={false}
        render={({ form }) => (
          <Paper>
            <Box padding={3}>
              <Typography variant="h6">Wheat Replacement Prices</Typography>
              {this.renderData(form)}
            </Box>
          </Paper>
        )}
      />
    )
  }
}

const TableContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
`

function getCellColor(isError?: boolean, isEven?: boolean) {
  return isError ? colors.red[50] : isEven ? colors.grey[50] : 'white'
}

interface TableRowProps {
  isError?: boolean
  isEven?: boolean
}

const TableRow = styled(MaterialTableRow)<TableRowProps>`
  background-color: ${({ isError, isEven }) => getCellColor(isError, isEven)};
`

const TableCell = styled(MaterialTableCell)`
  white-space: nowrap;
  min-width: 200px;
`
