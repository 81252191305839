import { Reducer, combineReducers } from 'redux'
import { SheetConstrainedActionTypes } from './types'
import { SheetConstrained } from 'interfaces/recipe/sheetConstrained'

const initialState: SheetConstrained = {
  constaints: []
}

const constaints: Reducer<SheetConstrained['constaints']> = (
  state = initialState.constaints,
  { type, payload }
) => {
  switch (type) {
    case SheetConstrainedActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetConstrainedActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetConstrainedReducer = combineReducers<SheetConstrained>({
  constaints
})
