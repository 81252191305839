import React, { Component } from 'react'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody
} from '@material-ui/core'
import { TextField, NumberField } from 'components/formikWrapper'
import * as yup from 'yup'
import { styled } from '@material-ui/styles'

export const validationSchemaFinancial = yup.object().shape({
  exchangeRate: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('exchange rate'),
  packagingMaterials: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('packaging materials'),
  flourAdditives: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('flour additives'),
  peopleCost: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('people cost'),
  electricity: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('electricity'),
  maintenance: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('maintenance'),
  othersAndFacilities: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('others and facilities'),
  operatingSupplies: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('operating supplies'),
  factoryDepreciation: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('factory depreciation'),
  hqDepreciation: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('hq depreciation'),
  vat: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('quantity'),
  salesAndAdmin: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('sales and admin'),
  logistics: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('logistics'),
  amortization: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('amortization'),
  financeCost: yup
    .number()
    .typeError('must be a number')
    .required()
    .label('finance cost')
})

export default class TabFinancials extends Component {
  renderData = () => {
    return (
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Exchange Rate IDR/USD</TableCell>
              <TableCell>
                <NumberField
                  name="financial.exchangeRate"
                  label="Exchange Rate IDR/USD"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Cost</TableCell>
              <TableCell variant="head">IDR/Bag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Packaging Materials</TableCell>
              <TableCell>
                <TextField
                  name="financial.packagingMaterials"
                  label="Packaging Materials"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Flour Additives</TableCell>
              <TableCell>
                <TextField
                  name="financial.flourAdditives"
                  label="Flour Additives"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">People Cost</TableCell>
              <TableCell>
                <TextField name="financial.peopleCost" label="People Cost" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Electricity</TableCell>
              <TableCell>
                <TextField name="financial.electricity" label="Electricity" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Maintenance</TableCell>
              <TableCell>
                <TextField name="financial.maintenance" label="Maintenance" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Others & Facilities</TableCell>
              <TableCell>
                <TextField
                  name="financial.othersAndFacilities"
                  label="Others & Facilities"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Operating Supplies</TableCell>
              <TableCell>
                <TextField
                  name="financial.operatingSupplies"
                  label="Operating Supplies"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Factory Depreciation</TableCell>
              <TableCell>
                <TextField
                  name="financial.factoryDepreciation"
                  label="Factory Depreciation"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">HQ Depreciation</TableCell>
              <TableCell>
                <TextField
                  name="financial.hqDepreciation"
                  label="HQ Depreciation"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">VAT</TableCell>
              <TableCell>
                <TextField name="financial.vat" label="VAT" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Sales & Admin (Others)</TableCell>
              <TableCell>
                <TextField
                  name="financial.salesAndAdmin"
                  label="Sales & Admin (Others)"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Logistics</TableCell>
              <TableCell>
                <TextField name="financial.logistics" label="Logistics" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Amortization</TableCell>
              <TableCell>
                <TextField name="financial.amortization" label="Amortization" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Finance Cost</TableCell>
              <TableCell>
                <TextField name="financial.financeCost" label="Finance Cost" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  render() {
    return (
      <Paper>
        <Box padding={3}>
          <Typography variant="h6">Financials</Typography>
          {this.renderData()}
        </Box>
      </Paper>
    )
  }
}

const TableContainer = styled('div')({
  maxWidth: '100%',
  overflow: 'auto'
})

const TableCell = styled(MaterialTableCell)({
  whiteSpace: 'nowrap',
  minWidth: '200px'
})
