import Excel from 'exceljs'
import { FlourSpec } from 'interfaces/master/sheetFlourSpecs'
import { ExpectedWheatSpec } from 'interfaces/master/sheetExpectedWheatSpecs'
import { InStockWheatSpec } from 'interfaces/master/sheetInStockWheatSpecs'

export class MasterImporter {
  private workbook = new Excel.Workbook()

  constructor(workbook: Excel.Workbook) {
    this.workbook = workbook
  }

  private getData = <T>(worksheetName: string, keys: (keyof T)[]) => {
    const data: T[] = []
    this.workbook
      .getWorksheet(worksheetName)
      .columns.forEach((column, index) => {
        const values = column.values
        if (index && values && values.length) {
          const item: T = {} as T
          keys.forEach((key, index) => {
            item[key] = values[index + 2] as any
          })
          data.push(item)
        }
      })
    return data
  }

  getFlourSpecs = () => {
    return this.getData<FlourSpec>('flour_specs', [
      'name',
      'productId',
      'usage',
      'minProtein',
      'maxProtein',
      'minWetGluten',
      'maxWetGluten',
      'minWaterAbsorption',
      'maxWaterAbsorption',
      'minFallingNumber',
      'maxFallingNumber',
      'minSourceWater',
      'maxSourceWater',
      'minSourceSucrose',
      'maxSourceSucrose',
      'minSourceSodiumCarbonate',
      'maxSourceSodiumCarbonate',
      'minSourceLacticAcid',
      'maxSourceLacticAcid'
    ])
  }

  getExpectedWheatSpecs = () => {
    return this.getData<ExpectedWheatSpec>('expected_wheat_specs', [
      'typeName',
      'origin',
      'testWeight',
      'screening',
      'moisture',
      'millingExtractionRate',
      'hardness',
      'colorType',
      'flourProtein',
      'flourWetGluten',
      'flourFallingNumber',
      'waterAbsorption',
      'doughDevelopmentTime',
      'stabilityTime',
      'water',
      'sucrose',
      'sodiumCarbonate',
      'lacticAcid',
      'spreadRatio',
      'maxGelatinisation',
      'targetMoisture',
      'millingInvisibleLoss',
      'byProductPrice'
    ])
  }

  getInStockWheatSpecs = () => {
    return this.getData<InStockWheatSpec>('in_stock_wheat_specs', [
      'code',
      'arrivalDate',
      'typeName',
      'origin',
      'rawPrice',
      'testWeight',
      'screening',
      'moisture',
      'millingExtractionRate',
      'hardness',
      'colorType',
      'flourProtein',
      'flourWetGluten',
      'flourFallingNumber',
      'waterAbsorption',
      'doughDevelopmentTime',
      'stabilityTime',
      'water',
      'sucrose',
      'sodiumCarbonate',
      'lacticAcid',
      'spreadRatio',
      'maxGelatinisation',
      'targetMoisture',
      'millingInvisibleLoss',
      'byProductPrice'
    ])
  }

  getDate = () => {
    return this.workbook.getWorksheet('date').getCell('B1').value as string
  }

  getPlantSite = () => {
    return this.workbook.getWorksheet('date').getCell('B2').value as string
  }
}
