import { Workbook, Style, Worksheet } from 'exceljs'
import { saveAs } from 'file-saver'
import { Recipe, RecipeWheat, RecipeFlour } from 'interfaces/generic/recipe'

export class RecipeExporter {
  private workbook: Workbook
  private constraints: Recipe[]
  private ideals: Recipe[]

  constructor(constraints: Recipe[], ideals: Recipe[]) {
    this.workbook = new Workbook()
    this.constraints = constraints
    this.ideals = ideals

    this.addWorksheet()
    this.createConstraints()
    this.createIdeals()
  }

  private addWorksheet() {
    this.workbook.addWorksheet('constrained')
    this.workbook.addWorksheet('ideal')
  }

  private createConstraints = () => {
    this.createRecipes('constrained', this.constraints)
  }

  private createIdeals = () => {
    this.createRecipes('ideal', this.ideals)
  }

  private createRecipes = (worksheetName: string, recipes: Recipe[]) => {
    const worksheet = this.workbook.getWorksheet(worksheetName)
    recipes.forEach(recipe => {
      this.createTableHeader(worksheet, recipe)
      this.createTableBody(worksheet, recipe)
      worksheet.addRow([])
    })
    worksheet.columns.forEach(column => {
      column.width = 20
    })
  }

  private createTableHeader = (worksheet: Worksheet, recipe: Recipe) => {
    const values: string[] = []
    values.push(recipe.date)
    recipe.recipeFlours[0].recipeWheats.forEach((recipeWheat: RecipeWheat) =>
      values.push(recipeWheat.name)
    )
    values.push('protein')
    values.push('wet_gluten')
    values.push('water_absorption')

    worksheet
      .addRow(values)
      .eachCell(cell => (cell.style = this.getHeaderCellStyle()))
  }

  private createTableBody = (worksheet: Worksheet, recipe: Recipe) => {
    recipe.recipeFlours.forEach((recipeFlour: RecipeFlour) => {
      const values: any[] = []
      values.push(recipeFlour.name)
      recipeFlour.recipeWheats.forEach((recipeWheat: RecipeWheat) =>
        values.push(parseFloat(recipeWheat.value.toString()))
      )
      values.push(recipeFlour.protein)
      values.push(recipeFlour.wetGluten)
      values.push(recipeFlour.waterAbsorption)
      worksheet.addRow(values).getCell(1).style = this.getHeaderCellStyle()
    })
  }

  private getHeaderCellStyle = (): Partial<Style> => {
    return {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCCCCCC' }
      },
      font: {
        bold: true
      }
    }
  }

  download = async () => {
    const blobPart = await this.workbook.xlsx.writeBuffer()
    const blob = new Blob([blobPart])
    saveAs(blob, 'recipe.xlsx')
  }

  getBuffer = () => {
    return this.workbook.xlsx.writeBuffer()
  }
}
