import { SheetExpectedWheatSpecs } from 'interfaces/master/sheetExpectedWheatSpecs'
import { Reducer, combineReducers } from 'redux'
import { SheetExpectedWheatSpecsActionTypes } from './types'

const initialState: SheetExpectedWheatSpecs = {
  wheats: []
}

const wheats: Reducer<SheetExpectedWheatSpecs['wheats']> = (
  state = initialState.wheats,
  { type, payload }
) => {
  switch (type) {
    case SheetExpectedWheatSpecsActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetExpectedWheatSpecsActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetExpectedWheatSpecsReducer = combineReducers<
  SheetExpectedWheatSpecs
>({
  wheats
})
