import { Reducer, combineReducers } from 'redux'
import { SheetIncomingWheatActionTypes } from './types'
import { SheetIncomingWheat } from 'interfaces/swimof/sheetIncomingWheat'

const initialState: SheetIncomingWheat = {
  incomingWheats: [],
  referenceWheats: []
}

const incomingWheats: Reducer<SheetIncomingWheat['incomingWheats']> = (
  state = initialState.incomingWheats,
  { type, payload }
) => {
  switch (type) {
    case SheetIncomingWheatActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetIncomingWheatActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

const referenceWheats: Reducer<SheetIncomingWheat['referenceWheats']> = (
  state = initialState.referenceWheats,
  { type, payload }
) => {
  switch (type) {
    case SheetIncomingWheatActionTypes.IMPORT_REFERENCE: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetIncomingWheatReducer = combineReducers<SheetIncomingWheat>({
  incomingWheats,
  referenceWheats
})
