import React, { Component } from 'react'
import { Provider, MessageContext } from './MessageContext'

interface MessageContainerState {
  title: string
  description: string
  isOpen: boolean
}

export class MessageContainer extends Component<{}, MessageContainerState> {
  state: MessageContainerState = {
    title: '',
    description: '',
    isOpen: false
  }

  handleOpen = (title: string, description: string) => {
    this.setState({
      title,
      description,
      isOpen: true
    })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const providerValue: MessageContext = {
      title: this.state.title,
      description: this.state.description,
      isOpen: this.state.isOpen,
      handleOpen: this.handleOpen,
      handleClose: this.handleClose
    }

    return <Provider value={providerValue}>{this.props.children}</Provider>
  }
}
