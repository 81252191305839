import { Reducer, combineReducers } from 'redux'
import { SheetFlourSpecsActionTypes } from './types'
import { SheetFlourSpecs } from 'interfaces/wup/sheetFlourSpecs'

const initialState: SheetFlourSpecs = {
  flourSpecs: []
}

const flourSpecs: Reducer<SheetFlourSpecs['flourSpecs']> = (
  state = initialState.flourSpecs,
  { type, payload }
) => {
  switch (type) {
    case SheetFlourSpecsActionTypes.CREATE: {
      return [...state, payload]
    }
    case SheetFlourSpecsActionTypes.IMPORT_DATA: {
      return [...payload]
    }
    default: {
      return state
    }
  }
}

export const sheetFlourSpecsReducer = combineReducers<SheetFlourSpecs>({
  flourSpecs
})
