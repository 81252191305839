import React, { FC, ChangeEvent, useContext } from "react";
import { Button, Box } from "@material-ui/core";
import { ImportButtonProps } from "./ImportButton.Container";
import { getWorkbook, RecipeImporter } from "utils/excel";
import { messageContext } from "components/message";

const ImportButton: FC<ImportButtonProps> = ({
  importConstraints,
  importIdeals,
  updateRecipeFileName,
  form,
  fileName,
}) => {
  const { handleOpen } = useContext(messageContext);
  let fileInput: HTMLInputElement | null = null;

  const resetForm = (recipeImporter: RecipeImporter) => {
    form.setFieldValue("constrained", recipeImporter.getConstraits());
    form.setFieldValue("ideal", recipeImporter.getIdeals());
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    event.target.value = "";

    getWorkbook(selectedFile!)
      .then((workbook) => {
        const recipeImporter = new RecipeImporter(workbook);
        importConstraints(recipeImporter.getConstraits());
        importIdeals(recipeImporter.getIdeals());
        updateRecipeFileName(selectedFile!.name);
        resetForm(recipeImporter);
      })
      .catch(() =>
        handleOpen(
          "Wrong Format",
          "Please use our excel template to import data"
        )
      );
  };

  const handleButtonClick = () => {
    fileInput && fileInput.click();
  };

  return (
    <>
      <input
        style={{ display: "none" }}
        type="file"
        onChange={handleFileChange}
        ref={(ref) => (fileInput = ref)}
      />
      <Button variant="outlined" color="primary" onClick={handleButtonClick}>
        Import
      </Button>
      <Box
        maxWidth={90}
        marginTop={1}
        textAlign="center"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        title={fileName}
      >
        {fileName}
      </Box>
    </>
  );
};

export default ImportButton;
