import { SheetDate } from 'interfaces/master/sheetDate'
import { Reducer, combineReducers } from 'redux'
import { SheetDateActionTypes } from './types'

const initialState: SheetDate = {
  date: '',
  plantSite: ''
}

const date: Reducer<SheetDate['date']> = (
  state = initialState.date,
  { type, payload }
) => {
  switch (type) {
    case SheetDateActionTypes.IMPORT_DATE: {
      return payload
    }
    default: {
      return state
    }
  }
}

const plantSite: Reducer<SheetDate['plantSite']> = (
  state = initialState.plantSite,
  { type, payload }
) => {
  switch (type) {
    case SheetDateActionTypes.IMPORT_PLANT_SITE: {
      return payload
    }
    default: {
      return state
    }
  }
}

export const sheetDateReducer = combineReducers<SheetDate>({
  date,
  plantSite
})
