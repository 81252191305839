import React, { FC, useContext } from 'react'
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  FormGroup
} from '@material-ui/core'
import { TextField } from 'components/formikWrapper'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import axios, { AxiosResponse } from 'axios'
import { login, isLoggedIn } from 'utils/auth'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { messageContext } from 'components/message'

interface FormState {
  username: string
  password: string
}

const initialValues = {
  username: '',
  password: ''
}

const validationSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required()
})

const url =
  '//cassava.cerestar.net/auth/login'

const Page: FC<RouteComponentProps> = ({ history }) => {
  const { handleOpen } = useContext(messageContext)

  const handleResponse = (response: AxiosResponse<any>) => {
    const { data } = response
    if (data.username) {
      login(data)
      history.push('/')
    } else {
      handleOpen(data.message, data.details)
    }
  }

  const handleSubmit = (values: FormState) => {
    axios
      .post(url, values)
      .then(handleResponse)
      .catch(err => handleOpen('Something when wrong', err.message))
  }

  const renderInnerForm = () => {
    return (
      <Form>
        <Card>
          <CardHeader title="Login" />
          <CardContent>
            <FormGroup>
              <TextField name="username" label="Username" />
            </FormGroup>
            <FormGroup>
              <TextField name="password" label="Password" type="password" />
            </FormGroup>
          </CardContent>
          <CardActions>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Login
            </Button>
          </CardActions>
        </Card>
      </Form>
    )
  }

  return isLoggedIn() ? (
    <Redirect to="/" />
  ) : (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={renderInnerForm}
      />
    </Box>
  )
}

export default withRouter(Page)
