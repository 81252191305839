export function isNumber(value: any): boolean {
  return value === '' ? false : !isNaN(value)
}

export const getFormattedValue = (value: any): string => {
  if (isNumber(value)) {
    const isDecimal = String(value).includes('.')
    if (isDecimal) {
      const split = String(value).split('.')
      split[0] = parseInt(split[0]).toLocaleString()
      return split.join('.')
    }

    return parseInt(value).toLocaleString()
  } else {
    return value
  }
}

export const getUSValue = (value: any): string => {
  if (isNumber(value)) {
    const isDecimal = String(value).includes('.')
    if (isDecimal) {
      const float = parseFloat(value).toFixed(2)
      const split = String(float).split('.')
      split[0] = parseInt(split[0]).toLocaleString()
      return split.join('.')
    }

    return parseInt(value).toLocaleString()
  } else {
    return value
  }
}

export const getUnformattedValue = (value: string): string => {
  return value.replace(/,/g, '')
}
