import React, { Component, ChangeEvent } from "react";
import {
  Container,
  Tabs,
  Tab as MaterialTab,
  Box,
  Typography,
  Grid,
  Button,
  colors,
} from "@material-ui/core";
import TabPanel from "components/TabPanel";
import TabConstrained from "./tabConstrained";
import TabIdeal from "./tabIdeal";
import ImportButton from "./components/importButton";
import ExportButton from "./components/exportButton";
import { PageProps } from "./Page.Container";
import { Formik, Form, FormikProps, FormikContextType } from "formik";
import * as yup from "yup";
import { validationSchemaConstrained } from "./tabConstrained/TabConstrained";
import { validationSchemaIdeal } from "./tabIdeal/TabIdeal";
import styled, { css } from "styled-components";
import CalculateButton from "./components/calculateButton";
import { Effect } from "components/formikWrapper";
import { Recipe, RecipeWheat } from "interfaces/generic/recipe";

const validationSchema = yup.object().shape({
  constraints: yup.array(validationSchemaConstrained),
  ideals: yup.array(validationSchemaIdeal),
});

interface PageState {
  activeTab: number;
}

enum TabIndex {
  TAB_CONSTRAINED = 0,
  TAB_IDEAL = 1,
}

export default class Page extends Component<PageProps, PageState> {
  state: PageState = {
    activeTab: TabIndex.TAB_CONSTRAINED,
  };

  isRecipeValueChanged = (currentRecipes: Recipe[], prevRecipes: Recipe[]) => {
    const isChagedFromImport = currentRecipes.length !== prevRecipes.length;
    return isChagedFromImport
      ? false
      : currentRecipes.some((recipe, indexRecipe) =>
          recipe.recipeFlours.some((recipeFlour, indexFlour) =>
            recipeFlour.recipeWheats.some(
              (recipeWheat, indexWheat) =>
                recipeWheat.value !==
                prevRecipes[indexRecipe].recipeFlours[indexFlour].recipeWheats[
                  indexWheat
                ].value
            )
          )
        );
  };

  getChangedWheat = (currentRecipes: Recipe[], prevRecipes: Recipe[]) => {
    let currentWheat: RecipeWheat = { name: "", value: 0 };
    let prevWheat: RecipeWheat = { name: "", value: 0 };
    let changedIndexRecipe = 0;
    let changedIndexFlour = 0;
    let changedIndexWheat = 0;

    currentRecipes.forEach((recipe, indexRecipe) =>
      recipe.recipeFlours.forEach((recipeFlour, indexFlour) =>
        recipeFlour.recipeWheats.forEach((recipeWheat, indexWheat) => {
          const prevConstraintWheat =
            prevRecipes[indexRecipe].recipeFlours[indexFlour].recipeWheats[
              indexWheat
            ];

          if (recipeWheat.value !== prevConstraintWheat.value) {
            currentWheat = recipeWheat;
            prevWheat = prevConstraintWheat;
            changedIndexRecipe = indexRecipe;
            changedIndexFlour = indexFlour;
            changedIndexWheat = indexWheat;
          }
        })
      )
    );

    return {
      currentWheat,
      prevWheat,
      changedIndexRecipe,
      changedIndexFlour,
      changedIndexWheat,
    };
  };

  getConstraintWheatFromName = (name: string) => {
    const { inStockWheatSpecs } = this.props;
    const splittedName = name.split(",");
    const origin = splittedName[0].replace("(", "");
    const typeName = splittedName[1].trim();
    const code = splittedName[2].replace(")", "").trim();

    return inStockWheatSpecs.find(
      (inStockWheatSpec) =>
        origin === inStockWheatSpec.origin &&
        typeName === inStockWheatSpec.typeName &&
        code === inStockWheatSpec.code
    );
  };

  getIdealWheatFromName = (name: string) => {
    const { expectedWheatSpecs } = this.props;
    const splittedName = name.split(",");
    const origin = splittedName[0].replace("(", "");
    const typeName = splittedName[1].trim().replace(")", "");

    return expectedWheatSpecs.find(
      (expectedWheatSpec) =>
        origin === expectedWheatSpec.origin &&
        typeName === expectedWheatSpec.typeName
    );
  };

  getOldWetGluten = (
    prevRecipes: Recipe[],
    changedIndexRecipe: number,
    changedIndexFlour: number
  ) => {
    const { wetGluten } = prevRecipes[changedIndexRecipe].recipeFlours[
      changedIndexFlour
    ];
    const oldWetGlutenValue = parseFloat(wetGluten);
    const oldWetGlutenRange = wetGluten.split(" ").slice(1).join(" ");
    return { oldWetGlutenValue, oldWetGlutenRange };
  };

  changeWetGluten = (
    currentRecipes: Recipe[],
    prevRecipes: Recipe[],
    recipeName: "constraints" | "ideals",
    form: FormikProps<PageProps["initialValues"]>
  ) => {
    const {
      currentWheat,
      prevWheat,
      changedIndexRecipe,
      changedIndexFlour,
    } = this.getChangedWheat(currentRecipes, prevRecipes);
    const wheat =
      recipeName === "constraints"
        ? this.getConstraintWheatFromName(currentWheat.name)
        : this.getIdealWheatFromName(currentWheat.name);
    if (!wheat) return;
    const newRatio = currentWheat.value;
    const oldRatio = prevWheat.value;
    const { oldWetGlutenValue, oldWetGlutenRange } = this.getOldWetGluten(
      prevRecipes,
      changedIndexRecipe,
      changedIndexFlour
    );

    const newWetGlutenValue = parseFloat(
      String(
        oldWetGlutenValue -
          (wheat.flourWetGluten * oldRatio) / 100 +
          (wheat.flourWetGluten * newRatio) / 100
      )
    ).toFixed(2);

    form.setFieldValue(
      `${recipeName}.${changedIndexRecipe}.recipeFlours.${changedIndexFlour}.wetGluten`,
      `${newWetGlutenValue} ${oldWetGlutenRange}`
    );
  };

  handleTabChange = (event: ChangeEvent<{}>, activeTab: number) => {
    this.setState({ activeTab });
  };

  handleSubmit = (values: PageProps["initialValues"]) => {
    this.props.importConstraints(values.constraints);
    this.props.importIdeals(values.ideals);
    this.props.history.push("/wup");
  };

  handleBackButtonClick = () => {
    this.props.history.push("/swimof");
  };

  handleFormChange = (
    current: FormikContextType<PageProps["initialValues"]>,
    prev: FormikContextType<PageProps["initialValues"]>,
    form: FormikProps<PageProps["initialValues"]>
  ) => {
    const {
      constraints: currentConstraints,
      ideals: currentIdeals,
    } = current.values;

    const { constraints: prevConstraints, ideals: prevIdeals } = prev.values;

    if (this.isRecipeValueChanged(currentConstraints, prevConstraints))
      this.changeWetGluten(
        currentConstraints,
        prevConstraints,
        "constraints",
        form
      );

    if (this.isRecipeValueChanged(currentIdeals, prevIdeals))
      this.changeWetGluten(currentIdeals, prevIdeals, "ideals", form);
  };

  renderPageHeader = (form: FormikProps<PageProps["initialValues"]>) => {
    return (
      <Box marginBottom={2}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h4">Recipe</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button onClick={this.handleBackButtonClick}>Back</Button>
              </Grid>
              <Grid item>
                <ImportButton form={form} />
              </Grid>
              <Grid item>
                <ExportButton form={form} />
              </Grid>
              <Grid item>
                <CalculateButton form={form} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  isTabError = (
    name: keyof PageProps["initialValues"],
    form: FormikProps<PageProps["initialValues"]>
  ) => {
    return form.errors[name] !== undefined;
  };

  renderTabs = (form: FormikProps<PageProps["initialValues"]>) => {
    const { activeTab } = this.state;
    return (
      <Tabs
        value={activeTab}
        onChange={this.handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label="constrained"
          isError={this.isTabError("constraints", form)}
        />
        <Tab label="ideal" isError={this.isTabError("ideals", form)} />
      </Tabs>
    );
  };

  renderTabPanels = () => {
    const { activeTab } = this.state;
    const { flourSpecs } = this.props;
    return (
      <Box marginTop={2}>
        <TabPanel index={TabIndex.TAB_CONSTRAINED} activeTab={activeTab}>
          <TabConstrained flourSpecs={flourSpecs} />
        </TabPanel>

        <TabPanel index={TabIndex.TAB_IDEAL} activeTab={activeTab}>
          <TabIdeal flourSpecs={flourSpecs} />
        </TabPanel>
      </Box>
    );
  };

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.props.initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnMount
        onSubmit={this.handleSubmit}
      >
        {(form) => (
          <Form>
            <Container maxWidth="xl">
              {this.renderPageHeader(form)}
              {this.renderTabs(form)}
              {this.renderTabPanels()}
            </Container>

            <Effect
              onChange={(current, prev) =>
                this.handleFormChange(current, prev, form)
              }
            />
          </Form>
        )}
      </Formik>
    );
  }
}

const Tab = styled(MaterialTab)<{ isError?: boolean }>`
  ${(props) =>
    props.isError &&
    css`
      color: ${colors.red[500]} !important;
    `}
`;
