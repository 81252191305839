import React from 'react'
import { FieldProps, FormikProps, ErrorMessage, FastField } from 'formik'
import {
  TextField as MaterialTextField,
  FormControl,
  FormHelperText
} from '@material-ui/core'
import { getFormattedValue, isNumber, getUnformattedValue } from 'utils/number'

interface NumberFieldProps {
  name: string
  label: string
  isInteger?: boolean
}

export const NumberField: React.FC<NumberFieldProps> = ({
  name,
  label,
  isInteger
}) => {
  function isError(form: FormikProps<any>) {
    return form.errors[name] !== undefined
  }

  function handleChange(
    event: React.ChangeEvent<any>,
    form: FieldProps['form']
  ) {
    const { value } = event.target
    const unformattedValue = getUnformattedValue(value)

    if (isNumber(unformattedValue) && isInteger)
      form.setFieldValue(name, parseInt(unformattedValue))
    else if (isNumber(unformattedValue))
      form.setFieldValue(name, unformattedValue)
    else if (value === '') form.setFieldValue(name, value)
  }

  function handleBlur(
    event: React.FocusEvent<any>,
    field: FieldProps['field'],
    form: FieldProps['form']
  ) {
    field.onBlur(event)

    if (isNumber(field.value) && !isInteger)
      form.setFieldValue(name, parseFloat(field.value).toFixed(2))
  }

  return (
    <FastField name={name}>
      {({ form, field }: FieldProps) => (
        <FormControl error={isError(form)}>
          <MaterialTextField
            {...field}
            value={getFormattedValue(field.value)}
            onChange={event => handleChange(event, form)}
            onBlur={event => handleBlur(event, field, form)}
            label={label}
            error={isError(form)}
            size="small"
          />
          <ErrorMessage
            name={name}
            render={message => <FormHelperText error>{message}</FormHelperText>}
          />
        </FormControl>
      )}
    </FastField>
  )
}
