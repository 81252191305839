import React, { Component } from 'react'
import { Provider, ConfirmContext } from './ConfirmContext'

interface ConfirmContainerState {
  isOpen: boolean
  acceptCallback: () => void
  title: string
  description: string
}

export class ConfirmContainer extends Component<{}, ConfirmContainerState> {
  state: ConfirmContainerState = {
    title: '',
    description: '',
    acceptCallback: () => null,
    isOpen: false
  }

  handleOpen = (
    title: string,
    description: string,
    acceptCallback: () => void
  ) => {
    this.setState({
      title,
      description,
      acceptCallback,
      isOpen: true
    })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const providerValue: ConfirmContext = {
      title: this.state.title,
      description: this.state.description,
      acceptCallback: this.state.acceptCallback,
      isOpen: this.state.isOpen,
      handleOpen: this.handleOpen,
      handleClose: this.handleClose
    }

    return <Provider value={providerValue}>{this.props.children}</Provider>
  }
}
