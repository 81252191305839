import { Workbook, Column } from 'exceljs'
import { InStockWheatSpec } from 'interfaces/master/sheetInStockWheatSpecs'
import { ExpectedWheatSpec } from 'interfaces/master/sheetExpectedWheatSpecs'
import { FlourSpec } from 'interfaces/master/sheetFlourSpecs'
import { saveAs } from 'file-saver'
import model from 'model-input1.json'

export class MasterExporter {
  private workbook: Workbook
  private inStockWheatSpecs: InStockWheatSpec[]
  private expectedWheatSpecs: ExpectedWheatSpec[]
  private flourSpecs: FlourSpec[]
  private date: string
  private plantSite: string

  constructor(
    inStockWheatSpecs: InStockWheatSpec[],
    expectedWheatSpecs: ExpectedWheatSpec[],
    flourSpecs: FlourSpec[],
    date: string,
    plantSite: string
  ) {
    this.workbook = new Workbook()
    this.inStockWheatSpecs = inStockWheatSpecs
    this.expectedWheatSpecs = expectedWheatSpecs
    this.flourSpecs = flourSpecs
    this.date = date
    this.plantSite = plantSite

    this.applyModel()
    this.createInStockWheatSpecs()
    this.createExpectedWheatSpecs()
    this.createFlourSpecs()
    this.createDate()
    this.createPlantSite()
  }

  private applyModel = () => {
    // @ts-ignore
    this.workbook.model = {
      ...this.workbook.model,
      ...model
    }
  }

  private createInStockWheatSpecs = () => {
    this.createRecords('in_stock_wheat_specs', this.inStockWheatSpecs, 'Wheat')
  }

  private createExpectedWheatSpecs = () => {
    this.createRecords('expected_wheat_specs', this.expectedWheatSpecs, 'Wheat')
  }

  private createFlourSpecs = () => {
    this.createRecords('flour_specs', this.flourSpecs, 'Flour')
  }

  private createDate = () => {
    this.workbook.getWorksheet('date').getCell('B1').value = this.date
  }

  private createPlantSite = () => {
    this.workbook.getWorksheet('date').getCell('B2').value = this.plantSite
  }

  private createRecords = (
    worksheetName: string,
    data: object[],
    prefix?: string
  ) => {
    const worksheet = this.workbook.getWorksheet(worksheetName)
    const records: Partial<Column>[] = []

    data.forEach((item, index) => {
      const header: string[] = []
      if (prefix) header.push(`${prefix} ${index + 1}`)
      header.push(...Object.values(item))
      records.push({
        header,
        width: 20,
        style: { alignment: { horizontal: 'center' } }
      })
    })

    worksheet.columns = [...worksheet.columns, ...records]
  }

  download = async () => {
    const blobPart = await this.workbook.xlsx.writeBuffer()
    const blob = new Blob([blobPart])
    saveAs(blob, 'master.xlsx')
  }

  getBuffer = () => {
    return this.workbook.xlsx.writeBuffer()
  }
}
